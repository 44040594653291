import * as React from 'react';
import { ClosedAlert } from '../../global/functions';

import './Alert.scss';

interface Props {
  title?: string;
  text: string;
  error?: boolean;
  component: React.Component;
}
interface State {
  show: boolean;
}

class Alert extends React.Component<Props, State> {
  state = {
    show: true,
  };

  render() {
    const { title = 'Всё хорошо!', text, error = false } = this.props;
    const { show } = this.state;

    const alert = show ? (
      <div className="allert_list">
        <div className={`allert ${error ? 'red' : 'green'}`}>
          <span>{title}</span>
          <p>{text}</p>
        </div>
      </div>
    ) : null;

    return alert;
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: false });
      ClosedAlert(this.props.component);
    }, 3000);
  }
}

export default Alert;
