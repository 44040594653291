import * as React from 'react';
import Slider from 'react-slick';

import './MyWorks.scss';
import '../../../global/SliderSlick.scss';

import { IFWorks } from '../../../global/interfaces';
import { ContextModal } from '../../../services/Contexts';
import CardWorks from '../../CardWorks/CardWorks';
import ContentModalListWorks from '../ContentModalListWorks/ContentModalListWorks';

interface Props {
  publications: IFWorks[];
  title: string;
}

class MyWorks extends React.Component<Props> {
  limitItem: number;

  constructor(props: Props) {
    super(props);
    this.limitItem = 3;
  }

  render() {
    const { publications } = this.props;
    const { limitItem } = this;

    const countSlides = publications.length < limitItem ? publications.length : limitItem;

    const stub = (
      <React.Fragment>
        {countSlides == 1 ? (
          <React.Fragment>
            <div></div>
            <div></div>
          </React.Fragment>
        ) : null}
        {countSlides == 2 ? <div></div> : null}
      </React.Fragment>
    );

    const settings = {
      // autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: limitItem,
      slidesToScroll: limitItem,
      className: 'slider-publish',
      arrows: publications.length > limitItem,
    };

    return (
      <div className="row mt-40 my-works">
        <div className="col-12">
          <div className="page_title publications_title">
            <h4>{this.props.title}</h4>
            {publications.length > limitItem ? (
              <ContextModal.Consumer>
                {({ showModal }) => {
                  return (
                    <a
                      href="#archive"
                      className="open_modal border_btn modal-all-works"
                      onClick={() => {
                        this.showPublications(showModal);
                      }}
                    >
                      Все
                    </a>
                  );
                }}
              </ContextModal.Consumer>
            ) : null}
          </div>

          <div
            className={`slider-publish publications ${
              publications.length <= limitItem ? 'slider-publish-off' : ''
            }`}
          >
            <Slider {...settings}>
              {publications.map((item, i) => (
                <CardWorks card={item} key={`card${i}`} />
              ))}
              {stub}
            </Slider>
          </div>
        </div>
      </div>
    );
  }

  showPublications(showModal: any) {
    showModal({
      title: 'Мои работы',
      content: <ContentModalListWorks cards={this.props.publications} />,
    });
  }
}

export default MyWorks;
