import * as React from 'react';

import './BannerActivity.scss';

import { activityNames, baseURL } from '../../global/vars';
import { ArrDeclension, ArrDeclensionCases, Declension } from '../../services/Declension';

interface Props {
  typeActivity: string;
  name: string;
  countStudents: number;
  banner: {
    thumb?: string;
    crop?: string;
    original: string;
  };

  btnBlocked?: boolean;
  btnEnroll?: boolean;
  btnApply?: boolean;
  btnCancel?: boolean;
  btnBecomeMember?: boolean;
  btnEdit?: boolean;
  btnControl?: boolean;
  btnCompleted?: boolean;
  btnWinner?: boolean;
  btnBuy?: boolean;
  btnCheckTrack?: boolean;

  statusOpen?: boolean;
  statusUnderConsideration?: boolean;
  statusCompleted?: boolean;
  statusClosed?: boolean;
  statusParticipant?: boolean;
  statusDone?: boolean;
  statusActiveTrack?: boolean;

  price?: number | null;
  textCount?: string;
  hasMeasurement?: boolean;
  clickButton?: any;
  textBtn?: string;
}

class BannerActivity extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      typeActivity,
      name,
      banner,
      countStudents,
      btnBlocked = false,
      btnEnroll = false,
      btnApply = false,
      btnCancel = false,
      btnBecomeMember = false,
      btnEdit = false,
      btnControl = false,
      btnCompleted = false,
      btnWinner = false,
      btnBuy = false,
      btnCheckTrack = false,

      statusOpen = false,
      statusUnderConsideration = false,
      statusCompleted = false,
      statusClosed = false,
      statusParticipant = false,
      statusDone = false,
      statusActiveTrack = false,

      price = false,
      textCount = 'Участников:',
      hasMeasurement = false,
      clickButton,
      textBtn,
    } = this.props;

    return (
      <div
        className={`banner ${this.checkTypeBanner(typeActivity)}`}
        style={{
          backgroundImage: `url(${baseURL}/storage/${banner.crop ? banner.crop : banner.original})`,
        }}
      >
        <div className="text">
          <span>{this.checkName()}</span>
          <h2>{name}</h2>
        </div>

        <div className="btn_block_bg">
          <div>
            {statusOpen ? <div className="activity_label">Открыт набор</div> : null}
            {statusUnderConsideration ? (
              <div className="activity_label bg_orange ">Заявка на рассмотрении</div>
            ) : null}
            {statusCompleted ? <div className="activity_label bg_closed ">Набор завершен</div> : null}
            {statusClosed ? <div className="activity_label bg_closed ">Набор закрыт</div> : null}
            {statusParticipant ? <div className="activity_label bg_participant ">Участник</div> : null}
            {statusDone ? <div className="activity_label bg_done ">Выполнено</div> : null}
            {statusActiveTrack ? <div className="activity_label bg_participant ">Активный трек</div> : null}

            {price ? (
              <span className="qual-price">
                Стоимость: <span className="price">{price} ₽</span>
              </span>
            ) : null}
            {typeActivity != 'challenge' ? (
              <span className="count">
                {`${textCount} ${countStudents} ${
                  hasMeasurement ? Declension(countStudents, ArrDeclension('people')) : null
                }`}
              </span>
            ) : null}
          </div>

          <div>
            {btnBlocked ? (
              <button className="red_btn" onClick={() => clickButton()}>
                Вы заблокированы
              </button>
            ) : null}
            {btnEnroll ? (
              <button className="blue_btn" onClick={() => clickButton()}>
                Записаться
              </button>
            ) : null}
            {btnApply ? (
              <button className="blue_btn" onClick={() => clickButton()}>
                Подать заявку
              </button>
            ) : null}
            {btnCancel ? (
              <button className="border_btn red big" onClick={() => clickButton()}>
                Отменить участие
              </button>
            ) : null}
            {btnBecomeMember ? (
              <button className="blue_btn" onClick={() => clickButton()}>
                Стать участником
              </button>
            ) : null}
            {btnEdit ? (
              <button className="border_btn" onClick={() => clickButton()}>
                Редактировать
              </button>
            ) : null}
            {btnControl ? (
              <button className="border_btn" onClick={() => clickButton()}>
                Управление
              </button>
            ) : null}
            {btnBuy ? (
              <button className="blue_btn" onClick={() => clickButton()}>
                Варианты покупки
              </button>
            ) : null}
            {btnCheckTrack ? (
              <button className="blue_btn" onClick={() => clickButton()}>
                {textBtn}
              </button>
            ) : null}

            {btnCompleted ? (
              <button className="blue_btn" onClick={() => clickButton()}>
                Завершен
              </button>
            ) : null}
            {btnWinner ? (
              <button className="blue_btn" onClick={() => clickButton()}>
                Победитель
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  checkName() {
    return activityNames.find((field) => field.name == this.props.typeActivity)?.text;
  }

  checkTypeBanner(typeActivity: string) {
    let type = '';
    switch (typeActivity) {
      case 'challenge':
        type = 'banner_challenge';
        break;
      case 'track':
        type = '';
        break;
      default:
        type = 'big';
    }
    return type;
  }
}

export default BannerActivity;
