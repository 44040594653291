import React from 'react';

import { IFWorks } from '../../../global/interfaces';
import CardWorks from '../../CardWorks/CardWorks';

interface Props {
  cards: IFWorks[];
}

class ContentModalListWorks extends React.Component<Props> {
  render() {
    const { cards } = this.props;

    return (
      <div className="content-modal content-modal_space-between">
        {cards.map((item: IFWorks, i) => (
          <div className="content-modal__card" key={`card${i}`}>
            <CardWorks card={item} />
          </div>
        ))}
      </div>
    );
  }
}

export default ContentModalListWorks;
