import * as React from 'react';
import Chart from 'react-apexcharts';
import { IFChart } from '../../global/interfaces';

import './Chart.scss';

interface Props {
  names: string[];
  points: number[];
}

interface State {
  chart: IFChart;
}

class ChartRadar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chart: {
        series: [
          {
            name: 'Series 1',
            data: this.props.points,
          },
        ],
        options: {
          chart: {
            height: 500,
            type: 'radar',
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            radar: {
              size: 150,
              polygons: {
                strokeColors: '#f0f0f0',
                fill: {
                  colors: ['#fff'],
                },
              },
            },
          },
          title: {
            text: '',
          },
          colors: ['#51a5f2'],
          markers: {
            size: 4,
            colors: ['#ffc500'],
            strokeColor: '#ffc500',
            strokeWidth: 3,
          },
          tooltip: {
            enabled: false,
            y: {
              formatter: (val: any) => {
                return val;
              },
            },
          },
          xaxis: {
            categories: this.props.names,
            labels: {
              style: {
                cssClass: 'chart__labels',
              },
              formatter: function (value: any) {
                return value;
              },
            },
          },
          yaxis: {
            tickAmount: 2,
            labels: {
              formatter: (val: any, i: any) => {},
            },
          },
          toolbar: {
            show: false,
          },
        },
      },
    };
  }

  render() {
    const { chart } = this.state;
    return (
      <div id="chart">
        <Chart
          options={chart.options}
          series={chart.series}
          type="radar"
          height={350}
          className="chart"
          toolbar={{
            enabled: false,
          }}
        />
      </div>
    );
  }
}

export default ChartRadar;
