import * as React from 'react';

import './CardActivity.scss';

interface Props {}

class CardActivityPreload extends React.Component<Props> {
  render() {
    return (
      <div className="activity-card__preload">
        <div className="activity-card__img-block activity-card__img-block_preload">
          <span className="activity-card__members activity-card__members_preload"></span>
          <span className="activity-card__price activity-card__price_preload"></span>
        </div>
        <div className="activity-card__content activity-card__content_preload">
          <div className="activity-card__content-top">
            <div className="activity-card__tag-line activity-card__tag-line_preload">
              <span></span>
            </div>
            <h4 className="activity-card__caption activity-card__caption_preload"></h4>
          </div>

          <div className="activity-card__content-bottom">
            <div className="activity-card__sign-board activity-card__sign-board_preload">
              <span></span>
            </div>
            <div className="activity-card__sign-board activity-card__sign-board_preload">
              <span></span>
            </div>
            <div className="activity-card__sign-board activity-card__sign-board_preload">
              <span></span>
            </div>
          </div>

          <div className="activity-card__status activity-card__status_preload">
            <span></span>
          </div>
        </div>
      </div>
    );
  }
}

export default CardActivityPreload;
