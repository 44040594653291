import React from 'react';
import Donut from '../../components/analytics/Donut/Donut';
import Rating from '../../components/analytics/Rating/Rating';
import Speedometers from '../../components/analytics/Speedometers/Speedometers';
import UserAnalyticFull from '../../components/analytics/UserAnalyticFull/UserAnalyticFull';
import WhoCooler from '../../components/analytics/WhoCooler/WhoCooler';

import { IFRating, IFSpeedometers, IFWhoCooler } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {}

interface State {
  activity: {
    today: IFSpeedometers;
    overall: IFSpeedometers;
  };
  ladder: IFWhoCooler[];
  rating: IFRating;
}

class Analytics extends React.Component<Props, State> {
  userAnalyticFull: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      activity: {
        today: {
          subscribe: { count: 0, max: 0 },
          answer: { count: 0, max: 0 },
          score: { count: 0, max: 0 },
        },
        overall: {
          subscribe: { count: 0, max: 0 },
          answer: { count: 0, max: 0 },
          score: { count: 0, max: 0 },
        },
      },
      ladder: [],
      rating: {
        all: [],
        challenge: [],
        course: [],
        event: [],
        project: [],
      },
    };
    this.userAnalyticFull = false;
  }

  render() {
    return (
      <div className="page statistics">
        <div className="page__container">
          <div className="page__content-full">
            <div className="row">
              <div className="col-12">
                <div className="page_title">
                  <h3>Аналитика по данным</h3>
                </div>

                <div className="statistics_cards">
                  <Speedometers activity={this.state.activity} />

                  <WhoCooler ladder={this.state.ladder} />

                  <Donut />

                  <Rating rating={this.state.rating} />

                  {this.userAnalyticFull ? <UserAnalyticFull /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.statistics().then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          activity: data.activity,
          ladder: data.ladder,
          rating: data.rating,
        })
      );
    });
  }
}

export default Analytics;
