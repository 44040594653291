import * as React from 'react';
import Slider from 'react-slick';
import { IFCompetenceSlider } from '../../../global/interfaces';
import { baseURL } from '../../../global/vars';

interface Props {
  sliderActivity: any;
}

interface State {
  activeSlide: number;
}

class HistoryStart extends React.Component<Props, State> {
  slider: React.RefObject<Slider>;

  constructor(props: Props) {
    super(props);
    this.slider = React.createRef();
    this.state = {
      activeSlide: 1,
    };
  }

  render() {
    const { sliderActivity } = this.props;

    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'slider-compet-rec',
    };

    return (
      <div className="history-start">
        <p className="history-start__text_bold">Начни движение с простых образовательных активностей</p>

        <div className="recomm_cards">
          <Slider
            {...settings}
            ref={this.slider}
            afterChange={(current: number) => this.setState({ activeSlide: current + 1 })}
          >
            {sliderActivity.map(
              ({ type, course_id, score, course, image }: IFCompetenceSlider, i: number) => (
                <a href={`/${type}/${course_id}`} className="recomm_card points_right blue" key={`slide${i}`}>
                  <span className="where">{type}</span>
                  <span className="pionts">
                    <span>{score}</span>
                    <span>балла</span>
                  </span>
                  <span className="name">{course}</span>
                  <div className="bg">
                    <div
                      style={
                        type
                          ? {
                              backgroundImage: `url(${baseURL}/storage/${type}ActivityCard/${
                                image.thumb ? image.thumb : image.original
                              })`,
                            }
                          : {}
                      }
                    ></div>
                  </div>
                </a>
              )
            )}
          </Slider>
          <div className="counter">
            <span id="recomm_card_slider_counter">{this.state.activeSlide}</span>
            <span id="recomm_card_slider_capacity">/{sliderActivity.length}</span>
          </div>
        </div>

        <div className="title mt-32">
          <h5>Как начать?</h5>
        </div>

        <div className="num_list">
          <div>
            <span>1</span>
            <span>Нажми «Принять участие»</span>
          </div>
          <div>
            <span>2</span>
            <span>Выполни задание и опубликуй работу</span>
          </div>
          <div>
            <span>3</span>
            <span>Дождись ее проверки и&nbsp;получи баллы</span>
          </div>
        </div>
      </div>
    );
  }
}

export default HistoryStart;
