import React from 'react';
import { checkTagLine } from '../../../global/functions';

import { IFAchievements, IFCardActivity } from '../../../global/interfaces';
import CardActivity from '../../CardActivity/CardActivity';

interface Props {
  data: IFAchievements;
}

class ContentModalListAchievements extends React.Component<Props> {
  render() {
    const {
      data: { items, type, name },
    } = this.props;

    const cards: IFCardActivity[] = [];
    items.forEach((item) => {
      cards.push({
        city_name: null,
        course_count: 0,
        date: item.end,
        id: item.id,
        image: item.image,
        is_online: 1,
        name: item.name,
        organization: item.organization,
        price: 0,
        status: item.status,
        student_count: item.student_count,
      });
    });

    return (
      <React.Fragment>
        <div className="content-modal content-modal_space-between">
          <div className="content-modal__text">
            <span>{`Здесь отображаются ${name}, пройденные тобой.`}</span>
          </div>
          {cards.map((item: IFCardActivity, i) => (
            <div className="content-modal__card" key={`card${i}`}>
              <CardActivity pageName={type} data={item} tagLine={checkTagLine(type, item)} />
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default ContentModalListAchievements;
