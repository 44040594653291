import * as React from 'react';
import Slider, { Settings } from 'react-slick';

import './Trajectory.scss';
import { Tooltip } from 'react-tippy';
import { Icons } from './Icons';
import Moment from 'react-moment';

interface Props {
  line: IFLineItemTrajectory[];
  title: string;
}

interface IFLineItemTrajectory {
  type: string;
  date: string;
  name: string;
  id: number;
  tracks: {
    id: number;
    name: string;
    score: number;
  }[];
}

interface State {
  settings: Settings;
}

class Trajectory extends React.Component<Props, State> {
  countSlides: number;
  idxLastSlide: number;
  slider: React.RefObject<Slider>;

  constructor(props: Props) {
    super(props);
    this.slider = React.createRef();
    this.countSlides = 5;
    this.idxLastSlide = this.props.line.length - this.countSlides + 1;
    this.state = {
      settings: {
        slidesToShow: this.countSlides,
        slidesToScroll: 1,
        className: 'slider-trajectory',
        arrows: true,
        infinite: false,
        initialSlide: this.idxLastSlide,
        draggable: false,
      },
    };
  }

  render() {
    const { line, title } = this.props;

    const lineFull: IFLineItemTrajectory[] = [
      ...line,
      {
        type: 'q-a',
        date: '',
        name: '',
        id: -10,
        tracks: [],
      },
    ];

    return (
      <div className="row">
        <div className="col-12">
          <div className="page_title">
            <h4>{title}</h4>
            <a
              href="#"
              className="border_btn traectoria_line-today"
              onClick={() => {
                this.today();
              }}
            >
              Сегодня
            </a>
          </div>

          <div className="traectoria_scroll">
            <div className="traectoria_line">
              <div className="traectories">
                <div className="traectories_scroll_line">
                  <Slider {...this.state.settings} ref={this.slider}>
                    {lineFull.map((item, i) => (
                      <div className={`traectoria_section ${item.type}`} key={`traectoria${i}`}>
                        <div className="traectoria_content">
                          <div className="icon">
                            <a>
                              <span className={`icon-${item.type}`}></span>
                            </a>
                          </div>
                          <div className="text">
                            <span className="status">
                              {item.name != '' ? 'Участник' : ''} {this.getNameActivity(item.type)}
                            </span>
                            <a href={`/${item.type}/${item.id}`} className="name">
                              {item.name}
                            </a>
                            <div className="line">
                              {item.tracks.map((track, index) => (
                                <Tooltip
                                  title={track.name}
                                  position="top"
                                  trigger="mouseenter"
                                  theme="light"
                                  arrow={true}
                                  key={`ico${index}`}
                                >
                                  <a href={`/track/${track.id}`} className="track-prog">
                                    {Icons(track.name)}
                                  </a>
                                </Tooltip>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="activity">
                          <div
                            className={`traectoria-arrow ${
                              i > 0 ? lineFull[i - 1].type : 'traectoria-arrow__no-arrow'
                            }`}
                          ></div>
                          {item.date ? (
                            <span>
                              <Moment format="DD.MM.YY">{item.date}</Moment>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.today();
  }

  getNameActivity(type: string) {
    const list: { [key: string]: string } = {
      challenge: 'челленджа',
      project: 'проекта',
      event: 'события',
      course: 'курса',
    };

    return list[type];
  }

  today() {
    this.slider.current?.slickGoTo(this.idxLastSlide);
  }
}

export default Trajectory;
