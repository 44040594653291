import * as React from 'react';

import './ContentModalYoutube.scss';

interface Props {
  link: string;
}

interface State {}

class ContentModalYoutube extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { link } = this.props;

    return (
      <div className="content-modal-youtube">
        <iframe
          width={`100%`}
          height="560"
          src={`https://www.youtube.com/embed/${link}`}
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={false}
        ></iframe>
      </div>
    );
  }

  setActive = (active: number) => {
    this.setState({ active: active });
  };
}

export default ContentModalYoutube;
