import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import { IFCompetenciesTracks, IFCompetence, IFCompetencies } from '../../../global/interfaces';
import { listCompetencies } from '../../../global/stub';
import { competenciesMaps } from '../../../global/vars';
import ChartRadar from '../../Chart/Chart';
import TabsMini from '../../TabsMini/TabsMini';

import { HistoryStart, HistoryDisabled } from './CompetenciesHistory';
import { HistoryActived } from './HistoryActived';

import './Сompetencies.scss';

interface Props {
  listCompetencies?: IFCompetencies[];
  star: {
    name: string;
    count: number;
  }[];
}

interface State {
  nav1: any;
  nav2: any;
  settingsWay: Settings;
  settingsTracks: Settings;
  activeWay: number;
  activeTrack: number;
  history: React.ReactElement;
  activeTab: number;
}

class Сompetencies extends React.Component<Props, State> {
  sliderWays: any;
  sliderTrack: any;

  constructor(props: Props) {
    super(props);
    this.sliderWays = null;
    this.sliderTrack = null;
    this.state = {
      nav1: this.sliderWays,
      nav2: this.sliderTrack,
      settingsWay: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'slider-competencies',
        arrows: true,
        infinite: true,
        draggable: false,
      },
      settingsTracks: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'slider-tracks',
        arrows: false,
        infinite: true,
        draggable: false,
        focusOnSelect: true,
      },
      activeWay: -1,
      activeTrack: -1,
      history: <HistoryStart />,
      activeTab: 0,
    };
  }

  render() {
    const { star } = this.props;
    const { activeWay, activeTrack, activeTab } = this.state;

    const namesChart: string[] = [];
    const pointsChart: number[] = [];
    star.forEach((item) => {
      namesChart.push(item.name);
      pointsChart.push(item.count);
    });

    return (
      <div className="row mt-40 competencies">
        <div className="page_title">
          <h4>Компетенции</h4>
          <TabsMini tabs={['Траектории', 'Аналитика']} active={activeTab} changedTabs={this.changedTabs} />
        </div>

        <div className="competencies__container">
          {activeTab == 0 ? (
            <div className="competencies__ways">
              <Slider
                {...this.state.settingsWay}
                asNavFor={this.state.nav2}
                ref={(slider) => (this.sliderWays = slider)}
              >
                {listCompetencies.map((item, i) => (
                  <div className="pin active" key={`name${i}`}>
                    <div className="name">
                      <span>{item.name}</span>
                    </div>
                  </div>
                ))}
              </Slider>
              <Slider
                {...this.state.settingsTracks}
                asNavFor={this.state.nav1}
                ref={(slider) => (this.sliderTrack = slider)}
              >
                {listCompetencies.map((item, i) => (
                  <div className="competencies__slide-tracks" key={`slide${i}`}>
                    {item.tracks.map((track, idx) => (
                      <div
                        className={`competencies__track ${
                          activeWay == i && activeTrack == idx ? 'competencies__track_active' : ''
                        }`}
                        key={`track${idx}`}
                        onClick={async () => {
                          await this.trackActived(i, idx);
                          this.openHistory(track, item.tracks);
                        }}
                      >
                        <div className="competencies__track-name">
                          <span>{track.name}</span>
                        </div>
                        <div className="competencies__img">
                          <img
                            src={require(`./img/positions/${track.nameImg}-${this.getPositionOnMap(
                              track.competencies
                            )}.png`)}
                            alt="position"
                            className="competencies__position"
                          />
                          <img
                            src={require(`./img/${track.nameImg}-map.jpg`)}
                            alt="map"
                            className="competencies__map"
                          />
                        </div>
                        <div className="competencies__track-text">
                          <span>{this.getTextOnMap(track.competencies)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="competencies__ways">
              <ChartRadar names={namesChart} points={pointsChart} />
            </div>
          )}

          {this.state.history}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState(
      Object.assign({}, this.state, {
        nav1: this.sliderWays,
        nav2: this.sliderTrack,
      })
    );
  }

  changedTabs = (i: number) => {
    this.setState(
      Object.assign({}, this.state, {
        activeTab: i,
      })
    );
  };

  dataOnMap(competencies: IFCompetence[]) {
    let sum = 0;
    competencies.forEach((item) => {
      sum = sum + item.score;
    });

    let position = 1;
    let text = 'На старте';
    competenciesMaps.points.forEach((item, i) => {
      if (item[0] <= sum && sum <= item[1]) {
        position = competenciesMaps.position[i];
        text = competenciesMaps.text[i];
      }
    });

    return { position: position, text: text };
  }

  getTextOnMap(competencies: IFCompetence[]) {
    const { text } = this.dataOnMap(competencies);
    return text;
  }

  getPositionOnMap(competencies: IFCompetence[]) {
    const { position } = this.dataOnMap(competencies);
    return position;
  }

  trackActived(idxWay: number, idxTrack: number) {
    this.setState(
      Object.assign({}, this.state, {
        activeWay: idxWay,
        activeTrack: idxTrack,
      })
    );
  }

  openHistory(track: IFCompetenciesTracks, tracks: IFCompetenciesTracks[]) {
    if (track.competencies.length == 0) {
      this.setState(
        Object.assign({}, this.state, {
          history: <HistoryDisabled />,
        })
      );
    } else {
      this.setState(
        Object.assign({}, this.state, {
          history: <HistoryActived track={track} listTracks={tracks} />,
        })
      );
    }
  }
}

export default Сompetencies;
