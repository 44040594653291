import * as React from 'react';

export const HistoryStart = () => {
  return (
    <div className="competencies__history-map competencies__history-map_center">
      <img src={require('./img/car.png')} className="competencies__history-car" />
      <p className="competencies__history-text">
        Выбери один из 20 треков слева, чтобы принять участие в гонке за баллами
      </p>
    </div>
  );
};

export const HistoryDisabled = () => {
  return (
    <div className="competencies__history-map competencies__history-map_center">
      <img src={require('./img/cones.png')} className="competencies__history-cones" />
      <p className="competencies__history-text">Этот трек пока не доступен, выбери другой трек</p>
    </div>
  );
};

// пока не используется
export const HistoryPent = () => {
  return (
    <div>
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <div className="pentagon">
              <div className="labels_penta">
                <div className="label">
                  <span>Экологическая сфера</span>
                </div>
                <div className="label">
                  <span>Креативная экономика</span>
                </div>
                <div className="label">
                  <span>Киберэкономика</span>
                </div>
                <div className="label">
                  <span>Новый технологический сектор</span>
                </div>
                <div className="label">
                  <span>Научно-производственная сфера</span>
                </div>
              </div>
              <canvas id="myChart" className="radar" width="320px" height="232px"></canvas>
            </div>
          </div>

          <div className="col-6">
            <div className="history_map">
              <div className="title">
                <h4>Треки научно-производственной сферы</h4>
              </div>
              <div className="descr mt-24">
                <span>
                  Не очень длинный текст о том что это за диаграмма, компетенции, вот это вот все, на две
                  строки
                </span>
              </div>
              <div className="title mt-16">
                <h6>Начинай движение</h6>
              </div>

              <div className="list">
                <div>
                  <div className="icon">
                    <img src="./img/icons/1.png" alt="" />
                  </div>
                  <span>
                    Обрати внимание, где сейчас находится курсор каждого трека. Сейчас ты НА СТАРТЕ.
                  </span>
                </div>
                <div>
                  <div className="icon">
                    <img src="./img/icons/2.png" alt="" />
                  </div>
                  <span>Выбирай понравившийся челлендж, мероприятие или проект.</span>
                </div>
                <div>
                  <div className="icon">
                    <img src="./img/icons/3.png" alt="" />
                  </div>
                  <span>Получай баллы и компетенции за его выполнение.</span>
                </div>
                <div>
                  <div className="icon">
                    <img src="./img/icons/4.png" alt="" />
                  </div>
                  <span>
                    При начислении от 1 до 20 баллов курсор соответствующего трека передвинется. Ты перейдешь
                    в статус НАЧАЛО ДВИЖЕНИЯ.
                  </span>
                </div>
                <div>
                  <div className="icon">
                    <img src="./img/icons/5.png" alt="" />
                  </div>
                  <span>
                    Получи статус НАБОР СКОРОСТИ до 20 декабря 2019 года и выиграй приз! Для этого набери от
                    21 до 51 балла.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
