import * as React from 'react';

const speedometerArrow = require('./img/speedometer-arrow.svg').default;

interface Props {
  count: string;
  speedometerImage: any;
  text: string;
  speed: number;
}

class SpeedItem extends React.Component<Props> {
  render() {
    const { count, speedometerImage, text, speed } = this.props;

    return (
      <div>
        <div className="speedometer">
          <span>{count}</span>
          <div className="scale">
            <img src={speedometerImage}></img>
            <div className="arrow">
              <div data-speedometer={`${speed}`} style={{ transform: `rotate(${speed}deg)` }}>
                <img src={speedometerArrow}></img>
              </div>
            </div>
          </div>
          <p>{text}</p>
        </div>
      </div>
    );
  }
}

export default SpeedItem;
