import * as React from 'react';
import Moment from 'react-moment';

import './CardActivity.scss';

import CardActivityPreload from './CardActivityPreload';

import { statuses, baseURL } from '../../global/vars';
import { IFCardActivity } from '../../global/interfaces';

interface Props {
  pageName: string;
  data: IFCardActivity;
  tagLine: string;
}

class CardActivity extends React.Component<Props> {
  render() {
    const { pageName, tagLine } = this.props;
    const { city_name, student_count, price, image, name, organization, date, status, id } = this.props.data;

    const link = `/${pageName}/${id}`;
    const cardImage = image.thumb
      ? { backgroundImage: `url(${baseURL}/storage/${image.thumb})` }
      : { backgroundImage: `url(${baseURL}/storage/${image.original})` };

    let statusCard = '';
    let statusClass = '';
    if (status) {
      const { text, class: statClass } = statuses[status];
      statusCard = text;
      statusClass = statClass;
    }

    return (
      <div className="activity-card">
        <CardActivityPreload />
        <a href={link}>
          <div className="activity-card__img-block">
            {student_count != 0 ? <span className="activity-card__members">{student_count}</span> : null}
            {price != 0 ? <span className="activity-card__price">от {price} ₽</span> : null}
            <div className="activity-card__img" style={cardImage}></div>
          </div>
          <div className="activity-card__content">
            <div className="activity-card__content-top">
              <div className="activity-card__tag-line">
                <span>{tagLine}</span>
              </div>
              <h4 className="activity-card__caption">«{name}»</h4>
            </div>

            <div className="activity-card__content-bottom">
              {organization ? (
                <div className="activity-card__sign-board activity-card__sign-board_organization">
                  <span>{organization}</span>
                </div>
              ) : null}
              {date ? (
                <div className="activity-card__sign-board activity-card__sign-board_date">
                  <span>
                    <Moment format="D MMMM YYYY">{date}</Moment>
                  </span>
                </div>
              ) : null}
              {city_name ? (
                <div className="activity-card__sign-board activity-card__sign-board_geo">
                  <span>{city_name}</span>
                </div>
              ) : null}
            </div>
            {status ? (
              <div className={`activity-card__status ${statusClass}`}>
                <span>{statusCard}</span>
              </div>
            ) : null}
          </div>
        </a>
      </div>
    );
  }
}

export default CardActivity;
