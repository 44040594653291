import * as React from 'react';

// не используется, поэтому пока статично и не отлажено
class RecProfile extends React.Component {
  render() {
    return (
      <div className="row mt-40">
        <div className="col-12">
          <div className="page_title">
            <h4>Рекомендации на основе вашего профиля</h4>
            <a href="./treks.html" className="border_btn">
              Сменить трек
            </a>
          </div>

          <div className="news">
            <div className="news_list news_list_switcher">
              <a href="challenge_page.html" className="recomm_card points_right blue mt-0">
                <span className="where">Челлендж</span>
                <span className="pionts">
                  <span>36</span>
                  <span>баллов</span>
                </span>
                <span className="name">Соревнования по робототехнике</span>

                <div className="bg">
                  <div style={{ backgroundImage: `url('img/bg/image.jpg')` }}></div>
                </div>
              </a>

              <a href="challenge_page.html" className="recomm_card points_right orange mt-0">
                <span className="where">Курс</span>
                <span className="pionts">
                  <span>36</span>
                  <span>баллов</span>
                </span>
                <span className="name">Дизайн-мышление в программировании и верстке</span>

                <div className="bg">
                  <div style={{ backgroundImage: `url('img/bg/image.jpg')` }}></div>
                </div>
              </a>

              <a href="challenge_page.html" className="recomm_card points_right red mt-0">
                <span className="where">Открытая лекция</span>
                <span className="pionts">
                  <span>36</span>
                  <span>баллов</span>
                </span>
                <span className="name">Встреча HR-клуба Томских компаний</span>

                <div className="bg">
                  <div style={{ backgroundImage: `url('img/bg/image.jpg')` }}></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecProfile;
