import React from 'react';
import { IFPeople } from '../../../global/interfaces';
import FetchMyTrack from '../../../services/FetchMyTrack';
import UserIco from '../../UserIco/UserIco';

import './ChatBanned.scss';

interface Props {
  banned: IFPeople[];
  updateBannedList: any;
}

interface State {
  bannedList: number[];
  unblockedList: number[];
}
class ChatBanned extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bannedList: [],
      unblockedList: [],
    };
  }

  render() {
    const { banned } = this.props;

    return (
      <div className="content-modal blacklist">
        {banned.length ? (
          <div className="text">
            <span>Здесь отображаются заблокированные вами пользователи.</span>
          </div>
        ) : (
          <div className="text">
            <span>Нет заблокированных пользователей.</span>
          </div>
        )}

        <div className="list">
          <div className="blacklist__users">
            {banned.map((item, i) => (
              <UserIco
                typeLink={'unlink'}
                data={item}
                checking={true}
                checkedUser={this.isChecked(item.id)}
                toggleList={this.toggleList}
                key={`userIco${i}`}
              />
            ))}
          </div>
        </div>

        {banned.length ? (
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
              this.unblocked();
            }}
          >
            <div className="btn_line blacklist_buttons">
              <input type="submit" value="Разблокировать" className="blue_btn"></input>
            </div>
          </form>
        ) : null}
      </div>
    );
  }

  componentDidMount() {
    const bannedArrID: number[] = [];
    this.props.banned.forEach(({ id }) => {
      bannedArrID.push(id);
    });

    this.setState(
      Object.assign({}, this.state, {
        bannedList: bannedArrID,
      })
    );
  }

  toggleList = (userID: number) => {
    const { bannedList, unblockedList } = this.state;
    let newBannedList: number[] = bannedList;
    let newUnblockedList: number[] = unblockedList;

    if (unblockedList.includes(userID)) {
      newUnblockedList.splice(unblockedList.indexOf(userID), 1);
      newBannedList = bannedList.concat(userID);
    } else {
      newUnblockedList = unblockedList.concat(userID);
      newBannedList.splice(bannedList.indexOf(userID), 1);
    }

    this.setState({
      bannedList: newBannedList,
      unblockedList: newUnblockedList,
    });
  };

  unblocked() {
    const { unblockedList } = this.state;
    const FetchAPI = new FetchMyTrack();
    const {
      props: { banned },
      state: { bannedList },
    } = this;

    const newBanned: IFPeople[] = [];
    banned.map((item) => {
      if (bannedList.includes(item.id)) {
        newBanned.push(item);
      }
    });

    if (unblockedList.length) {
      FetchAPI.unblockUsers(unblockedList).then((data) => {
        if (data.status == 'success') {
          this.props.updateBannedList(newBanned);
        }
      });
    }
  }

  isChecked(id: number) {
    const checked = this.state.unblockedList.includes(id);

    if (checked) {
      return true;
    }
    return false;
  }
}

export default ChatBanned;
