import * as React from 'react';

import './Hobbies.scss';

import { baseURL } from '../../../global/vars';
import ContentModalListHobbies from '../ContentModalListHobbies/ContentModalListHobbies';
import { IFHobby } from '../../../global/interfaces';
import { ContextModal } from '../../../services/Contexts';
import Edit from '../Edit/Edit';

interface Props {
  interests: IFHobby[];
  interestsAll: IFHobby[];
  updateStateProfile?: any;
  edit: boolean;
  limitItem?: number;
}

class Hobbies extends React.Component<Props> {
  render() {
    const { interests, interestsAll, updateStateProfile, edit, limitItem = 3 } = this.props;

    return (
      <div className="col-6 mt-40 hobbies">
        <div className="page_title">
          <h4>Интересы</h4>
          {edit ? (
            <Edit
              title="Интересы"
              ComponentModal={
                <ContentModalListHobbies
                  hobbies={interests}
                  all={interestsAll}
                  updateStateProfile={updateStateProfile}
                />
              }
              width="min"
            />
          ) : null}
        </div>

        {interests.map((item, i) =>
          i < limitItem ? (
            <div className="hobby" key={`hobby${i}`}>
              <i
                className="icon-hobby"
                style={{ backgroundImage: `url(${baseURL}/img/icons/competence/${item.image}.svg) ` }}
              ></i>
              <span>{item.name}</span>
            </div>
          ) : null
        )}

        {interests.length == 0 ? <p className="fs14w400">Пока не добавлен ни один интерес.</p> : null}

        {interests.length > limitItem ? (
          <ContextModal.Consumer>
            {({ showModal }) => {
              return (
                <a
                  href="#all"
                  className="link_border"
                  onClick={() => {
                    this.showInterests(showModal);
                  }}
                >
                  Смотреть все
                </a>
              );
            }}
          </ContextModal.Consumer>
        ) : null}
      </div>
    );
  }

  showInterests(showModal: any) {
    showModal({
      title: 'Интересы',
      content: <ContentModalListHobbies hobbies={this.props.interests} />,
      width: 'min',
    });
  }
}

export default Hobbies;
