import * as React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Moment from 'react-moment';

import './CardInfo.scss';
import '../../CardActivity/CardActivity.scss';

import { ArrDeclension, Declension } from '../../../services/Declension';

interface Props {
  startDate: string | null;
  endDate: string | null;
  contactGeoCode: string | null;
  contactAddress: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  type: string;
  organization: string;
  contactTitle?: string;
}

class CardInfo extends React.Component<Props> {
  render() {
    const {
      startDate,
      endDate,
      contactGeoCode,
      contactAddress,
      contactName,
      contactEmail,
      contactPhone,
      type,
      organization,
      contactTitle = 'Контакты',
    } = this.props;

    const codes = contactGeoCode?.split(',') || [];
    const geoCodes: number[] = [];
    codes.map((item) => {
      geoCodes.push(Number(item));
    });

    return (
      <div className="activity-card activity-card_100w">
        {contactGeoCode && codes.length == 2 ? (
          <div className="ymap">
            <YMaps>
              <div>
                <Map defaultState={{ center: geoCodes, zoom: 17 }} width={`100%`} height={`15vw`}>
                  <Placemark geometry={geoCodes} />
                </Map>
              </div>
            </YMaps>
          </div>
        ) : null}

        <div className="activity-card__content">
          <div className="activity-card__content-bottom">
            {startDate ? (
              <div className="activity-card__sign-board activity-card__sign-board_date">
                <span>
                  {`Начало ${Declension(2, ArrDeclension(type))}: `}
                  <Moment format="D MMMM YYYY г.">{startDate}</Moment>
                </span>
              </div>
            ) : null}
            {endDate ? (
              <div className="activity-card__sign-board activity-card__sign-board_date">
                <span>
                  {`Завершение ${Declension(2, ArrDeclension(type))}: `}
                  <Moment format="D MMMM YYYY г.">{endDate}</Moment>
                </span>
              </div>
            ) : null}
            {organization != '' ? (
              <div className="activity-card__sign-board activity-card__sign-board_organization">
                <span>{organization}</span>
              </div>
            ) : null}
            {contactAddress ? (
              <div className="activity-card__sign-board activity-card__sign-board_geo">
                <span>{contactAddress}</span>
              </div>
            ) : null}

            {contactName || contactEmail || contactPhone ? (
              <div>
                <div className="mt-24 mb-12">
                  <b>{contactTitle}</b>
                </div>
                {contactName ? (
                  <div className="activity-card__sign-board activity-card__sign-board_user">
                    <span>{contactName}</span>
                  </div>
                ) : null}
                {contactEmail ? (
                  <div className="activity-card__sign-board activity-card__sign-board_mail">
                    <span>{contactEmail}</span>
                  </div>
                ) : null}
                {contactPhone ? (
                  <div className="activity-card__sign-board activity-card__sign-board_phone">
                    <span>{contactPhone}</span>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default CardInfo;
