import * as React from 'react';

import './ProjectTasks.scss';

import CardWorks from '../../components/CardWorks/CardWorks';
import { getTypeAndIDPage } from '../../global/functions';
import { IFImages, IFMentor, IFTasks, IFPeople, IFWorks } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';
import Mentors from '../../components/detailPageActivity/Mentors/Mentors';
import Slider from 'react-slick';
import UserIco from '../../components/UserIco/UserIco';

interface Props {}

interface State {
  name: string;
  image: IFImages;
  myTasks: IFTasks[];
  tasks: IFTasks[];
  mentors: IFMentor[];
  students: IFPeople[];
  answers: {
    date: string;
    id: number;
    image: string; // исправить api
    liked: null;
    likes: number;
    name: string;
    surname: string;
    title: string;
    video: null;
  }[];
}

class ProjectTasks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: 'Тема',
      image: { original: '' },
      myTasks: [],
      tasks: [],
      mentors: [],
      students: [],
      answers: [],
    };
  }

  render() {
    const { name, image, myTasks, tasks, mentors, students, answers } = this.state;
    const { idPage, typePage } = getTypeAndIDPage();

    let cards: IFWorks[] = [];
    answers.forEach((item) => {
      cards.push({
        activity: '',
        parent: 0,
        id: item.id,
        image: item.image,
        name: item.title,
        u_name: item.name,
        u_surname: item.surname,
        publish_time: item.date,
        video_id: item.video,
        co_type: null,
        module: null,
      });
    });

    const settings = {
      // autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      className: 'slider-students',
    };

    return (
      <div className="page project-tasks">
        <div className="page__container">
          <div className="page__content-full">
            <div
              className="banner small"
              style={{
                backgroundImage: `url(/storage/projectActivityCard/${image.original})`,
              }}
            >
              <div className="text" id="title">
                <span>Проект</span>
                <h2>{name}</h2>
              </div>
              <div className="deskr_page_link">
                <a href={`/${typePage}/${idPage}`}>
                  Подробнее о проекте <i className="icon-a-arrow_right-button"></i>
                </a>
              </div>
            </div>

            <div className="mt-24">
              <div className="page_title publications_title">
                <h3>Мои задачи</h3>
              </div>
              <div id="myTasks" className="tasks_list">
                {myTasks.map(({ name, description, student_count }, i) => (
                  <a href="#task_modal_1" className="open_modal task_card" key={`card${i}`}>
                    <div className="content">
                      <div className="name">
                        <b>{name}</b>
                      </div>
                      <div className="text">
                        <span>{description}</span>
                      </div>
                      <div className="task_info_line">
                        <div className="party">
                          <span>{student_count}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="mt-40">
              <div className="page_title publications_title">
                <h3>Проектные задачи</h3>
              </div>

              <div id="course_tasks" className="tasks_list color_bg">
                {tasks.map(({ name, description, student_count }, i) => (
                  <a href="#task_modal_1" className="open_modal task_card" key={`card${i}`}>
                    <div className="content">
                      <div className="name">
                        <b>{name}</b>
                      </div>
                      <div className="text">
                        <span>{description}</span>
                      </div>
                      <div className="task_info_line">
                        <div className="party">
                          <span>{student_count}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="mt-40">
              <div id="user_results" className="page_title mt-40">
                <h3>Результаты ({cards.length})</h3>
              </div>
              <div className="list">
                <div className="news pt-0">
                  <div className="news__list-grid news__list-grid_4item">
                    <a href="#add_result_modal" className="add_result open_modal">
                      <div className="icon"></div>
                      <span>Добавить результат</span>
                    </a>

                    {cards.map((item: IFWorks, i) => (
                      <div className="project-tasks__card" key={`card${i}`}>
                        <CardWorks card={item} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <Mentors title={`Наставники (${mentors.length})`} mentors={mentors} />

            <div className="row mt-40" id="project_members">
              <div className="col-12">
                <div className="page_title publications_title">
                  <h3 id="user_title">Участники ({students.length})</h3>
                </div>

                {students.length > 9 ? (
                  <Slider {...settings}>
                    {students.map((item, i) => (
                      <UserIco typeLink={'user'} data={item} key={`userIco${i}`} />
                    ))}
                  </Slider>
                ) : (
                  <div className="project-tasks__students">
                    {students.map((item, i) => (
                      <div className="project-tasks__student" key={`userIco${i}`}>
                        <UserIco typeLink={'user'} data={item} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    const { idPage } = getTypeAndIDPage();

    FetchAPI.getTask(idPage).then((data) => {
      const img = /\/([A-Za-z0-9_.]*)"}/.exec(data.label);
      console.log(data.students);
      this.setState({
        name: data.name,
        image: { original: img ? img[1] : '' }, // исправить api
        myTasks: data.myTasks,
        tasks: data.tasks,
        answers: data.answers,
        mentors: data.mentors,
        students: data.students,
      });
    });
  }
}

export default ProjectTasks;
