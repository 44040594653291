import * as React from 'react';
import SubscriptionActivation from '../../components/SubscriptionActivation/SubscriptionActivation';
import TableOrder from '../../components/TableOrder/TableOrder';
import Tabs from '../../components/Tabs/Tabs';
import { IFDataOrders } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';

import './Orders.scss';

interface Props {}

interface State {
  active: number;
  all: IFDataOrders;
  qualifications: IFDataOrders;
  courses: IFDataOrders;
  projects: IFDataOrders;
  challenges: IFDataOrders;
  events: IFDataOrders;
}

const blankDataOrders: IFDataOrders = {
  data: [],
  pageNumber: 1,
  totalCount: 0,
  totalPages: 1,
};

class Orders extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
      all: blankDataOrders,
      qualifications: blankDataOrders,
      courses: blankDataOrders,
      projects: blankDataOrders,
      challenges: blankDataOrders,
      events: blankDataOrders,
    };
  }

  render() {
    const { all, qualifications, courses, projects, challenges, events } = this.state;
    return (
      <div className="page right_block order">
        <div className="content order__page">
          <Tabs
            tabs={[`Все (${all.totalCount})`, 'Квалификации', 'Курсы', 'Проекты', 'Челленджи', 'События']}
            tabsContent={[
              <TableOrder order={all} typeContent="all" updateList={this.updateList} key={`table1`} />,
              <TableOrder
                order={qualifications}
                typeContent="qualifications"
                updateList={this.updateList}
                key={`table2`}
              />,
              <TableOrder
                order={courses}
                typeContent="courses"
                updateList={this.updateList}
                key={`table3`}
              />,
              <TableOrder
                order={projects}
                typeContent="projects"
                updateList={this.updateList}
                key={`table4`}
              />,
              <TableOrder
                order={challenges}
                typeContent="challenges"
                updateList={this.updateList}
                key={`table5`}
              />,
              <TableOrder order={events} typeContent="events" updateList={this.updateList} key={`table6`} />,
            ]}
            setActive={this.setActive}
            active={this.state.active}
          />

          <SubscriptionActivation />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.FetchAPI.ordersList().then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          all: data.all,
          qualifications: data.qualifications,
          courses: data.courses,
          projects: data.projects,
          challenges: data.challenges,
          events: data.events,
        })
      );
    });
  }

  setActive = (active: number) => {
    this.setState(Object.assign({}, this.state, { active: active }));
  };

  updateList = (newData: { [key: string]: IFDataOrders }) => {
    this.setState(Object.assign({}, this.state, newData));
  };
}

export default Orders;
