import * as React from 'react';

import './Edit.scss';

import { bodyHidden } from '../../../global/functions';
import { ContextModal } from '../../../services/Contexts';

interface Props {
  title: string;
  ComponentModal: any;
  width: string;
}
interface State {
  edit: boolean;
}

class Edit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  render() {
    const { title, ComponentModal, width } = this.props;

    const { edit } = this.state;

    return (
      <div
        className={`edit ${edit ? 'edit_open' : ''}`}
        onClick={() => {
          this.showEdit();
        }}
      >
        {edit ? (
          <ContextModal.Consumer>
            {({ showModal }) => {
              return (
                <div className="edit__popup">
                  <a
                    href="#hobbies_modal"
                    className="open_modal"
                    onClick={() => {
                      showModal({ title, content: ComponentModal, width });
                      bodyHidden();
                    }}
                  >
                    Редактировать
                  </a>
                </div>
              );
            }}
          </ContextModal.Consumer>
        ) : null}
      </div>
    );
  }

  showEdit() {
    this.setState(
      Object.assign({}, this.state, {
        edit: !this.state.edit,
      })
    );
  }
}

export default Edit;
