import * as React from 'react';
import { ContextModal } from '../../services/Contexts';

import './Activity.scss';

import ActivityList from '../../components/activity/ActivityList/ActivityList';
import { IFCardActivity, IFPeople, IFShowModal, IFTasks } from '../../global/interfaces';
import Table from '../../components/Table/Table';
import FetchMyTrack from '../../services/FetchMyTrack';
import ModalActivity from '../../components/activity/ModalActivity/ModalActivity';
import ContentModalListAct from '../../components/activity/ContentModalListAct/ContentModalListAct';
import RightProfile from '../../components/RightProfile/RightProfile';
import { ClosedModal } from '../../services/Modal';

interface Props {
  profile: IFPeople;
}

interface State {
  course_tasks: IFTasks[];
  tasks: IFTasks[];
  courses: IFCard[];
  challenges: IFCard[];
  events: IFCard[];
  projects: IFCard[];
  modalActive: boolean;
  modal: {
    title: string;
    content: React.Component | null;
  };
}

interface IFCard {
  id: number;
  image: string;
  name: string;
  organization: string;
  price: number;
  request: null;
  signDate: string;
  status: number;
  student_count: number;
  video: null;
  winner: number;
  type?: string;
}

class Activity extends React.Component<Props, State> {
  dataCourse: IFCardActivity[];
  dataChallenges: IFCardActivity[];
  dataEvents: IFCardActivity[];
  dataProjects: IFCardActivity[];

  constructor(props: Props) {
    super(props);
    this.state = {
      course_tasks: [],
      tasks: [],
      courses: [],
      challenges: [],
      events: [],
      projects: [],
      modalActive: false,
      modal: {
        title: 'Заголовок',
        content: null,
      },
    };
    this.dataCourse = [];
    this.dataChallenges = [];
    this.dataEvents = [];
    this.dataProjects = [];
  }

  render() {
    const { profile } = this.props;
    const { dataCourse, dataChallenges, dataEvents, dataProjects } = this;
    const { course_tasks, tasks, courses, challenges, events, projects, modal } = this.state;

    dataCourse.splice(0, dataCourse.length);
    dataChallenges.splice(0, dataChallenges.length);
    dataEvents.splice(0, dataEvents.length);
    dataProjects.splice(0, dataProjects.length);

    const arrData: IFCardActivity[][] = [dataCourse, dataChallenges, dataEvents, dataProjects];

    [courses, challenges, events, projects].forEach((act, i) => {
      act.forEach((item) => {
        const data: IFCardActivity = {
          city_name: null,
          course_count: 0, // ?
          date: item.signDate,
          id: item.id,
          image: {
            crop: '', //
            original: '', //
            thumb: '', //
          },
          is_online: 1, // ?
          name: item.name,
          organization: item.organization,
          price: item.price,
          status: item.status,
          student_count: item.student_count,
          type: item.type,
        };
        arrData[i].push(data);
      });
    });

    return (
      <React.Fragment>
        <ContextModal.Provider
          value={{ component: this, closedModal: ClosedModal, showModal: this.showModal }}
        >
          <div className="page right_block page_activity">
            <div className="page__container">
              <div className="page__content-full">
                <div className="news mb-40">
                  <div className="page_title">
                    <h4>Квалификации</h4>
                  </div>

                  <div className="qualify_list">
                    <p className="mt-16">
                      У тебя еще нет открытых квалификаций,
                      <a href={`/tracks`}> получи доступ к квалификациям</a> и приступи к прохождению!
                    </p>
                  </div>
                </div>

                <div className=" tasks-tabel course-tasks">
                  <div className="page_title">
                    <h4>Курсовые задания</h4>
                    <a
                      href="#course_tasks_modal"
                      className="modal-course-tasks open_modal border_btn"
                      onClick={(e) => {
                        this.showModal({ e });
                      }}
                    >
                      Все задания
                    </a>
                  </div>
                  <Table tasks={course_tasks} heads={['Курс', 'Задание', 'Статус']} type="course" />
                </div>

                <ActivityList
                  title="Курсы"
                  type="course"
                  cards={dataCourse}
                  countItem={3}
                  classNameAll="modal-course"
                />

                <div className=" tasks-tabel project-tasks">
                  <div className="page_title">
                    <h4>Проектные задания</h4>
                    <a
                      href="#tasks_modal"
                      className="modal-projects-tasks open_modal border_btn"
                      onClick={(e) => {
                        this.showModal({ e });
                      }}
                    >
                      Все задания
                    </a>
                  </div>
                  <Table tasks={tasks} heads={['Проект', 'Задача', 'Статус']} type="project" />
                </div>

                <ActivityList
                  title="Проекты"
                  type="project"
                  cards={dataProjects}
                  countItem={3}
                  classNameAll="modal-projects"
                />

                <ActivityList
                  title="Челленджи"
                  type="challenge"
                  cards={dataChallenges}
                  countItem={3}
                  classNameAll="modal-challenges"
                />

                <ActivityList
                  title="События"
                  type="event"
                  cards={dataEvents}
                  countItem={3}
                  classNameAll="modal-events"
                />
              </div>
            </div>
          </div>
          {this.state.modalActive ? <ModalActivity title={modal.title} content={modal.content} /> : null}
          <RightProfile profile={profile} />
        </ContextModal.Provider>
      </React.Fragment>
    );
  }

  showModal = ({ e }: IFShowModal) => {
    let content, title;
    if (e.target.classList.contains('modal-projects-tasks')) {
      content = <Table tasks={this.state.tasks} heads={['Проект', 'Задача', 'Статус']} type="project" />;
      title = 'Все задания к проектам';
    }
    if (e.target.classList.contains('modal-course-tasks')) {
      content = <Table tasks={this.state.course_tasks} heads={['Курс', 'Задание', 'Статус']} type="course" />;
      title = 'Все задания к курсам';
    }

    if (e.target.classList.contains('modal-projects')) {
      content = <ContentModalListAct cards={this.dataProjects} type="project" />;
      title = 'Проекты';
    }
    if (e.target.classList.contains('modal-course')) {
      content = <ContentModalListAct cards={this.dataCourse} type="course" />;
      title = 'Курсы';
    }
    if (e.target.classList.contains('modal-challenges')) {
      content = <ContentModalListAct cards={this.dataChallenges} type="challenge" />;
      title = 'Челленджи';
    }
    if (e.target.classList.contains('modal-events')) {
      content = <ContentModalListAct cards={this.dataEvents} type="event" />;
      title = 'События';
    }

    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: title,
          content: content,
        },
      })
    );
  };

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.getActivities().then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          course_tasks: data.course_tasks,
          tasks: data.tasks,
          courses: data.courses,
          challenges: data.challenges,
          events: data.events,
          projects: data.projects,
        })
      );
    });
  }
}

export default Activity;
