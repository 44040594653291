import * as React from 'react';

import './App.scss';
import './old/index';

import { IFPeople, IFFitlers, Config } from './global/interfaces';
import FetchMyTrack from './services/FetchMyTrack';
import { ContextApp } from './services/Contexts';
import RoutesAPP from './components/Routes/Routes';

interface Props {}
interface State {
  data: {
    students: IFPeople[];
    mentors: IFPeople[];
    filters: IFFitlers;
  };
  config: Config;
  geo: number;
  profile: IFPeople;
}

class App extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      data: {
        students: [],
        mentors: [],
        filters: {},
      },
      config: {
        page: 1,
        languages: '',
        interests: '',
        tech_skills: '',
        track_id: '',
        way_id: '',
        gender: '',
        fresh: 1,
        needle: '',
      },
      geo: 85,
      profile: {
        name: '',
        surname: '',
        avatar: '',
        text: '',
        id: 0,
      },
    };
  }

  render() {
    return (
      <ContextApp.Provider
        value={{
          updateGeo: this.updateGeo,
          updateProfile: this.updateProfile,
          profile: this.state.profile,
        }}
      >
        <RoutesAPP geo={this.state.geo} profile={this.state.profile} />
      </ContextApp.Provider>
    );
  }

  updateGeo = async (newGeo: string) => {
    await this.setState(
      Object.assign({}, this.state, {
        geo: parseInt(newGeo),
      })
    );
  };

  updateProfile = ({
    name,
    surname,
    avatar,
    text,
    id,
  }: {
    name: string;
    surname: string;
    avatar: string;
    text: string;
    id: number;
  }) => {
    this.setState(
      Object.assign({}, this.state, {
        profile: {
          name,
          surname,
          avatar,
          text,
          id,
        },
      })
    );
  };
}

export default App;
