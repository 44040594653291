import * as React from 'react';
import Moment from 'react-moment';

import './DetailChat.scss';

import {
  IFChatMenu,
  IFDetailChatInfo,
  IFDetailChatMessage,
  IFDetailChatMessageByDays,
  IFModal,
  IFPeople,
} from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';
import MenuChat from '../../components/messages/MenuChat/MenuChat';
import { getTypeAndIDPage } from '../../global/functions';
import MessagesList from '../../components/detailChat/MessagesList/MessagesList';
import ModalActivity from '../../components/activity/ModalActivity/ModalActivity';
import { ContextModal } from '../../services/Contexts';
import { ClosedModal } from '../../services/Modal';
import BlockedUser from '../../components/detailChat/BlockedUser/BlockedUser';
import DeleteChat from '../../components/detailChat/DeleteChat/DeleteChat';
import CreateGroupChat from '../../components/messages/CreateGroupChat/CreateGroupChat';
import LeavedChat from '../../components/detailChat/LeavedChat/LeavedChat';

interface Props {
  profile: IFPeople;
}

interface State {
  chatInfo: IFDetailChatInfo;
  lastTimeActive: string;
  messages: IFDetailChatMessageByDays[];

  modalActive: boolean;
  modal: IFModal;
  msg: string;
  file: Blob | null;
  nameFile: string;
}

class DetailChat extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  idPage = getTypeAndIDPage().idPage;
  fileInput: React.RefObject<HTMLInputElement>;
  defualtTextFile: string;

  constructor(props: Props) {
    super(props);
    this.defualtTextFile = 'Прикрепить файл (до 30мб)';
    this.state = {
      // banned: false,
      chatInfo: {
        id: 0,
        chat_name: null,
        type: 1,
        users_count: 0,
        owner_id: null,
        avatar: { default: true, path: '' },
        users: [
          {
            avatar: '',
            banned: 0,
            id: 0,
            name: '',
            surname: '',
            type: '',
          },
        ],
      },
      lastTimeActive: '',
      messages: [],
      // moderator: false,

      modalActive: false,
      modal: {
        title: 'Заголовок',
        content: null,
        width: '',
      },
      msg: '',
      file: null,
      nameFile: this.defualtTextFile,
    };
    this.fileInput = React.createRef();
  }

  render() {
    const { chatInfo, lastTimeActive, messages, modalActive, modal, nameFile } = this.state;
    const { profile } = this.props;

    let nameChat = 'Чат';
    let menu: IFChatMenu[] = [];

    const user = chatInfo.users[0];
    // 1 - диалог, 0 - чат
    if (chatInfo.type == 1) {
      nameChat = `${user.name} ${user.surname}`;
      menu = [
        {
          name: 'Открыть профиль',
          href: `/profile/${user.type}/${user.id}`,
          icon: require('./img/a-person-outline.svg').default,
          clickFunction: () => {},
        },
      ];
      if (user.banned) {
        menu.push({
          name: 'Разблокировать',
          href: '#blacklist',
          icon: require('./img/a-person-block.svg').default,
          clickFunction: this.unBlockedUser,
          arg: user.id,
        });
      } else {
        menu.push({
          name: 'Заблокировать',
          href: '#blacklist',
          icon: require('./img/a-person-block.svg').default,
          clickFunction: this.blockedUser,
          arg: user.id,
        });
      }
      menu.push({
        name: 'Удалить диалог',
        href: '#del',
        icon: require('./img/a-delete.svg').default,
        clickFunction: this.deleteChat,
      });
    } else {
      nameChat = chatInfo.chat_name ? chatInfo.chat_name : 'Чат';
      if (profile.id == chatInfo.owner_id) {
        menu.push(
          {
            name: 'Управление',
            href: `#editChat`,
            icon: require('./img/a-person-outline.svg').default,
            clickFunction: this.showEditChat,
            arg: { nameChat, chatInfo },
          },
          {
            name: 'Удалить чат',
            href: '#del',
            icon: require('./img/a-delete.svg').default,
            clickFunction: this.deleteChat,
          }
        );
      } else {
        menu.push({
          name: 'Выйти из чата',
          href: '#leaveChat',
          icon: require('./img/a-logout.svg').default,
          clickFunction: this.cleaveChat,
        });
      }
    }

    return (
      <React.Fragment>
        <div className="page detal-chat">
          <div className="msgs_page">
            <div className="open_dialog detal-chat__place-dialog">
              <div className="header">
                <a href="/messages" className="back">
                  <img src={require('./img/a-arrow-back.svg').default} alt="" />
                  <span>Назад</span>
                </a>

                <div className="name">
                  <h4>{nameChat}</h4>
                  <span>
                    был(а) онлайн{' '}
                    <Moment fromNow add={{ hours: 7 }}>
                      {lastTimeActive}
                    </Moment>
                  </span>
                </div>

                <MenuChat menu={menu} />
              </div>

              <MessagesList profile={profile} messages={messages} />

              {user.banned ? (
                <div className="send_msg_form">
                  <div className="dialog_closed">
                    <div className="text">
                      <img src={require('./img/a-block.svg').default} alt=""></img>
                      <span>Сообщения ограничены, пользователь заблокирован</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="send_msg_form">
                  <form
                    action=""
                    className="detal-chat__form-send"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.sendMsg();
                    }}
                  >
                    <textarea
                      name="message"
                      placeholder="Сообщение"
                      onChange={(e) => {
                        this.inputMsg(e);
                      }}
                    ></textarea>
                    <div className="detal-chat__form-options">
                      <div className="input file mt-8 mb-0">
                        <label htmlFor="cover_news">{nameFile}</label>
                        <input
                          type="file"
                          id="cover_news"
                          name="file"
                          multiple={false}
                          onChange={(e) => {
                            this.loadingFile(e);
                          }}
                          ref={this.fileInput}
                        />
                      </div>
                      <input type="submit" className="blue_btn w100" value="Отправить" />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        {modalActive ? (
          <ContextModal.Provider value={{ component: this, closedModal: ClosedModal }}>
            <ModalActivity title={modal.title} content={modal.content} width={modal.width} />
          </ContextModal.Provider>
        ) : null}
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.FetchAPI.getChatPage(this.idPage, 0).then((data) => {
      this.setState({
        chatInfo: data.chat_info,
        lastTimeActive: data.last_time_active,
        messages: FIX_apiData(data.messages), // поменять после обновления api
      });
    });

    // this.FetchAPI.auth().then((data) => {});
  }

  blockedUser = (idUser: number) => {
    const user = this.state.chatInfo.users[0];
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Заблокировать',
          content: (
            <BlockedUser
              component={this}
              idChat={this.idPage}
              idUser={idUser}
              text={`Вы уверены, что хотите заблокировать ${user.name} ${user.surname}?
                <br> Василийq Тёркинq больше не сможет вам писать.`}
              banned={user.banned}
            />
          ),
          width: 'min',
        },
      })
    );
  };

  unBlockedUser = (idUser: number) => {
    const user = this.state.chatInfo.users[0];
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Разблокировать',
          content: (
            <BlockedUser
              component={this}
              idChat={this.idPage}
              idUser={idUser}
              text={`Вы уверены, что хотите разблокировать ${user.name} ${user.surname}?`}
              banned={user.banned}
            />
          ),
          width: 'min',
        },
      })
    );
  };

  deleteChat = () => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Удаление чата',
          content: <DeleteChat component={this} idChat={this.idPage} />,
          width: 'min',
        },
      })
    );
  };

  showEditChat = (options: { nameChat: string; chatInfo: IFDetailChatInfo }) => {
    const { nameChat, chatInfo } = options;

    const image = !chatInfo.avatar.default ? chatInfo.avatar.path : null;
    const checked = chatInfo.avatar.default ? 3 : null;

    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Управление чатом',
          content: (
            <CreateGroupChat
              profile={this.props.profile}
              editChat={{
                name: nameChat,
                checked: checked,
                image: image,
                members: chatInfo.users,
              }}
              buttonName="Сохранить изменения"
              component={this}
            />
          ),
          width: 'min',
        },
      })
    );
  };

  cleaveChat = () => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Выход из чата',
          content: <LeavedChat component={this} idChat={this.idPage} />,
          width: 'min',
        },
      })
    );
  };

  inputMsg(e: any) {
    this.setState(
      Object.assign({}, this.state, {
        msg: e.target.value,
      })
    );
  }

  sendMsg = () => {
    const { file } = this.state;
    const data = new FormData();

    data.append('chat_id', this.idPage.toString());
    data.append('message', this.state.msg);

    if (file) {
      data.append('file', file);
    }

    this.FetchAPI.sendMsgGroup(data).then((data) => {
      console.log(data);
    });
  };

  loadingFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.fileInput.current?.files != null) {
      this.setState({
        nameFile: this.fileInput.current?.files[0].name,
        file: e.target.files !== null ? e.target.files[0] : null,
      });
    }
  }
}

export default DetailChat;

const FIX_apiData = (data: any) => {
  // удалить после обновления api
  const dates: string[] = Object.keys(data);
  const arrs: IFDetailChatMessage[][] = Object.values(data);
  const messages: IFDetailChatMessageByDays[] = [];

  dates.forEach((date, i) => {
    messages.push({
      date: date,
      messages: arrs[i],
    });
  });

  return messages;
};
