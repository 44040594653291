import React from 'react';
import { checkTagLine } from '../../../global/functions';
import { IFCardActivity } from '../../../global/interfaces';
import { ContextModal } from '../../../services/Contexts';
import CardActivity from '../../CardActivity/CardActivity';

interface Props {
  title: string;
  cards: IFCardActivity[];
  countItem?: number;
  classNameAll: string;
  type: string;
}

class ActivityList extends React.Component<Props> {
  render() {
    const { title, cards, countItem, classNameAll, type } = this.props;

    return (
      <div className="news">
        <div className="page_title">
          <h4>{title}</h4>
          <ContextModal.Consumer>
            {({ showModal }) => {
              return (
                <a
                  href="#"
                  className={`border_btn ${classNameAll}`}
                  onClick={(e) => {
                    showModal({ e });
                  }}
                >
                  Все
                </a>
              );
            }}
          </ContextModal.Consumer>
        </div>

        <div className="news_list news__list-grid">
          {countItem
            ? cards.map((item, i) =>
                countItem > i ? (
                  <CardActivity
                    pageName={type}
                    data={item}
                    tagLine={checkTagLine(type, item)}
                    key={`card${i}`}
                  />
                ) : null
              )
            : cards.map((item, i) => (
                <CardActivity
                  pageName={type}
                  data={item}
                  tagLine={checkTagLine(type, item)}
                  key={`card${i}`}
                />
              ))}
        </div>
      </div>
    );
  }
}

export default ActivityList;
