import { getTypeAndIDPage } from '../global/functions';
import { varsString } from '../global/vars';
import FetchMyTrack from './FetchMyTrack';

interface IFToggleOpt {
  e: any;
  classItem: string;
  arrIdItems: number[];
  itemsBefore: any[];
  allItems: any[];
}

export const ToogleOptionsProfile = ({ e, classItem, arrIdItems, itemsBefore, allItems }: IFToggleOpt) => {
  let id: number;
  if (e.target.classList.contains(classItem)) {
    id = e.target.getAttribute('id');
  } else {
    id = e.target.closest(`.${classItem}`).getAttribute('id');
  }

  if (arrIdItems.includes(Number(id))) {
    arrIdItems.splice(arrIdItems.indexOf(Number(id)), 1);

    const itemsAfter = [...itemsBefore];
    const field = itemsAfter.find((field) => field.id == id);
    if (field) {
      itemsAfter.splice(itemsAfter.indexOf(field), 1);
    }

    return {
      itemsAfter,
      arrIdItemsAfter: arrIdItems,
    };
  } else {
    const field = allItems.find((field) => field.id == id);

    if (field) {
      return {
        itemsAfter: [...itemsBefore, field],
        arrIdItemsAfter: [...arrIdItems, field.id],
      };
    }
  }
};

export const FetchGetProfile = (Component: React.Component, type: string, idVal?: number) => {
  const FetchAPI = new FetchMyTrack();
  let id = 1;
  if (idVal) {
    id = idVal;
  } else {
    const { idPage } = getTypeAndIDPage();
    id = idPage != 0 ? idPage : 1;
  }

  FetchAPI.getProfile(id, type).then((data) => {
    if (type == varsString.student) {
      updateProfileUser(data, Component, id);
    }
    if (type == varsString.mentor) {
      updateProfileMentor(data, Component, id);
    }
  });
};

const updateProfileUser = (data: any, Component: React.Component, id: number) => {
  Object.keys(data.line_after).forEach((item) => {
    Object.assign(data.line_after[item], { date: item });
  }); // убрать после обновления api
  const line = [].concat(Object.values(data.line), Object.values(data.line_after)); // убрать после обновления api

  Component.setState({
    idProfile: id,
    name: data.name,
    surname: data.surname,
    avatar: data.avatar,
    line: line, // нужно переделать api
    achievements: [
      {
        name: 'Квалификации',
        type: 'course',
        count: data.achievements.length,
        src: require('../components/profile/Achievements/img/achivements.svg').default,
        items: data.achievements,
      },
      {
        name: 'Челленджи',
        type: 'challenge',
        count: data.challenges.length,
        src: require('../components/profile/Achievements/img/challenges.svg').default,
        items: data.challenges,
      },
      {
        name: 'События',
        type: 'event',
        count: data.events.length,
        src: require('../components/profile/Achievements/img/event.svg').default,
        items: data.events,
      },
      {
        name: 'Проекты',
        type: 'project',
        count: data.projects.length,
        src: require('../components/profile/Achievements/img/projects.svg').default,
        items: data.projects,
      },
      {
        name: 'Курсы',
        type: 'course',
        count: data.courses.length,
        src: require('../components/profile/Achievements/img/courses.svg').default,
        items: data.courses,
      },
    ],
    interests: data.interests,
    interestsAll: data.interests_options,
    languages: data.languages,
    skills: data.tech_skills,
    skillsAll: data.tech_skills_options,
    topUsers: data.rating_before,
    ratingPlace: data.user_place,
    publications: data.user_publications,
    medals: data.achievement_medals,
    // star: data.star,
  });
};

const updateProfileMentor = (data: any, Component: React.Component, id: number) => {
  Component.setState({
    idProfile: id,
    name: data.name,
    surname: data.surname,
    avatar: data.avatar,
    interests: data.interests,
    interestsAll: data.interests_options,
    languages: data.languages,
    skills: data.tech_skills,
    skillsAll: data.tech_skills_options,
    topUsers: data.rating_before,
    ratingPlace: data.user_place,
    statusMessage: data.status_message,
    lastOnline: data.last_online,
    // star: data.star,
  });
};
