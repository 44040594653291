import * as React from 'react';

import Tabs from '../../components/Tabs/Tabs';
import TabsContentWorks from '../../components/TabsContentWorks/TabsContentWorks';

interface Props {}

interface State {
  active: number;
}

class Works extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  render() {
    const {} = this.props;

    return (
      <div className="page">
        <div className="page__container">
          <div className="page__content-full" id="mainContent">
            <div className="page_title">
              <h2>Витрина работ</h2>
            </div>
            <div className="activityContainer">
              <div className="row mt-40">
                <div className="col-12">
                  <Tabs
                    tabs={['Все', 'Проекты', 'Курсы', 'Челленджи', 'События']}
                    tabsContent={[
                      <TabsContentWorks type="all" key="tabAll" />,
                      <TabsContentWorks type="project" key="tabProject" />,
                      <TabsContentWorks type="course" key="tabCourse" />,
                      <TabsContentWorks type="challenge" key="tabChallenge" />,
                      <TabsContentWorks type="event" key="tabEvent" />,
                    ]}
                    setActive={this.setActive}
                    active={this.state.active}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  setActive = (active: number) => {
    this.setState({ active: active });
  };
}

export default Works;
