import * as React from 'react';

import { IFCompetenciesTracks } from '../../../global/interfaces';
import { baseURL } from '../../../global/vars';

import HistoryDiagram from './HistoryDiagram';
import HistoryStart from './HistoryStart';

interface Props {
  track: IFCompetenciesTracks;
  listTracks: IFCompetenciesTracks[];
}

export class HistoryActived extends React.Component<Props> {
  render() {
    const {
      track: { name, competencies, id, sliderActivity },
    } = this.props;

    let sumPoints = 0;
    competencies.forEach((item) => {
      sumPoints += item.score;
    });

    let content;
    if (sumPoints == 0) {
      content = <HistoryStart sliderActivity={sliderActivity} />;
    } else {
      content = <HistoryDiagram {...this.props} />;
    }

    return (
      <div className="competencies__history-map history-map inroads">
        <div className="treks_popup">
          <div className="title">
            <h4>Трек «{name}»</h4>
          </div>
          <div className="history-map__link">
            <a href={`${baseURL}/track/${id}`}>Подробнее о треке</a>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12">
              <path fill="#1588ee" fillRule="nonzero" d="M7 10.59L2.673 6 7 1.41 5.668 0 0 6l5.668 6z"></path>
            </svg>
          </div>
          {content}
        </div>
      </div>
    );
  }
}
