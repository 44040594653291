export const OnlyLetters = (value: string) => {
  if (/^[A-Za-zА-Яа-яЁё\s-]+$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const ItEmail = (value: string) => {
  if (!/^[0-9A-Za-z-._\@]+$/.test(value)) {
    return false;
  }

  if (value.split('@').length != 2) {
    return false;
  } else {
    if (!value.split('@')[1].includes('.')) {
      return false;
    }
  }

  return true;
};

export const StatusProcessingAPI = (data: any) => {
  let errorText = '',
    successText = '';
  if (data.status == 'fail') {
    if (data.errors) {
      errorText = data.errors;
    }
    if (data.errors.mail) {
      errorText = data.errors.mail;
    }
    if (data.errors.user) {
      errorText = data.errors.user;
    }
    if (data.errors.activation) {
      errorText = data.errors.activation;
    }
  }

  if (data.status == 'success') {
    successText = data.objects;
  }

  return { errorText, successText };
};
