import * as React from 'react';

import Search from '../Search/Search';
import FilterTabs from '../FilterTabs/FilterTabs';

interface Props {
  tabs: string[];
  tabsContent: React.ReactElement[];
  search?: boolean;
  filterTabs?: boolean;
  fetchGetFilteredPageMore?: any;
  fetchGetFilteredPage?: any;
  updateFilterTabs?: any;
  updateConfig?: any;
  setActive: any;
  active: number;
}

class Tabs extends React.Component<Props> {
  render() {
    const { tabs = [], tabsContent, search = false, filterTabs = false, updateFilterTabs } = this.props;
    const { active } = this.props;

    return (
      <div className="tabs">
        <ul className="tabs__caption">
          {tabs.map((item, i) => (
            <li
              className={`${active == i ? 'active' : ''}`}
              key={`tabs${i}`}
              onClick={() => this.props.setActive(i)}
            >
              {item}
            </li>
          ))}
        </ul>

        {search && <Search searchPlaceholder={'по имени'} />}

        {filterTabs && (
          <FilterTabs
            tabs={[
              { text: 'По рейтингу', fresh: 0 },
              { text: 'По дате регистрации', fresh: 1 },
            ]}
            updateFilterTabs={updateFilterTabs}
          />
        )}

        <div className={`tabs__content active`}>{tabsContent[active]}</div>
      </div>
    );
  }
}

export default Tabs;
