import { baseURL } from '../global/vars';

export default class FetchMyTrack {
  BaseUrl = `${baseURL}/api`;
  headers = {
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('token') || '',
    // Authorization: 'cNTONJaMBkPFGmb4oXQTIFzV1tznfHM6OFIQ6u3I1oNBB66I6xR9zZr0sy4k',
  };

  optionPOST(body: any) {
    return {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    };
  }

  // people
  async getPage() {
    const res = await fetch(`${this.BaseUrl}/people/getPage`);
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getFilteredPage(config: any, page: string) {
    const res = await fetch(`${this.BaseUrl}/${page}/getFilteredPage`, this.optionPOST(config));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getTracks(wayId: number) {
    const res = await fetch(`${this.BaseUrl}/challenge/getTracks`, this.optionPOST({ way_id: wayId }));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  // header
  async getTopProfile(auth: any) {
    const res = await fetch(`${this.BaseUrl}/student/getTopProfile`, this.optionPOST(auth));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  // login
  async register(
    role: string,
    name: string,
    surname: string,
    gender: number,
    region: number,
    email: string,
    password: string,
    password2: string,
    check: string
  ) {
    const res = await fetch(
      `${this.BaseUrl}/register`,
      this.optionPOST({
        role,
        name,
        surname,
        gender,
        geo_id: region,
        mail: email,
        password,
        password1: password2,
        check: check,
      })
    );

    return await res.json();
  }

  async refreshRegister(email: string) {
    const res = await fetch(
      `${this.BaseUrl}/register/refresh`,
      this.optionPOST({
        mail: email,
      })
    );

    return await res.json();
  }

  async login(mail: string, password: string) {
    const res = await fetch(`${this.BaseUrl}/login`, this.optionPOST({ mail, password }));

    return await res.json();
  }

  async resetpassword(email: string) {
    const res = await fetch(`${this.BaseUrl}/register/resetpassword`, this.optionPOST({ mail: email }));

    return await res.json();
  }

  async getFilterFields(page: string) {
    const res = await fetch(`${this.BaseUrl}/${page}/getFilterFields`);
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getFilterFieldsPOST(page: string, geo: number, Component: React.Component) {
    const res = await fetch(`${this.BaseUrl}/${page}/getFilterFields`, this.optionPOST({ geo_id: geo }));

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }

    res.json().then((data) => {
      Component.setState(
        Object.assign(Component.state, {
          filters: data,
        })
      );
      return;
    });
  }

  // async setGeoId(geoID: number) {
  //   const res = await fetch(
  //     `${this.BaseUrl}/student/setGeoId`,
  //     this.optionPOST({
  //       geo_id: geoID,
  //     })
  //   );
  //   if (!res.ok) {
  //     throw new Error(`Ошибка`);
  //   }
  //   return await res.json();
  // }

  async getMenu() {
    const res = await fetch(`${this.BaseUrl}/notify/getMenu`, this.optionPOST({}));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getTracksQualifications() {
    const res = await fetch(`${this.BaseUrl}/qualifications/getTracksQualifications`);
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getFilteredPageWorks(type: string, page: number) {
    const res = await fetch(
      `${this.BaseUrl}/works/getFilteredPage`,
      this.optionPOST({ type: type, page: page, mode: 0 })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async statistics() {
    const res = await fetch(`${this.BaseUrl}/statistics`);
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getActivities() {
    const res = await fetch(`${this.BaseUrl}/student/getActivities`, {
      headers: this.headers,
    });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getRecommend() {
    const res = await fetch(
      `${this.BaseUrl}/student/getRecommend`,
      this.optionPOST({ track: 1 }) // ?
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getProfile(id: number, type: string) {
    const res = await fetch(`${this.BaseUrl}/${type}/getProfile/${id}`, {
      headers: this.headers,
    });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async updateInterests(arrId: number[]) {
    const res = await fetch(
      `${this.BaseUrl}/student/updateInterests`,
      this.optionPOST({ interest_id: arrId }) // ?
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async sendUserIdea(data: FormData) {
    const res = await fetch(`${this.BaseUrl}/student/sendUserIdea`, { method: 'POST', body: data });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async removeLanguage(id: number) {
    const res = await fetch(`${this.BaseUrl}/student/removeLanguage`, this.optionPOST({ lng_id: id }));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async updateLanguage(id: number, idLevel: number) {
    const res = await fetch(
      `${this.BaseUrl}/student/updateLanguage`,
      this.optionPOST({ lng_id: id, lvl_id: idLevel })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async setLanguage(id: number, idLevel: number) {
    const res = await fetch(
      `${this.BaseUrl}/student/setLanguage`,
      this.optionPOST({ lng_id: id, lvl_id: idLevel })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async updateTechSkills(arrId: number[]) {
    const res = await fetch(
      `${this.BaseUrl}/student/updateTechSkills`,
      this.optionPOST({ tech_id: arrId }) // ?
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getCourse(id: number, urlRequest: string) {
    const res = await fetch(
      `${this.BaseUrl}${urlRequest}`,
      this.optionPOST({ course_id: id }) // ?
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getCompany(id: number) {
    const res = await fetch(
      `${this.BaseUrl}/organization/getCompany`,
      this.optionPOST({ organization_id: id }) // ?
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getBanner(type: string) {
    const res = await fetch(`${this.BaseUrl}/courses/getBanner`, this.optionPOST({ type: type }));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getSkillActivities(id: number, page: number) {
    const res = await fetch(
      `${this.BaseUrl}/skill/getSkillActivities`,
      this.optionPOST({ skill_id: id, page: page })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getCourseModule(courseID: number, moduleID: number) {
    const res = await fetch(
      `${this.BaseUrl}/courses/getCourseModule`,
      this.optionPOST({ course_module_id: moduleID, course_id: courseID })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getCourseModuleTask(courseID: number, moduleID: number, taskID: number) {
    const res = await fetch(
      `${this.BaseUrl}/courses/getCourseModuleTask`,
      this.optionPOST({ course_id: courseID, module_id: moduleID, task_id: taskID })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getTask(courseID: number) {
    const res = await fetch(`${this.BaseUrl}/project/getTask`, this.optionPOST({ course_id: courseID }));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getAnswer(id: number, type: string) {
    let request = '';
    // getChallengeAnswer переименовать в getAnswer и убрать switch
    switch (type) {
      case 'challenge':
        request = 'getChallengeAnswer';
        break;
      case 'project':
        request = 'getAnswer';
        break;
      case 'courses':
        request = 'getAnswer';
        break;
      case 'event':
        request = 'getAnswer';
        break;
      default:
        request = '';
    }
    if (request != '') {
      const res = await fetch(`${this.BaseUrl}/${type}/${request}`, this.optionPOST({ answer_id: id }));
      if (!res.ok) {
        throw new Error(`Ошибка`);
      }
      return await res.json();
    }
  }

  async setLike(id: number, type: string) {
    const res = await fetch(`${this.BaseUrl}/${type}/setLike`, this.optionPOST({ answer_id: id }));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async loadComments(type: string, id: number, count: number) {
    const res = await fetch(
      `${this.BaseUrl}/${type}/loadComments`,
      this.optionPOST({ answer_id: id, count: count })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async sendComment(type: string, id: number, idParent: number, comment: string) {
    const res = await fetch(
      `${this.BaseUrl}/${type}/sendComment`,
      this.optionPOST({ answer_id: id, parent_id: idParent, comment: comment, reply_on_id: idParent })
    );
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getQualification(id: number) {
    const res = await fetch(`${this.BaseUrl}/qualifications/page/${id}`, {
      headers: this.headers,
    });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getTrack(id: number) {
    const res = await fetch(`${this.BaseUrl}/track/getTrack`, this.optionPOST({ track_id: id }));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async changeCurrentTrack(id: number) {
    const res = await fetch(`${this.BaseUrl}/student/changeCurrentTrack`, this.optionPOST({ track_id: id }));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async deleteCurrentTrack() {
    const res = await fetch(`${this.BaseUrl}/student/deleteCurrentTrack`, {
      headers: this.headers,
    });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getSettings() {
    const res = await fetch(`${this.BaseUrl}/student/getSettings`, {
      headers: this.headers,
    });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async updateSettings(settings: { [key: string]: string | number | null }) {
    const res = await fetch(`${this.BaseUrl}/student/updateSettings`, this.optionPOST(settings));
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async ordersList() {
    const res = await fetch(`${this.BaseUrl}/license/list`, {
      headers: this.headers,
    });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async licenseActivation(key: string) {
    const res = await fetch(`${this.BaseUrl}/license/activation`, this.optionPOST({ key: key }));

    return res.json();
  }

  async ordersListMore(type: string, page: number) {
    const res = await fetch(`${this.BaseUrl}/license/list/${type}/${page}`, {
      headers: this.headers,
    });
    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getChatsPage(id: number) {
    const res = await fetch(`${this.BaseUrl}/chats/getChatsPage`, this.optionPOST({ user_id: id }));

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getChatsByName(id: number, search: string) {
    const res = await fetch(
      `${this.BaseUrl}/chats/getChatsByName`,
      this.optionPOST({ user_id: id, needle: search })
    );

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async unblockUsers(listID: number[]) {
    const res = await fetch(
      `${this.BaseUrl}/chats/unblockUsers`,
      this.optionPOST({ banned_users_list: listID })
    );

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async findUser(name: string) {
    const res = await fetch(`${this.BaseUrl}/chats/findUser`, this.optionPOST({ user_name: name }));

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async createChat(data: FormData) {
    const res = await fetch(`${this.BaseUrl}/chats/create`, { method: 'POST', body: data });

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async getChatPage(id: number, offset: number) {
    const res = await fetch(
      `${this.BaseUrl}/chats/getChatPage`,
      this.optionPOST({ chat_id: id, offset: offset })
    );

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async blockUser(idChat: number, idUser: number) {
    const res = await fetch(
      `${this.BaseUrl}/chats/blockUser`,
      this.optionPOST({ chat_id: idChat, user_id: idUser })
    );

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async deleteChat(idChat: number) {
    const res = await fetch(`${this.BaseUrl}/chats/deleteChat`, this.optionPOST({ chat_id: idChat }));

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async editChat(data: FormData) {
    const res = await fetch(`${this.BaseUrl}/chats/edit`, { method: 'POST', body: data });

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async removeChat(idChat: number) {
    const res = await fetch(`${this.BaseUrl}/chats/removeSelfFromChat`, this.optionPOST({ chat_id: idChat }));

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async unblockUser(idChat: number, idUser: number) {
    const res = await fetch(
      `${this.BaseUrl}/chats/unblockUser`,
      this.optionPOST({ chat_id: idChat, user_id: idUser })
    );

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async sendMsgGroup(data: FormData) {
    const res = await fetch(`${this.BaseUrl}/chat/group`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: this.headers.Authorization,
      },
    });

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async auth() {
    const res = await fetch(
      `${baseURL}/broadcasting/auth`,
      this.optionPOST({ socket_id: 664877593.53005003, channel_name: 'presence-89' })
    );

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }

  async notifyAll(page: number, type: string) {
    const res = await fetch(`${this.BaseUrl}/notify/${type}`, this.optionPOST({ page: page }));

    if (!res.ok) {
      throw new Error(`Ошибка`);
    }
    return await res.json();
  }
}
