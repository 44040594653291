import * as React from 'react';
// import Slider from 'react-slick'; /* Нет данных по топовым ученикам */

import './TabsContentPeople.scss';

import UserIco from '../../components/UserIco/UserIco';

import { IFPeople } from '../../global/interfaces';
import { count } from '../../global/vars';

interface Props {
  people: IFPeople[];
  active: number;
  fetchGetFilteredPageMore: any;
  updateConfig: any;
  fetchGetFilteredPage: any;
  typeLink: string;
}

interface State {
  page: number;
  buttonMorePeople: boolean;
}

class TabsContentPeople extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      page: 1,
      buttonMorePeople: true,
    };
  }

  render() {
    const { active, people, typeLink } = this.props;
    const { buttonMorePeople } = this.state;
    const peopleMore90 = people.length < count.peopleToPage ? false : true;

    /* Нет данных по топовым ученикам */
    /*const settings = {
      // autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 9,
      slidesToScroll: 9,
      className: 'party_slider2 line-slider2',
    };*/

    return (
      <div>
        <div className="people_list">
          {people.map((item, i) => (
            <UserIco typeLink={typeLink} data={item} key={`userIco${i}`} />
          ))}
        </div>

        {peopleMore90 && buttonMorePeople && (
          <div className="load_more text-center mt-24">
            <button className="border_btn big w230" onClick={() => this.loadMore(active)}>
              Показать еще
            </button>
          </div>
        )}

        {/* Нет данных по топовым ученикам */}
        {/* <div className="row mt-80">
          <div className="col-12">
            <div className="page_title publications_title">
              <h3>Топ учеников</h3>
            </div>
            <Slider {...settings}>
              {people.map((item, i) => (
                <UserIco data={item} key={`userIco${i}`} />
              ))}
            </Slider>
          </div>
        </div> */}
      </div>
    );
  }

  loadMore(indexTab: number) {
    this.props.fetchGetFilteredPageMore(indexTab);
  }
}

export default TabsContentPeople;
