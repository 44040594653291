import * as React from 'react';

import './CardNavigator.scss';

import { typeOnline, baseURL } from '../../global/vars';
import { IFCardNavigator } from '../../global/interfaces';

interface Props {
  pageName: string;
  data: IFCardNavigator;
}

class CardNavigator extends React.Component<Props> {
  render() {
    const { pageName } = this.props;
    const {
      image,
      educationTypeList,
      name,
      id,
      contact_address,
      course_cnt,
      project_cnt,
      event_cnt,
      challenge_cnt,
    } = this.props.data;

    const link = `/${pageName}/${id}`;
    const cardImage = image.thumb
      ? { backgroundImage: `url(${baseURL}/storage/${image.thumb})` }
      : { backgroundImage: `url(${baseURL}/storage/${image.original})` };

    return (
      <div className="navigator-card">
        <div className="navigator-card__preload">
          <div className="navigator-card__img-block navigator-card__img-block_preload">
            <span></span>
          </div>
          <div className="navigator-card__content navigator-card__content_preload">
            <div className="navigator-card__tag-line navigator-card__tag-line_preload">
              <span></span>
            </div>

            <h4 className="navigator-card__caption navigator-card__caption_preload"></h4>

            <div className="navigator-card__geo navigator-card__geo_preload">
              <span></span>
            </div>

            <div className="navigator-card__text navigator-card__text_preload">
              <span></span>
              <span></span>
              <span></span>
            </div>

            <div className="navigator-card__sign-boards-line navigator-card__sign-boards-line_preload">
              <div
                className="navigator-card__sign-board navigator-card__sign-board_preload"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Курсы"
              >
                <span className="navigator-card__sign-board-count"></span>
              </div>
              <div
                className="navigator-card__sign-board navigator-card__sign-board_preload"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Проекты"
              >
                <span className="navigator-card__sign-board-count"></span>
              </div>
              <div
                className="navigator-card__sign-board navigator-card__sign-board_preload"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Мероприятия"
              >
                <span className="navigator-card__sign-board-count"></span>
              </div>
              <div
                className="navigator-card__sign-board navigator-card__sign-board_preload"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Челленджи"
              >
                <span className="navigator-card__sign-board-count"></span>
              </div>
            </div>
          </div>
        </div>

        <a href={link}>
          <div className="navigator-card__img-block">
            <div className="navigator-card__img" style={cardImage}></div>
          </div>
          <div className="navigator-card__content">
            {educationTypeList ? (
              <div className="navigator-card__tag-line">
                <span>{educationTypeList}</span>
              </div>
            ) : null}

            <h4 className="navigator-card__caption">{name}</h4>

            {contact_address ? (
              <div className="navigator-card__geo">
                <span>{contact_address}</span>
              </div>
            ) : null}

            <div className="navigator-card__sign-boards-line">
              <div
                className="navigator-card__sign-board navigator-card__a-card-education"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Курсы"
              >
                <span className="navigator-card__sign-board-count">{course_cnt}</span>
              </div>
              <div
                className="navigator-card__sign-board navigator-card__a-card-project"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Проекты"
              >
                <span className="navigator-card__sign-board-count">{project_cnt}</span>
              </div>
              <div
                className="navigator-card__sign-board navigator-card__a-card-events"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Мероприятия"
              >
                <span className="navigator-card__sign-board-count">{event_cnt}</span>
              </div>
              <div
                className="navigator-card__sign-board navigator-card__a-card-challenge"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Челленджи"
              >
                <span className="navigator-card__sign-board-count">{challenge_cnt}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default CardNavigator;
