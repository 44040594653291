import * as React from 'react';

import './Notifications.scss';

import Tabs from '../../components/Tabs/Tabs';
import TabsContentNotify from '../../components/TabsContentNotify/TabsContentNotify';
import { IFNotify } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';
import Pagination from 'react-js-pagination';

interface Props {}

interface State {
  active: number;
  all: { notify: IFNotify[]; total: number };
  important: { notify: IFNotify[]; total: number };
  secondary: { notify: IFNotify[]; total: number };
  activePage: number;
}

class Notifications extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
      all: {
        notify: [],
        total: 1,
      },
      important: {
        notify: [],
        total: 1,
      },
      secondary: {
        notify: [],
        total: 1,
      },

      activePage: 1,
    };
  }

  render() {
    const { all, important, secondary } = this.state;

    return (
      <div className="page page_notifications">
        <div className="page__container">
          <div className="page__content notifications_page">
            <div className="page_title">
              <h3>Уведомления</h3>
            </div>

            <Tabs
              tabs={['Все', 'Важные', 'Второстепенные']}
              tabsContent={[
                <TabsContentNotify
                  notify={all.notify}
                  totalPage={all.total}
                  getNotify={this.getAll.bind(this)}
                  key={`notify1`}
                />,
                <TabsContentNotify
                  notify={important.notify}
                  totalPage={important.total}
                  getNotify={this.getImportant.bind(this)}
                  key={`notify2`}
                />,
                <TabsContentNotify
                  notify={secondary.notify}
                  totalPage={secondary.total}
                  getNotify={this.getSecondary.bind(this)}
                  key={`notify3`}
                />,
              ]}
              setActive={this.setActive}
              active={this.state.active}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getAll(1);
    this.getImportant(1);
    this.getSecondary(1);
  }

  setActive = (active: number) => {
    this.setState(Object.assign({}, this.state, { active: active }));
  };

  getAll(page: number) {
    this.FetchAPI.notifyAll(page, 'get').then((data) => {
      this.setState(
        Object.assign({}, this.state, { all: { notify: data.notifications, total: data.total_pages } })
      );
    });
  }

  getImportant(page: number) {
    this.FetchAPI.notifyAll(page, 'important').then((data) => {
      this.setState(
        Object.assign({}, this.state, { important: { notify: data.notifications, total: data.total_pages } })
      );
    });
  }

  getSecondary(page: number) {
    this.FetchAPI.notifyAll(page, 'secondary').then((data) => {
      this.setState(
        Object.assign({}, this.state, { secondary: { notify: data.notifications, total: data.total_pages } })
      );
    });
  }
}

export default Notifications;
