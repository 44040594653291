import React from 'react';
import FetchMyTrack from '../../../services/FetchMyTrack';
import { ClosedModal } from '../../../services/Modal';

import './BlockedUser.scss';

interface Props {
  component: React.Component;
  idChat: number;
  idUser: number;
  text: string;
  banned: number;
}

interface State {
  error: string;
}

class BlockedUser extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  render() {
    const { component, text, banned } = this.props;
    const { error } = this.state;

    return (
      <div className="content-modal blocked-user">
        <div className="text">
          <span dangerouslySetInnerHTML={{ __html: text }}></span>
        </div>

        <div className="blocked-user__buttons">
          <button
            className="blocked-user__button border_btn big w100 button_close"
            onClick={() => {
              ClosedModal(component);
            }}
          >
            Нет
          </button>
          <button
            className="blocked-user__button red_btn w100 button_close"
            onClick={() => {
              banned ? this.unBlocked() : this.blocked();
            }}
          >
            Да
          </button>
        </div>
        <span className="notifications">{error}</span>
      </div>
    );
  }

  blocked() {
    const { idChat, idUser } = this.props;

    this.FetchAPI.blockUser(idChat, idUser).then((data) => {
      this.actions(data);
    });
  }

  unBlocked() {
    const { idChat, idUser } = this.props;

    this.FetchAPI.unblockUser(idChat, idUser).then((data) => {
      this.actions(data);
    });
  }

  actions(data: any) {
    const { idChat, component } = this.props;
    if (data.status == 'success') {
      ClosedModal(component);
      window.location.href = `/message/${idChat}`;
    } else {
      this.setState({ error: `${data.status}: Ошибка отправки запроса` });
    }
  }
}

export default BlockedUser;
