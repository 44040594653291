import * as React from 'react';

import './Login.scss';
const tintelLogo = require('./tintel-logo-login.svg').default;

import Tabs from '../../components/Tabs/Tabs';
import { main } from '../../global/vars';
import FormSignIn from '../../components/FormSignIn/FormSignIn';
import FormRegister from '../../components/FormRegister/FormRegister';
import ModalActivity from '../../components/activity/ModalActivity/ModalActivity';
import { IFAlert, IFModal } from '../../global/interfaces';
import { ContextModal } from '../../services/Contexts';
import { ClosedModal } from '../../services/Modal';
import PassRecovery from '../../components/login/PassRecovery/PassRecovery';
import Alert from '../../components/Alert/Alert';

interface Props {}

interface State {
  active: number;
  modalActive: boolean;
  modal: IFModal;
  alert: IFAlert | null;
}

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
      modalActive: false,
      modal: {
        title: 'Заголовок',
        content: null,
        width: '',
      },
      alert: null,
    };
  }

  render() {
    const { modalActive, modal, alert } = this.state;

    return (
      <React.Fragment>
        <div className="login_page">
          <div className="header">
            <a href="/">
              <div className="site_name">
                <div className="logo"></div>
              </div>
            </a>
          </div>

          <div className="login_tab">
            <Tabs
              tabs={['Вход', 'Регистрация']}
              tabsContent={[
                <FormSignIn passwordRecovery={this.passwordRecovery} key="formSignIn" />,
                <FormRegister key="formRegister" />,
              ]}
              setActive={this.setActive}
              active={this.state.active}
            />
          </div>

          <div className="service_info">
            <div className="logo">
              <img src={tintelLogo} />
            </div>
            <span>
              На цифровой платформе MyTrack реализуется региональный образовательный проект Томской области
              «Территория интеллекта»
            </span>
          </div>
        </div>

        {alert ? <Alert title={alert.title} text={alert.text} component={this} error={alert.error} /> : null}

        {modalActive ? (
          <ContextModal.Provider
            value={{
              component: this,
              closedModal: ClosedModal,
            }}
          >
            <ModalActivity title={modal.title} content={modal.content} width={modal.width} />
          </ContextModal.Provider>
        ) : null}
      </React.Fragment>
    );
  }

  setActive = (active: number) => {
    this.setState(Object.assign({}, this.state, { active: active }));
  };

  async componentDidMount() {
    if (window.history.state.usr != undefined) {
      const newActive = window.history.state.usr.active == main.register ? 1 : 0;
      await this.setState(Object.assign({}, this.state, { active: newActive }));
    }
  }

  passwordRecovery = () => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Восстановление пароля',
          content: <PassRecovery showAlert={this.showAlert} />,
          width: 'very-min',
        },
      })
    );
  };

  showAlert = (title: string, text: string, error: boolean) => {
    this.setState(Object.assign({}, this.state, { alert: { title, text, error } }));
  };
}

export default Login;
