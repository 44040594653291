import React from 'react';

import './ContentModalListAct.scss';

import { IFCardActivity } from '../../../global/interfaces';
import CardActivity from '../../CardActivity/CardActivity';
import { checkTagLine } from '../../../global/functions';

interface Props {
  cards: IFCardActivity[];
  type: string;
}

class ContentModalListAct extends React.Component<Props> {
  render() {
    const { cards, type } = this.props;

    return (
      <div className="content-modal content-modal_space-between">
        {cards.map((item, i) => (
          <div className="content-modal__card" key={`card${i}`}>
            <CardActivity pageName={type} data={item} tagLine={checkTagLine(type, item)} />
          </div>
        ))}
      </div>
    );
  }
}

export default ContentModalListAct;
