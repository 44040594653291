import React from 'react';
import { IFPeople } from '../../../global/interfaces';
import FetchMyTrack from '../../../services/FetchMyTrack';
import Search from '../../Search/Search';
import UserIco from '../../UserIco/UserIco';

interface Props {
  updateUsersSearch: any;
  toggleList: any;
  members: IFPeople[];
}

interface State {
  users: IFPeople[];
}

class SearchUsersForChat extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  render() {
    const { users } = this.state;
    const { toggleList } = this.props;

    return (
      <div className="search_users">
        <Search search={this.search} />

        {users.length ? (
          <div className="search_users_result">
            <div className="line">
              {users.map((item, i) => (
                <UserIco
                  typeLink={'unlink'}
                  checking={true}
                  checkedUser={this.isChecked(item.id)}
                  toggleList={toggleList}
                  data={item}
                  key={`userIco${i}`}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="prev">
            <div className="text">
              <img src="img/icons/a-search-80-px.svg" alt=""></img>
              <span>Чтобы добавить нового учатника в чат, напишите его имя в поле поиска выше</span>
            </div>
          </div>
        )}
      </div>
    );
  }

  search = (value: string) => {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.findUser(value).then((data) => {
      this.props.updateUsersSearch(data.users);
      this.setState({
        users: data.users,
      });
    });
  };

  isChecked(id: number) {
    const userMember = this.props.members.find((field) => field.id == id);
    if (userMember) {
      return true;
    }
    return false;
  }
}

export default SearchUsersForChat;
