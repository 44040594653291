import React from 'react';

import './Legal.scss';

class Legal extends React.Component {
  render() {
    return (
      <div className="page page_terms-use">
        <div className="page__container">
          <div className="page__content_798">
            <div className="page_title">
              <h2>Условия использования</h2>
            </div>

            <div className="page__content">
              <div className="terms-use">
                <a href="/storage/policy.pdf" target="_blank" className="terms-use__item">
                  Политика конфиденциальности
                </a>
                <a href="#" target="_blank" className="terms-use__item">
                  Cогласие на обработку персональных данных
                </a>
                {/* <a href="#" target="_blank" className="terms-use__item">
                  Лицензионное соглашение
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Legal;
