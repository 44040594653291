import * as React from 'react';

import './DetailPageActivity.scss';

import FetchMyTrack from '../../services/FetchMyTrack';
import CompetenceDevelopment from '../../components/detailPageActivity/CompetenceDevelopment/CompetenceDevelopment';
import BannerActivity from '../../components/BannerActivity/BannerActivity';

import { IFCompetencePoints, IFCourseProgramm, IFMentor, IFSeeMoreEvents } from '../../global/interfaces';
import Mentors from '../../components/detailPageActivity/Mentors/Mentors';
import CourseProgramm from '../../components/detailPageActivity/CourseProgramm/CourseProgramm';
import Gallery from '../../components/detailPageActivity/Gallery/Gallery';
import CardInfo from '../../components/detailPageActivity/CardInfo/CardInfo';
import Attachment from '../../components/detailPageActivity/Attachment/Attachment';
import MoreActivityInDetail from '../../components/MoreActivityInDetail/MoreActivityInDetail';
import { ArrDeclension, ArrDeclensionCases, Declension } from '../../services/Declension';
import { getTypeAndIDPage } from '../../global/functions';

interface Props {
  name?: string;
  text?: string;
}

interface State {
  settingsBtn: { [key: string]: boolean };
  type: string;
  banner: {
    thumb?: string;
    crop?: string;
    original: string;
  };
  name: string;
  countStudents: number;
  description: any;
  content: any;
  map: {
    type: string;
    title: string;
    track: string;
    competencies: {
      name: string;
      points: IFCompetencePoints[];
    }[];
  }[];
  startDate: string;
  endDate: string;
  organization: string;
  contactGeoCode: string | null;
  contactAddress: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  attachment: string[];
  gallery: string[];
  courseProgramm: IFCourseProgramm[];
  mentors: IFMentor[];
  seeMore: IFSeeMoreEvents[];
}

class DetailPageActivity extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      settingsBtn: {},
      type: '',
      banner: {
        thumb: '',
        crop: '',
        original: '',
      },
      name: '',
      countStudents: 0,
      description: null,
      content: null,
      map: [
        // {
        //   type: 'course',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 145,
        //           text: 'Способен определять основные сельскохозяйственные культуры по морфологическим признакам',
        //           score: 1,
        //           completed: true,
        //         },
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: 'project',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 145,
        //           text: 'Способен определять основные сельскохозяйственные культуры по морфологическим признакам',
        //           score: 1,
        //           completed: true,
        //         },
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //         {
        //           id: 145,
        //           text: 'Способен определять основные сельскохозяйственные культуры по морфологическим признакам',
        //           score: 1,
        //           completed: true,
        //         },
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: 'price',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //     {
        //       name: 'Культивирование агрокультур и животноводство 2',
        //       points: [
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: 'course',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      startDate: '',
      endDate: '',
      organization: '',
      contactGeoCode: null,
      contactAddress: null,
      contactName: null,
      contactEmail: null,
      contactPhone: null,
      attachment: [
        // 'eventActivityFiles/1401/гора.png',
        // 'eventActivityFiles/1401/мономиф.png',
        // 'eventActivityFiles/1401/реверсия.png',
        // 'eventActivityFiles/1401/фундамент истории.png',
      ],
      gallery: [
        // 'courseImage1376_gallery_12_1644921832.jpeg',
        // 'courseImage1376_gallery_7_1644921832.jpeg',
        // 'courseImage1376_gallery_1_1644921832.jpeg',
        // 'courseImage1376_gallery_1_1644921832.jpeg',
        // 'courseImage1376_gallery_1_1644921832.jpeg',
        // 'courseImage1376_gallery_1_1644921832.jpeg',
      ],
      courseProgramm: [
        // {
        //   id: 4585,
        //   name: 'Защита растений от болезней (фитопатология)',
        //   taskCount: 4585,
        // },
        // {
        //   id: 4500,
        //   name: 'Питание растений (агрохимия)',
        //   taskCount: 45,
        // },
      ],
      mentors: [],
      seeMore: [
        {
          date: '',
          id: 0,
          image: { original: '' },
          is_permanent: 0,
          name: '',
          organization: '',
          place: null,
          price: 0,
          status: 0,
        },
      ],
    };
  }

  render() {
    const {
      settingsBtn,
      type,
      banner,
      name,
      countStudents,
      description,
      content,
      map,
      startDate,
      endDate,
      organization,
      contactGeoCode,
      contactAddress,
      contactName,
      contactEmail,
      contactPhone,
      attachment,
      gallery,
      courseProgramm,
      mentors,
      seeMore,
    } = this.state;

    const cardInfo = (
      <CardInfo
        startDate={startDate}
        endDate={endDate}
        contactGeoCode={contactGeoCode}
        contactAddress={contactAddress}
        contactName={contactName}
        contactEmail={contactEmail}
        contactPhone={contactPhone}
        type={type}
        organization={organization}
      />
    );

    return (
      <div className="page">
        <div className="page__container">
          <div className="page__content-full detail-activity">
            <BannerActivity
              {...this.props}
              typeActivity={type}
              name={name}
              countStudents={countStudents}
              banner={banner}
              {...settingsBtn}
            />

            <div className="detail-activity__content mt-24">
              <div>
                <div className="text mt-24" dangerouslySetInnerHTML={{ __html: description }}></div>

                {map.length != 0 ? (
                  <div className="detail-activity__cards-about mt-40">
                    {map.map(({ type, title, track }, i) => (
                      <div className="detail-activity__card mb-24" key={`track${i}`}>
                        <div className="icon">
                          <img src={this.checkIcon(type)} alt="" />
                        </div>
                        <div className="text">
                          <span>{title}</span>
                          <span>{track}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                {content ? (
                  <div
                    className="detail-activity__text-goal mt-16"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                ) : null}

                {attachment.length != 0 ? (
                  <Attachment attachment={attachment} title={`Материалы к ${ArrDeclensionCases(type)[2]}`} />
                ) : null}
              </div>

              {cardInfo}
            </div>

            {gallery.length != 0 ? <Gallery type={type} gallery={gallery} /> : null}

            {courseProgramm.length != 0 ? <CourseProgramm courseProgramm={courseProgramm} /> : null}

            {map.length != 0 ? <CompetenceDevelopment list={map} type={type} /> : null}

            {mentors.length != 0 ? <Mentors title={`Наставники`} mentors={mentors} /> : null}

            {seeMore.length != 0 ? (
              <MoreActivityInDetail
                seeMore={seeMore}
                title={`Другие ${Declension(2, ArrDeclension(type))}`}
                type="course"
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();

    const { idPage, typePage } = getTypeAndIDPage();

    let urlRequest;
    switch (typePage) {
      case 'course':
        urlRequest = '/courses/getCourse';
        break;
      case 'project':
        urlRequest = '/project/getProject';
        break;
      case 'event':
        urlRequest = '/event/getEvent';
        break;
      case 'challenge':
        urlRequest = '/challenge/getChallenge';
        break;
      default:
        urlRequest = '/courses/getCourse';
    }

    FetchAPI.getCourse(idPage, urlRequest).then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          settingsBtn: Object.assign(
            {},
            this.checkButtonStatus(data.status_id),
            this.checkStatus(data.status_id)
          ), // заменить после исправления api
          type: typePage,
          banner: data.photo_links,
          name: data.name,
          countStudents: data.count_stud,
          description: data.description,
          attachment: data.attachment ? Object.values(data.attachment) : [], // заменить после исправления api
          content: data.content,
          startDate: data.start_date ? data.start_date : null,
          endDate: data.end_date ? data.end_date : null,
          organization: data.organization,
          contactGeoCode: data.contact_geocode,
          contactAddress: data.contact_address,
          contactName: data.contact_person,
          contactEmail: data.contact_person_email,
          contactPhone: data.contact_person_phone,
          // переделать api
          // gallery:data.gallery ? data.gallery : [],
          // courseProgramm:data.course_programm ? data.course_programm : [],
          mentors: data.mentors ? data.mentors : [],
          seeMore: data.seeMore ? data.seeMore : [],
        })
      );
    });
  }

  checkIcon(type: string) {
    let icon = '';
    switch (type) {
      case 'napr':
        icon = 'napr';
        break;
      case 'course':
      case 'project':
        icon = 'trec';
        break;
      case 'vozrast':
        icon = 'vozrast';
        break;
      case 'price':
        icon = 'price';
        break;
      case 'ovz':
        icon = 'ovz';
        break;
      default:
        icon = '';
    }

    let src = '';
    if (icon != '') {
      src = require(`../../old/img/icons/${icon}.svg`);
    }
    return src;
  }

  checkButtonStatus(status: number) {
    const button = [
      { btnBlocked: true },
      { btnEnroll: true },
      { btnApply: true },
      { btnCancel: true },
      { btnBecomeMember: true },
      { btnEdit: true },
      { btnControl: true },
      { btnCompleted: true },
      { btnWinner: true },
    ];

    return button[status];
  }

  checkStatus(status: number) {
    const label = [
      { statusOpen: true },
      { statusUnderConsideration: true },
      { statusCompleted: true },
      { statusClosed: true },
      { statusParticipant: true },
      { statusDone: true },
    ];

    return label[status];
  }

  checkNameFile(item: string) {
    const arr = /.*\/(.*)/.exec(item);
    return arr ? arr[1] : '';
  }
}

export default DetailPageActivity;
