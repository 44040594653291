import React from 'react';

import FetchMyTrack from '../../../services/FetchMyTrack';
import { ItEmail, StatusProcessingAPI } from '../../../services/Serv.Login';
import Input from '../../Input/Input';

import './PassRecovery.scss';

interface Props {
  showAlert: any;
}

interface State {
  email: string;
  errorEmail: string;
  success: string;
}

class PassRecovery extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      errorEmail: '',
      success: '',
    };
  }

  render() {
    const { email, errorEmail, success } = this.state;
    return (
      <div className="content-modal pass-recovery">
        <div className="text">
          <span>Введите почту на которую зарегистрирован ваш аккаунт.</span>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.recovery();
          }}
        >
          <div className="mt-24 mb-24">
            <div className="input">
              <Input
                type="email"
                name="recover_mail"
                placeholder="E-mail"
                value={email}
                error={errorEmail}
                saveValue={this.saveEmail}
              />
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="blue_btn button_close">
              Восстановить доступ
            </button>
          </div>
        </form>
      </div>
    );
  }

  saveEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { email: e.target.value }));
  };

  recovery() {
    const { email } = this.state;
    let emailNotify = '',
      hasErrors = 0;

    if (!ItEmail(email)) {
      emailNotify = 'E-mail введен некорректно';
      hasErrors = 1;
    }

    if (email == '') {
      emailNotify = 'Поле пустое';
      hasErrors = 1;
    }

    if (hasErrors) {
      this.setState(
        Object.assign({}, this.state, {
          errorEmail: emailNotify,
        })
      );
    } else {
      const FetchAPI = new FetchMyTrack();
      FetchAPI.resetpassword(email).then((data) => {
        const { errorText, successText } = StatusProcessingAPI(data);
        this.setState(Object.assign({}, this.state, { errorEmail: errorText, success: successText }));

        if (successText.length) {
          this.props.showAlert('Всё хорошо!', successText, false);
        } else {
          //  Вывод Alert, если потребуется (дублирует ошибку под полем)
          this.props.showAlert('Ошибка', errorText, true);
        }
      });
    }
  }
}

export default PassRecovery;
