import * as React from 'react';

import './Rating.scss';

import { IFRating } from '../../../global/interfaces';
import { clickTabs } from '../../../global/functions';
import { Tooltip } from 'react-tippy';
import { activityNames } from '../../../global/vars';

interface Props {
  rating: IFRating;
}

interface State {
  active: number;
}

class Rating extends React.Component<Props, State> {
  tabs: { [key: string]: string }[];

  constructor(props: Props) {
    super(props);
    this.tabs = activityNames;
    this.state = {
      active: 0,
    };
  }

  render() {
    const { rating } = this.props;
    const { active } = this.state;

    return (
      <div className="card rating">
        <div className="page_title">
          <h4>Рейтинг контента</h4>
        </div>
        <div className="content-card">
          <ul className="nav nav-tabs">
            {this.tabs.map((tab, i) => (
              <li className="nav-item" key={`tab${i}`} onClick={() => clickTabs(this, i)}>
                <a
                  className={`nav-link  ${active == i ? 'active' : ''}`}
                  data-toggle="tab"
                  href={`#tab-${i}`}
                >
                  {tab.text}
                </a>
              </li>
            ))}
          </ul>
          <div className="tab-content">
            {this.tabs.map((tab, iTab) => (
              <div
                className={`tab-pane fade ${active == iTab ? 'show active' : ''}`}
                id={`tab-${iTab}`}
                key={`tab${iTab}`}
              >
                {rating[tab.name].map(({ name, now, before, type, id }, i) => (
                  <div className="rating-content" key={`rating${i}`}>
                    <div>
                      <b>{this.checkNameTab(type)}</b>
                      <span>
                        <a className="rating-content__link" href={`/${type}/${id}`}>
                          {name}
                        </a>
                      </span>
                    </div>
                    <div>
                      <div className="progress">
                        <span>{now}</span>
                        <Tooltip
                          title={`Текущий месяц`}
                          position="top"
                          trigger="mouseenter"
                          theme="light"
                          arrow={true}
                          className="progress__tooltip"
                        >
                          <div
                            className="progress__indicator"
                            style={{ width: `${this.progressNow(now, before)}%` }}
                          ></div>
                        </Tooltip>
                      </div>

                      <div className="progress">
                        <span className={`${before == 0 ? 'progress__num-grey' : ''}`}>{before}</span>
                        <Tooltip
                          title={`Прошедший месяц`}
                          position="top"
                          trigger="mouseenter"
                          theme="light"
                          arrow={true}
                          className="progress__tooltip"
                        >
                          <div
                            className="progress__indicator"
                            style={{ width: `${this.progressBefore(now, before)}%` }}
                          ></div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  progressNow(now: number, before: number) {
    let percent;
    if (before < now) {
      percent = 100;
    } else {
      if (now == 0) {
        percent = 1;
      } else {
        percent = (now / before) * 100;
      }
    }
    return percent;
  }

  progressBefore(now: number, before: number) {
    let percent;
    if (before > now) {
      percent = 100;
    } else {
      if (before == 0) {
        percent = 1;
      } else {
        percent = (before / now) * 100;
      }
    }
    return percent;
  }

  checkNameTab(name: string) {
    return this.tabs.find((field) => field.name == name)?.text;
  }
}

export default Rating;
