import * as React from 'react';
import Moment from 'react-moment';

import './TableOrder.scss';

import { IFDataOrders, IFOrdersActivities } from '../../global/interfaces';
import { ArrDeclensionCases } from '../../services/Declension';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {
  order: IFDataOrders;
  typeContent: string;
  updateList: any;
}

interface State {
  showActivities: number;
  showBtnLoadMore: boolean;
}

class TableOrder extends React.Component<Props, State> {
  statuses: { [key: string]: string[] };
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.statuses = {
      all: ['Доступ открыт', 'Пройдено'],
      qualification: ['', 'Доступ открыт', 'Доступ открыт', 'Доступ открыт', 'Пройдено'],
      courses: ['Доступ открыт', 'Пройдено'],
      projects: ['Доступ открыт', 'Пройдено'],
      challenges: ['Доступ открыт', 'Пройдено'],
      event: ['Доступ открыт', 'Пройдено'],
    };
    this.state = {
      showActivities: 3,
      showBtnLoadMore: true,
    };
  }

  render() {
    const { order } = this.props;
    const { showActivities, showBtnLoadMore } = this.state;

    return (
      <div className="tabs-with-content__content">
        <div className="table-grid">
          <div className="table-grid__row table-grid__row_head">
            <div className="table-grid__cell">Название</div>
            <div className="table-grid__cell">Статус</div>
            <div className="table-grid__cell">Стоимость</div>
            <div className="table-grid__cell">Активация</div>
            <div className="table-grid__cell">Окончание</div>
          </div>

          {order.data.map(({ activated, activities, id, name, price, series, status, type }, i) => (
            <div className="table-grid__row" key={`table-row${i}`}>
              <div className="table-grid__cell table-grid__cell_name table-grid__cell_group-activities">
                <p className="table-grid__name-qualification">
                  <a href="#" className="table-grid__link-activities">
                    {ArrDeclensionCases(type)[0]} «{name}»
                  </a>
                </p>
                {type == 'qualification' ? (
                  <p className="table-grid__series">
                    Серия #<span>{series}</span>
                  </p>
                ) : null}
                {activities?.map(({ id, name, type }, idx) =>
                  idx < showActivities ? (
                    <p className="table-grid__name-item-activities" key={`activity${idx}`}>
                      <a href={`/${type}/${id}`} className="table-grid__link-activities">
                        {ArrDeclensionCases(type)[0]} «{name}»
                      </a>
                    </p>
                  ) : null
                )}

                {activities?.length > 3 ? (
                  <span className="table-grid__show-in-full" onClick={() => this.showAll(activities)}>
                    {showActivities <= 3 ? 'Показать полностью' : 'Свернуть'}
                  </span>
                ) : null}
              </div>
              <div className="table-grid__cell">
                <span className="status-mark status-mark_green">
                  {this.statuses[type][status] ? this.statuses[type][status] : this.statuses.all[status]}
                </span>
              </div>
              <div className="table-grid__cell">{new Intl.NumberFormat('ru-RU').format(price)} ₽</div>
              <div className="table-grid__cell">
                <Moment format="DD.MM.YYYY">{activated}</Moment>
              </div>
              <div className="table-grid__cell">
                <Moment format="DD.MM.YYYY" add={{ years: 1 }}>
                  {activated}
                </Moment>
              </div>
            </div>
          ))}
        </div>

        {showBtnLoadMore ? (
          <div className="order__button-yet">
            <span className="button button_border button_big" onClick={() => this.loadMore()}>
              Показать еще
            </span>
          </div>
        ) : null}
      </div>
    );
  }

  componentDidMount() {
    this.setState(
      Object.assign({}, this.state, {
        showBtnLoadMore: this.checkBtnLoadMore(),
      })
    );
  }

  componentDidUpdate(prevP: Props) {
    if (prevP != this.props) {
      this.setState(
        Object.assign({}, this.state, {
          showBtnLoadMore: this.checkBtnLoadMore(),
        })
      );
    }
  }

  showAll(activities: IFOrdersActivities[]) {
    this.setState({
      showActivities: this.state.showActivities > 3 ? 3 : activities.length,
    });
  }

  loadMore() {
    const { order, typeContent, updateList } = this.props;
    const nextPage = order.pageNumber + 1;

    this.FetchAPI.ordersListMore(typeContent, nextPage)
      .then((data) => {
        updateList({
          [typeContent]: {
            data: order.data.concat(data.data),
            pageNumber: data.pageNumber,
            totalCount: data.totalCount,
            totalPages: data.totalPages,
          },
        });
      })
      .then(() => {
        this.setState(
          Object.assign({}, this.state, {
            showBtnLoadMore: this.checkBtnLoadMore(),
          })
        );
      });
  }

  checkBtnLoadMore() {
    const { order } = this.props;
    return order.pageNumber < order.totalPages;
  }
}

export default TableOrder;
