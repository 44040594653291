import * as React from 'react';
import { IFPeople } from '../../../global/interfaces';
import { baseURL } from '../../../global/vars';
import UserIco from '../../UserIco/UserIco';

interface Props {
  users: IFPeople[];
  ratingPlace: {
    max_place: number;
    place: number;
    days: number;
  };
  countPublish: number;
  showUser: any;
  profile: {
    name: string;
    surname: string;
    avatar: string;
  };
  title?: string;
  type: string;
}

class UserTop extends React.Component<Props> {
  render() {
    const { users, ratingPlace, countPublish, showUser, profile, title = '', type } = this.props;

    return (
      <div className="col-6">
        <div className="page_title">
          <h4>Топ {title}</h4>
        </div>

        <div className="top_list">
          <div className="line">
            {users.map((item, i) => (
              <UserIco typeLink={type} data={item} showUser={showUser} key={`userIco${i}`} />
            ))}
          </div>

          <div className="you">
            <div className="user user_top select">
              <div className="ava" style={{ backgroundImage: `url(${baseURL}/storage/${profile.avatar})` }}>
                <div className="points buble">{countPublish}</div>
              </div>
              <div className="text">
                <div className="name">
                  <span>
                    {profile.name} {profile.surname}
                  </span>
                </div>
                <div className="info">
                  <span>Место в рейтиге: </span>
                  <span>
                    {ratingPlace.place} из {ratingPlace.max_place}
                  </span>
                </div>
                <div className="info">
                  <span>На платформе: </span>
                  <span>{ratingPlace.days} дня</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserTop;
