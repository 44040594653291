import * as React from 'react';

import { getTypeAndIDPage } from '../../global/functions';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {}

interface State {
  name: string;
  description: string;
  nextModule: number | null;
  numberTheme: number;
}

class ModuleActivity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: 'Тема',
      description: '',
      nextModule: null,
      numberTheme: 1,
    };
  }

  render() {
    const { name, description, nextModule, numberTheme } = this.state;
    const { idPage, typePage } = getTypeAndIDPage();

    return (
      <div className="page">
        <div className="page__container">
          <div className="page__content-full">
            <nav className="nav_blue">
              <div className="left">
                <a href={`/${typePage}/${idPage}`}>Описание курса</a>
              </div>
              <div className="content_8col">
                <div className="center">
                  <div className="drop_down_list">
                    <div className="selected">
                      <span>{`Тема ${numberTheme}: ${name}`}</span>
                    </div>
                  </div>
                </div>
              </div>
              {nextModule ? (
                <div className="right">
                  <a href={`/${typePage}/${idPage}/module/${nextModule}`}>Следующая тема</a>
                </div>
              ) : null}
            </nav>

            <div id="module_article" className="content_8col">
              {/* <div >
            <a className="border_btn" href="https://mytrack.ru/course/695/module/696/edit">
              Редактировать
            </a>
          </div>
          <div >
            <a href="#edit_task" className="border_btn open_modal">
              Управление заданиями
            </a>
          </div> */}

              <div className="content">
                <div className="news_page">
                  <div className="text" dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-40">
              <div id="user_results" className="page_title mt-40">
                <h3>Результаты к заданиям темы (0)</h3>
              </div>
              <p>Пока нет результатов.</p>
              <div className="list">
                <div className="news pt-0" id="user_results_list">
                  <div className="news_list col4">
                    <a href="#add_result_modal" className="add_result open_modal">
                      <div className="icon"></div>
                      <span>Добавить результат</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    const { idPage, idModule } = getTypeAndIDPage();

    FetchAPI.getCourseModule(idPage, idModule).then((data) => {
      this.setState({
        name: data.name,
        description: data.description,
        nextModule: data.next_module,
      });
    });
  }
}

export default ModuleActivity;
