import React from 'react';

import './ContentModalListTechSkills.scss';

import { IFFiltersItem } from '../../../global/interfaces';
import { bodyAuto } from '../../../global/functions';
import FetchMyTrack from '../../../services/FetchMyTrack';
import { ToogleOptionsProfile } from '../../../services/Serv.Profile';

interface Props {
  skills: IFFiltersItem[];
  allListSkills?: IFFiltersItem[];
  updateStateProfile?: any;
}

interface State {
  arrIdSkills: number[];
  newSkills: IFFiltersItem[];
}
class ContentModalListTechSkills extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      arrIdSkills: [],
      newSkills: this.props.skills,
    };
  }

  render() {
    const { skills, allListSkills } = this.props;
    const { arrIdSkills } = this.state;

    return (
      <div className="content-modal">
        {allListSkills ? (
          <React.Fragment>
            <div className="content-modal__skills">
              {allListSkills.map((item, i) =>
                arrIdSkills.includes(item.id) ? (
                  <span
                    className="content-modal__skill content-modal__skill_edit active"
                    key={`skill${item.id}`}
                    onClick={(e) => {
                      this.toogleSkills(e);
                    }}
                    id={`${item.id}`}
                  >
                    {item.name}
                  </span>
                ) : null
              )}
              <div className="content-modal__skills-all">
                {allListSkills.map((item) => (
                  <span
                    className={`content-modal__skill ${arrIdSkills.includes(item.id) ? 'active' : ''}`}
                    key={`skill${item.id}`}
                    onClick={(e) => {
                      this.toogleSkills(e);
                    }}
                    id={`${item.id}`}
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
            <button
              className="blue_btn content-modal__button"
              onClick={() => {
                this.updateHobbies();
                bodyAuto();
              }}
            >
              Сохранить
            </button>
          </React.Fragment>
        ) : (
          skills.map((item) => (
            <span className="content-modal__skill active" key={`skill${item.id}`}>
              {item.name}
            </span>
          ))
        )}
      </div>
    );
  }
  componentDidMount() {
    const newArr: number[] = [];
    this.props.skills.forEach((item) => {
      newArr.push(item.id);
    });

    this.setState(
      Object.assign({}, this.state, {
        arrIdSkills: newArr,
      })
    );
  }

  toogleSkills(e: any) {
    const data = ToogleOptionsProfile({
      e,
      classItem: 'content-modal__skill',
      arrIdItems: this.state.arrIdSkills,
      itemsBefore: this.props.skills,
      allItems: this.props.allListSkills ? this.props.allListSkills : [],
    });

    this.setState(
      Object.assign({}, this.state, {
        arrIdSkills: data?.arrIdItemsAfter,
        newSkills: data?.itemsAfter,
      })
    );
  }

  updateHobbies() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.updateTechSkills(this.state.arrIdSkills);

    //обновляется глобально, чтобы при закрытии модалки данные не сбрасывались и визуально отображали корректные списки (Profile. this.state.skills)
    this.props.updateStateProfile({ skills: this.state.newSkills, modalActive: false });
  }
}

export default ContentModalListTechSkills;
