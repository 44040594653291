import * as React from 'react';

import './UserIco.scss';
import { baseURL } from '../../global/vars';

import { IFPeople } from '../../global/interfaces';
import { Link } from 'react-router-dom';

interface Props {
  data: IFPeople;
  typeLink: string;
  showUser?: any;
  toggleList?: any;
  checking?: boolean;
  checkedUser?: boolean;
  hasDel?: boolean;
}

interface State {
  checked: boolean;
}

class UserIco extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checked: this.props.checkedUser ? this.props.checkedUser : false,
    };
  }

  render() {
    const {
      data: { name, surname, avatar, score, id, type },
      typeLink,
      showUser = () => {},
      checking = false,
      hasDel,
    } = this.props;

    const { checked } = this.state;

    return (
      <Link
        to={typeLink == 'unlink' ? '' : `/profile/${typeLink}/${id}`}
        className="user"
        onClick={(e) => {
          typeLink == 'unlink' ? e.preventDefault() : null;
          checking ? this.checkUser(id) : showUser(id);
        }}
      >
        <div className="ava" style={{ backgroundImage: `url(${baseURL}/storage/${avatar})` }}>
          {type === 'mentor' ? <div className="mentor"></div> : null}
          {score ? <div className="points buble">{score}</div> : null}
          {checking ? <div className={`select ${checked ? 'select_active' : ''}`}></div> : null}
          {hasDel ? (
            <div
              className="del"
              onClick={() => {
                this.checkUser(id);
              }}
            ></div>
          ) : null}
        </div>
        <div className="name">
          <span>{name}</span>
          <span>{surname}</span>
        </div>
      </Link>
    );
  }

  componentDidUpdate(prevP: Props) {
    if (prevP != this.props) {
      this.setState(
        Object.assign({}, this.state, {
          checked: this.props.checkedUser ? this.props.checkedUser : false,
        })
      );
    }
  }

  checkUser(id: number) {
    this.setState({
      checked: !this.state.checked,
    });

    const { toggleList } = this.props;
    toggleList ? toggleList(id) : null;
  }
}

export default UserIco;
