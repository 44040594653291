import * as React from 'react';

interface Props {
  type: string;
  name: string;
  placeholder: string;
  form?: string;
  labelFor?: string;
  labelText?: string;
  value?: string;
  saveValue?: any;
  error?: string;
}

class Input extends React.Component<Props> {
  render() {
    const { type, name, placeholder, form = '', labelFor, labelText, value, saveValue, error } = this.props;

    return (
      <div className="col-12">
        <div className="input">
          {labelFor ? <label htmlFor={labelFor}>{labelText}</label> : null}
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            form={form}
            defaultValue={value}
            id={(Math.random() * 100).toFixed().toString()}
            onChange={(e) => {
              saveValue(e);
            }}
            className={error?.length ? 'input-text__input_error' : ''}
          />
          <span className="input-text__notification">{error}</span>
        </div>
      </div>
    );
  }
}

export default Input;
