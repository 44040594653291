import * as React from 'react';

import './Languages.scss';

import { IFLanguages } from '../../../global/interfaces';
import { ContextModal } from '../../../services/Contexts';

import ContentModalListLanguages from '../ContentModalListLanguages/ContentModalListLanguages';
import Edit from '../Edit/Edit';

interface Props {
  languages: IFLanguages[];
  edit: boolean;
}
class Languages extends React.Component<Props> {
  limitItem = 3;

  render() {
    const { languages, edit } = this.props;
    const { limitItem } = this;

    return (
      <div className="col-6 mt-40 languages">
        <div className="page_title">
          <h4>Языки</h4>
          {edit ? (
            <Edit
              title="Языки"
              ComponentModal={<ContentModalListLanguages languages={languages} edit={true} />}
              width="min"
            />
          ) : null}
        </div>

        {languages.map((item, i) =>
          i < limitItem ? (
            <div className="lang" key={`lang${i}`}>
              <i className={`${item.image}`}></i>
              <span>
                {item.name}
                <span>({item.level})</span>
              </span>
            </div>
          ) : null
        )}

        {languages.length == 0 ? <p className="fs14w400">Пока не добавлен ни один язык.</p> : null}

        {languages.length > limitItem ? (
          <ContextModal.Consumer>
            {({ showModal }) => {
              return (
                <a
                  href="#all"
                  className="link_border"
                  onClick={() => {
                    this.showLanguages(showModal);
                  }}
                >
                  Смотреть все
                </a>
              );
            }}
          </ContextModal.Consumer>
        ) : null}
      </div>
    );
  }

  showLanguages(showModal: any) {
    showModal({
      title: 'Языки',
      content: <ContentModalListLanguages languages={this.props.languages} />,
      width: 'min',
    });
  }
}

export default Languages;
