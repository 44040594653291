import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { IFPeople } from '../../global/interfaces';
import { apiUrl } from '../../global/vars';
import Page404 from '../../pages/404/404';
import Activity from '../../pages/Activity/Activity';
import Analytics from '../../pages/Analytics/Analytics';
import Challenges from '../../pages/Challenges/Challenges';
import Courses from '../../pages/Courses/Courses';
import DetailPageActivity from '../../pages/DetailPageActivity/DetailPageActivity';
import DetailPageOrganization from '../../pages/DetailPageOrganization/DetailPageOrganization';
import Events from '../../pages/Events/Events';
import Legal from '../../pages/Legal/Legal';
import Login from '../../pages/Login/Login';
import ModuleActivity from '../../pages/ModuleActivity/ModuleActivity';
import Navigator from '../../pages/Navigator/Navigator';
import People from '../../pages/People/People';
import Profile from '../../pages/Profile/Profile';
import Projects from '../../pages/Projects/Projects';
import ProjectTasks from '../../pages/ProjectTasks/ProjectTasks';
import Recommendations from '../../pages/Recommendations/Recommendations';
import SkillActivities from '../../pages/SkillActivities/SkillActivities';
import Tracks from '../../pages/Tracks/Tracks';
import Works from '../../pages/Works/Works';
import Layout from '../Layout/Layout';
import TaskActivity from '../../pages/TaskActivity/TaskActivity';
import ProfileMentor from '../../pages/ProfileMentor/ProfileMentor';
import Answer from '../../pages/Answer/Answer';
import Qualifications from '../../pages/Qualifications/Qualifications';
import DetailPageTrack from '../../pages/DetailPageTrack/DetailPageTrack';
import Settings from '../../pages/Settings/Settings';
import Orders from '../../pages/Orders/Orders';
import Messages from '../../pages/Messages/Messages';
import DetailChat from '../../pages/DetailChat/DetailChat';
import Notifications from '../../pages/Notifications/Notifications';
import DetailTrackNews from '../../pages/DetailTrackNews/DetailTrackNews';

interface Props {
  geo: number;
  profile: IFPeople;
}

class RoutesAPP extends React.Component<Props> {
  render() {
    const { geo, profile } = this.props;
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} />
        </Routes>
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
        <Routes>
          <Route
            path="/navigator"
            element={<Layout pageName={apiUrl.navigator} page={<Navigator geo={geo} />} />}
          />
        </Routes>
        <Routes>
          <Route path="/course" element={<Layout pageName={apiUrl.courses} page={<Courses geo={geo} />} />} />
        </Routes>
        <Routes>
          <Route
            path="/project"
            element={<Layout pageName={apiUrl.project} page={<Projects geo={geo} />} />}
          />
        </Routes>
        <Routes>
          <Route
            path="/challenge"
            element={<Layout pageName={apiUrl.challenge} page={<Challenges geo={geo} />} />}
          />
        </Routes>
        <Routes>
          <Route path="/event" element={<Layout pageName={apiUrl.event} page={<Events geo={geo} />} />} />
        </Routes>
        <Routes>
          <Route path="/people" element={<Layout page={<People />} />} />
        </Routes>

        <Routes>
          <Route path="/works" element={<Layout page={<Works />} />} />
        </Routes>
        <Routes>
          <Route path="/analytics" element={<Layout page={<Analytics />} />} />
        </Routes>
        <Routes>
          <Route path="/legal" element={<Layout page={<Legal />} />} />
        </Routes>
        <Routes>
          <Route path="/tracks" element={<Layout page={<Tracks />} />} />
        </Routes>
        <Routes>
          <Route path="/activity" element={<Layout page={<Activity profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/recommendations" element={<Layout page={<Recommendations profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/profile" element={<Layout page={<Profile profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/course/:id" element={<Layout page={<DetailPageActivity />} />} />
        </Routes>
        <Routes>
          <Route path="/project/:id" element={<Layout page={<DetailPageActivity />} />} />
        </Routes>
        <Routes>
          <Route path="/event/:id" element={<Layout page={<DetailPageActivity />} />} />
        </Routes>
        <Routes>
          <Route path="/challenge/:id" element={<Layout page={<DetailPageActivity />} />} />
        </Routes>
        <Routes>
          <Route path="/navigator/:id" element={<Layout page={<DetailPageOrganization />} />} />
        </Routes>
        <Routes>
          <Route path="/skillActivities/:id" element={<Layout page={<SkillActivities />} />} />
        </Routes>
        <Routes>
          <Route path="/profile/user/:id" element={<Layout page={<Profile profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/profile/mentor/:id" element={<Layout page={<ProfileMentor profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/course/:id/module/:id" element={<Layout page={<ModuleActivity />} />} />
        </Routes>
        <Routes>
          <Route path="/course/:id/module/:id/task/:id" element={<Layout page={<TaskActivity />} />} />
        </Routes>
        <Routes>
          <Route path="/project/:id/tasks" element={<Layout page={<ProjectTasks />} />} />
        </Routes>
        <Routes>
          <Route path="/challenge/:id/answer/:id" element={<Layout page={<Answer />} />} />
        </Routes>
        <Routes>
          <Route path="/project/:id/answer/:id" element={<Layout page={<Answer />} />} />
        </Routes>
        <Routes>
          <Route path="/course/:id/module/:id/answer/:id" element={<Layout page={<Answer />} />} />
        </Routes>
        <Routes>
          <Route path="/event/:id/answer/:id" element={<Layout page={<Answer />} />} />
        </Routes>
        <Routes>
          <Route path="/qualifications/:id" element={<Layout page={<Qualifications />} />} />
        </Routes>
        <Routes>
          <Route path="/track/:id" element={<Layout page={<DetailPageTrack profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/track/:id/news/:id" element={<Layout page={<DetailTrackNews />} />} />
        </Routes>
        <Routes>
          <Route path="/settings" element={<Layout page={<Settings />} />} />
        </Routes>
        <Routes>
          <Route path="/orders" element={<Layout page={<Orders />} />} />
        </Routes>
        <Routes>
          <Route path="/messages" element={<Layout page={<Messages profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/message/:id" element={<Layout page={<DetailChat profile={profile} />} />} />
        </Routes>
        <Routes>
          <Route path="/notifications" element={<Layout page={<Notifications />} />} />
        </Routes>

        {/* <Routes>
        <Route path="*" element={<Layout page={<Page404 />} />} />
      </Routes> */}
      </Router>
    );
  }
}

export default RoutesAPP;
