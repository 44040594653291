import * as React from 'react';
import Moment from 'react-moment';

import './CardNews.scss';

import { baseURL } from '../../global/vars';
import { IFNews } from '../../global/interfaces';

interface Props {
  card: IFNews;
}

class CardNews extends React.Component<Props> {
  render() {
    const { id, image, name, user = 'Администратор', date, slug } = this.props.card;

    const cardImage = {
      backgroundImage: `url(${baseURL}/storage/${image}`,
    };

    return (
      <div className="activity-card activity-card_new">
        <a href={`/track/${id}/news/${slug}`}>
          <div className="activity-card__img-block">
            <div className="activity-card__img" style={cardImage}></div>
          </div>
          <div className="activity-card__content">
            <div className="activity-card__content-top">
              <h4 className="activity-card__caption">{name}</h4>
            </div>

            <div className="activity-card__content-bottom">
              <div className="activity-card__sign-board work-card__sign-board_user">
                <span>{user}</span>
              </div>
              {date ? (
                <div className="activity-card__sign-board activity-card__sign-board_date">
                  <span>
                    <Moment format="D MMMM YYYY">{date}</Moment>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default CardNews;
