import * as React from 'react';

import CardActivity from '../../components/CardActivity/CardActivity';
import ActivityPage from '../../components/ActivityPage/ActivityPage';

import FetchMyTrack from '../../services/FetchMyTrack';
import { apiUrl, count, defaultConfig, typeOnline } from '../../global/vars';
import { IFFitlers, IFCardActivity, Config, IFFiltersItem } from '../../global/interfaces';
import Filter from '../../services/Filter';
import {
  filteredPage,
  filteredPageMore,
  printDataPage,
  updateAllConfig,
  updateConfig,
  updateFiltersTracks,
} from '../../services/Serv.ActivityPages';

interface Props {
  geo: number;
}

interface State {
  filters: IFFitlers;
  cards: IFCardActivity[];
  countNewCards: number;
  config: Config;
}

class Projects extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  pageName = 'project';
  fresh = 1;

  constructor(props: Props) {
    super(props);
    this.state = {
      filters: {},
      cards: [],
      countNewCards: count.cardsToPage,
      config: {
        page: 0,
        fresh: this.fresh,
        geo_id: this.props.geo,
      },
    };
  }

  render() {
    const { ...itemProps } = this.props;
    const { filters, cards } = this.state;

    const content = cards.map((item, i) => (
      <CardActivity pageName={this.pageName} data={item} tagLine={'Проект'} key={`card${i}`} />
    ));

    return (
      <ActivityPage
        title="Проекты"
        content={content}
        countCards={this.state.countNewCards}
        filters={filters}
        search={this.search}
        fetchGetFilteredPage={this.fetchGetFilteredPage}
        updateFiltersTracks={this.updateFiltersTracks}
        updateConfig={this.updateConfig}
        updateAllConfig={this.updateAllConfig}
        getFilteredPageMore={this.getFilteredPageMore}
        {...itemProps}
      />
    );
  }

  componentDidMount() {
    printDataPage(apiUrl.project, this.props.geo, this.state.config, this);
  }

  componentDidUpdate(prevP: Props) {
    if (prevP !== this.props) {
      printDataPage(apiUrl.project, this.props.geo, this.state.config, this);
    }
  }

  search = (value: string) => {
    const filter = new Filter();
    filter.search({ value, url: apiUrl.project, Component: this, geo: this.props.geo, fresh: this.fresh });
  };

  fetchGetFilteredPage = () => {
    filteredPage(this, apiUrl.project);
  };

  updateFiltersTracks = (item: IFFiltersItem[]) => {
    updateFiltersTracks(item, this);
  };

  updateAllConfig = () => {
    updateAllConfig(defaultConfig.all.fresh, this);
  };

  updateConfig = async (key: string, item: string, masAges: number[][]) => {
    await updateConfig(key, item, this, masAges);
    this.fetchGetFilteredPage();
  };

  getFilteredPageMore = () => {
    filteredPageMore(apiUrl.project, this);
  };
}

export default Projects;
