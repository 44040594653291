import * as React from 'react';

export const TrajectoryStub = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="page_title">
          <h4>Траектория развития</h4>
        </div>

        <div className="traectoria_scroll">
          <div className="traectoria_line">
            <div className="traectories">
              <div className="traectories_scroll_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
