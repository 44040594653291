import React from 'react';

import './ContentModalListHobbies.scss';

import { baseURL } from '../../../global/vars';
import { IFHobby } from '../../../global/interfaces';
import FetchMyTrack from '../../../services/FetchMyTrack';
import { bodyAuto } from '../../../global/functions';
import { ToogleOptionsProfile } from '../../../services/Serv.Profile';

interface Props {
  hobbies: IFHobby[];
  all?: IFHobby[];
  updateStateProfile?: any;
}

interface State {
  arrIdHobby: number[];
  newHobby: IFHobby[];
}

class ContentModalListHobbies extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      arrIdHobby: [],
      newHobby: this.props.hobbies,
    };
  }

  render() {
    const { hobbies, all } = this.props;
    const { arrIdHobby } = this.state;

    return (
      <div className="content-modal">
        {all ? (
          <React.Fragment>
            <div className="content-modal__hobby">
              {all.map((item, i) =>
                arrIdHobby.includes(item.id) ? (
                  <div
                    className="hobby active"
                    key={`hobby${i}`}
                    onClick={(e) => {
                      this.toogleHobby(e);
                    }}
                    id={`${item.id}`}
                  >
                    <i
                      className="icon-hobby"
                      style={{ backgroundImage: `url(${baseURL}/img/icons/competence/${item.image}.svg) ` }}
                    ></i>
                    <span>{item.name}</span>
                  </div>
                ) : null
              )}
              <div className="hobby-all">
                {all.map((item, i) => (
                  <div
                    className={`hobby ${arrIdHobby.includes(item.id) ? 'active' : ''}`}
                    key={`hobby${i}`}
                    onClick={(e) => {
                      this.toogleHobby(e);
                    }}
                    id={`${item.id}`}
                  >
                    <i
                      className="icon-hobby"
                      style={{ backgroundImage: `url(${baseURL}/img/icons/competence/${item.image}.svg) ` }}
                    ></i>
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>

            <button
              className="blue_btn content-modal__button"
              onClick={() => {
                this.updateHobbies();
                bodyAuto();
              }}
            >
              Сохранить
            </button>
          </React.Fragment>
        ) : (
          hobbies.map((item, i) => (
            <div className="hobby active" key={`hobby${i}`}>
              <i
                className="icon-hobby"
                style={{ backgroundImage: `url(${baseURL}/img/icons/competence/${item.image}.svg) ` }}
              ></i>
              <span>{item.name}</span>
            </div>
          ))
        )}
      </div>
    );
  }

  componentDidMount() {
    const newArr: number[] = [];
    this.props.hobbies.forEach((item) => {
      newArr.push(item.id);
    });

    this.setState(
      Object.assign({}, this.state, {
        arrIdHobby: newArr,
      })
    );
  }

  toogleHobby(e: any) {
    const data = ToogleOptionsProfile({
      e,
      classItem: 'hobby',
      arrIdItems: this.state.arrIdHobby,
      itemsBefore: this.state.newHobby,
      allItems: this.props.all ? this.props.all : [],
    });

    this.setState(
      Object.assign({}, this.state, {
        arrIdHobby: data?.arrIdItemsAfter,
        newHobby: data?.itemsAfter,
      })
    );
  }

  updateHobbies() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.updateInterests(this.state.arrIdHobby);

    //обновляется глобально, чтобы при закрытии модалки данные не сбрасывались и визуально отображали корректные списки (Profile. this.state.interests)
    this.props.updateStateProfile({ interests: this.state.newHobby, modalActive: false });
  }
}

export default ContentModalListHobbies;
