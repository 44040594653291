import * as React from 'react';
import { IFAchievements } from '../../../global/interfaces';

import { ContextModal } from '../../../services/Contexts';
import ContentModalListAchievements from '../ContentModalListAchievements/ContentModalListAchievements';

interface Props {
  achievements: IFAchievements[];
}

class Achievements extends React.Component<Props> {
  render() {
    const { achievements } = this.props;

    return (
      <div className="col-12">
        <div className="page_title">
          <h4>Достижения</h4>
        </div>

        <div className="progress_stat">
          {achievements.map((item, i) => (
            <ContextModal.Consumer key={`achiev${i}`}>
              {({ showModal }) => {
                return (
                  <a
                    href="#progress"
                    className="open_modal"
                    onClick={() => {
                      this.showAchievment(showModal, item);
                    }}
                  >
                    <div className="progress_item">
                      <div className="icon">
                        <div className="count buble">{item.count}</div>
                        <img src={item.src} alt="" />
                      </div>
                      <div className="name">
                        <span>{item.name}</span>
                      </div>
                    </div>
                  </a>
                );
              }}
            </ContextModal.Consumer>
          ))}
        </div>
      </div>
    );
  }

  showAchievment(showModal: any, item: IFAchievements) {
    showModal({
      title: `${item.name}`,
      content: <ContentModalListAchievements data={item} />,
      width: '',
    });
  }
}

export default Achievements;
