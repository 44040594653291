import * as React from 'react';

import { ContextModal } from '../../services/Contexts';

import './Profile.scss';

import RightProfile from '../../components/RightProfile/RightProfile';
import RecProfile from '../../components/profile/RecProfile/RecProfile';
import Сompetencies from '../../components/profile/Competencies/Сompetencies';
import Achievements from '../../components/profile/Achievements/Achievements';
import {
  IFFiltersItem,
  IFWorks,
  IFPeople,
  IFHobby,
  IFShowModal,
  IFLanguages,
  IFAchievements,
  IFModal,
} from '../../global/interfaces';
import Trajectory from '../../components/profile/Trajectory/Trajectory';
import Hobbies from '../../components/profile/Hobbies/Hobbies';
import Languages from '../../components/profile/Languages/Languages';
import TechnicalSkills from '../../components/profile/TechnicalSkills/TechnicalSkills';
import UserTop from '../../components/profile/UserTop/UserTop';
import PersonalityType from '../../components/profile/PersonalityType/PersonalityType';
import Workload from '../../components/profile/Workload/Workload';
import Rewards from '../../components/profile/Rewards/Rewards';
import MyWorks from '../../components/profile/MyWorks/MyWorks';
import FetchMyTrack from '../../services/FetchMyTrack';
import ModalActivity from '../../components/activity/ModalActivity/ModalActivity';
import { TrajectoryStub } from '../../components/profile/Trajectory/TrajectoryStub';
import { star } from '../../global/stub';
import { FetchGetProfile } from '../../services/Serv.Profile';
import { varsString } from '../../global/vars';
import { ClosedModal } from '../../services/Modal';

interface Props {
  profile: IFPeople;
}

interface State {
  idProfile: number;
  modalActive: boolean;
  modal: IFModal;
  name: string;
  surname: string;
  avatar: string;
  line: {
    type: string;
    date: string;
    name: string;
    id: number;
    tracks: {
      id: number;
      name: string;
      score: number;
    }[];
  }[];
  achievements: IFAchievements[];
  interests: IFHobby[];
  interestsAll: IFHobby[];
  languages: {
    image: string;
    level: string;
    lng_id: number;
    lvl_id: number;
    name: string;
  }[];
  skills: IFFiltersItem[];
  skillsAll: IFFiltersItem[];
  topUsers: IFPeople[];
  ratingPlace: {
    max_place: number;
    place: number;
    days: number;
  };
  publications: IFWorks[];
  medals: {
    description: string;
    have: number;
    id: number;
    name: string;
  }[];
  star: {
    name: string;
    count: number;
  }[];
}

class Profile extends React.Component<Props, State> {
  recommendations: boolean;
  workload: boolean;
  personalityType: boolean;
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.recommendations = false;
    this.workload = false;
    this.personalityType = false;
    this.state = {
      idProfile: 1,
      modalActive: false,
      modal: {
        title: 'Заголовок',
        content: null,
        width: '',
      },
      name: '',
      surname: '',
      avatar: '',
      line: [
        // {
        //   type: 'project',
        //   date: '08.12.20',
        //   name: 'Солнечный трекер',
        //   id: 2,
        //   tracks: [
        //     { id: 1, name: 'Промышленный дизайн', score: 11 },
        //     { id: 4, name: 'Промышленная электроника', score: 22 },
        //     { id: 15, name: 'Робототехника', score: 10 },
        //   ],
        // },
      ], // нужно переделать api
      achievements: [],
      interests: [],
      interestsAll: [],
      languages: [],
      skills: [],
      skillsAll: [],
      topUsers: [],
      ratingPlace: { max_place: 0, place: 0, days: 0 },
      publications: [],
      medals: [],
      star: star,
    };
  }

  render() {
    const { profile } = this.props;
    const {
      idProfile,
      name,
      surname,
      avatar,
      line,
      achievements,
      interests,
      interestsAll,
      languages,
      skills,
      skillsAll,
      topUsers,
      ratingPlace,
      publications,
      medals,
      star,
    } = this.state;
    const { modalActive, modal } = this.state;

    const countPublish =
      idProfile != 1
        ? topUsers.find((field) => field.id == idProfile)?.score || 0
        : topUsers.find((field) => field.id == profile.id)?.score || 0;

    publications.map((item) => {
      Object.assign(item, {
        u_name: profile.name,
        u_surname: profile.surname,
      });
    });

    return (
      <React.Fragment>
        <ContextModal.Provider
          value={{
            component: this,
            closedModal: ClosedModal,
            showModal: this.showModal,
            updateLanguages: this.updateLanguages,
          }}
        >
          <div className="page right_block page_profile">
            <div className="page__container">
              <div className="page__content-full">
                {line.length != 0 ? (
                  <Trajectory
                    line={line}
                    title={idProfile == 1 ? 'Моя траектория развития' : 'Траектория развития пользователя'}
                  />
                ) : (
                  <TrajectoryStub />
                )}

                {this.recommendations ? <RecProfile /> : null}

                <Сompetencies star={star} />

                <div className="row mt-40 column-2">
                  <div className="col-6">
                    <div className="row">
                      <Achievements achievements={achievements} />

                      <div className="column-2">
                        <Hobbies
                          interests={interests}
                          interestsAll={interestsAll}
                          updateStateProfile={this.updateStateProfile}
                          edit={idProfile == 1}
                        />

                        <Languages languages={languages} edit={idProfile == 1} />
                      </div>

                      <TechnicalSkills
                        skills={skills}
                        skillsAll={skillsAll}
                        updateStateProfile={this.updateStateProfile}
                        edit={idProfile == 1}
                      />
                    </div>
                  </div>

                  <UserTop
                    users={topUsers}
                    title={`участников`}
                    type={`user`}
                    ratingPlace={ratingPlace}
                    countPublish={countPublish}
                    showUser={this.showUser}
                    profile={{ name, surname, avatar }}
                  />

                  {this.workload ? <Workload /> : null}
                </div>

                {this.personalityType ? <PersonalityType /> : null}

                <Rewards medals={medals} />

                <MyWorks
                  publications={publications}
                  title={idProfile == 1 ? 'Мои работы' : 'Работы пользователя'}
                />
              </div>
            </div>
          </div>
          {modalActive ? (
            <ModalActivity title={modal.title} content={modal.content} width={modal.width} />
          ) : null}
          <RightProfile
            profile={{ name, surname, avatar, text: '', id: idProfile != 1 ? idProfile : profile.id }}
            showUser={this.showUser}
          />
        </ContextModal.Provider>
      </React.Fragment>
    );
  }

  componentDidMount() {
    FetchGetProfile(this, varsString.student);
  }

  showUser = (id: number) => {
    FetchGetProfile(this, varsString.student, id);
  };

  showModal = ({ title, content, width }: IFShowModal) => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: title,
          content: content,
          width: width,
        },
      })
    );
  };

  updateStateProfile = (dataUpdate: { [key: string]: any }) => {
    this.setState(Object.assign({}, this.state, dataUpdate));
  };

  updateLanguages = (newLang: IFLanguages[]) => {
    this.setState({
      languages: newLang,
    });
  };
}

export default Profile;
