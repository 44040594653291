import * as React from 'react';

import { ContextModal } from '../../services/Contexts';

import './ProfileMentor.scss';

import RightProfile from '../../components/RightProfile/RightProfile';
import Сompetencies from '../../components/profile/Competencies/Сompetencies';
import { IFFiltersItem, IFPeople, IFHobby, IFShowModal } from '../../global/interfaces';

import Hobbies from '../../components/profile/Hobbies/Hobbies';
import Languages from '../../components/profile/Languages/Languages';
import TechnicalSkills from '../../components/profile/TechnicalSkills/TechnicalSkills';
import UserTop from '../../components/profile/UserTop/UserTop';
import FetchMyTrack from '../../services/FetchMyTrack';
import ModalActivity from '../../components/activity/ModalActivity/ModalActivity';
import { star } from '../../global/stub';
import { FetchGetProfile } from '../../services/Serv.Profile';
import { varsString } from '../../global/vars';
import { ClosedModal } from '../../services/Modal';

interface Props {
  profile: IFPeople;
}

interface State {
  idProfile: number;
  modalActive: boolean;
  modal: {
    title: string;
    content: React.Component | null;
    width: string;
  };
  name: string;
  surname: string;
  avatar: string;
  interests: IFHobby[];
  interestsAll: IFHobby[];
  languages: {
    image: string;
    level: string;
    lng_id: number;
    lvl_id: number;
    name: string;
  }[];
  skills: IFFiltersItem[];
  skillsAll: IFFiltersItem[];
  topUsers: IFPeople[];
  ratingPlace: {
    max_place: number;
    place: number;
    days: number;
  };
  star: {
    name: string;
    count: number;
  }[];
  statusMessage: string;
  lastOnline: string;
}

class ProfileMentor extends React.Component<Props, State> {
  recommendations: boolean;
  workload: boolean;
  personalityType: boolean;
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.recommendations = false;
    this.workload = false;
    this.personalityType = false;
    this.state = {
      idProfile: 1,
      modalActive: false,
      modal: {
        title: 'Заголовок',
        content: null,
        width: '',
      },
      name: '',
      surname: '',
      avatar: '',
      interests: [],
      interestsAll: [],
      languages: [],
      skills: [],
      skillsAll: [],
      topUsers: [],
      ratingPlace: { max_place: 0, place: 0, days: 0 },
      star: star,
      statusMessage: '',
      lastOnline: '0000-00-00',
    };
  }

  render() {
    const { profile } = this.props;
    const {
      idProfile,
      name,
      surname,
      avatar,
      interests,
      interestsAll,
      languages,
      skills,
      skillsAll,
      topUsers,
      ratingPlace,
      star,
      statusMessage,
    } = this.state;
    const { modalActive, modal } = this.state;

    const countPublish =
      idProfile != 1
        ? topUsers.find((field) => field.id == idProfile)?.score || 0
        : topUsers.find((field) => field.id == profile.id)?.score || 0;

    return (
      <React.Fragment>
        <ContextModal.Provider
          value={{
            component: this,
            closedModal: ClosedModal,
            showModal: this.showModal,
          }}
        >
          <div className="page right_block page_profile">
            <div className="page__container">
              <div className="page__content-full">
                <Сompetencies star={star} />

                <div className="row mt-40 column-2">
                  <div className="col-6">
                    <div className="row">
                      <div className="column-2">
                        <Hobbies interests={interests} interestsAll={interestsAll} edit={idProfile == 1} />

                        <Languages languages={languages} edit={idProfile == 1} />
                      </div>

                      <TechnicalSkills skills={skills} skillsAll={skillsAll} edit={idProfile == 1} />
                    </div>
                  </div>

                  <UserTop
                    users={topUsers}
                    title={`наставников`}
                    type={`mentor`}
                    ratingPlace={ratingPlace}
                    countPublish={countPublish}
                    showUser={this.showUser}
                    profile={{ name, surname, avatar }}
                  />
                </div>
              </div>
            </div>
          </div>
          {modalActive ? (
            <ModalActivity title={modal.title} content={modal.content} width={modal.width} />
          ) : null}
          <RightProfile
            profile={{
              name,
              surname,
              avatar,
              text: statusMessage,
              id: idProfile != 1 ? idProfile : profile.id,
            }}
            showUser={this.showUser}
          />
        </ContextModal.Provider>
      </React.Fragment>
    );
  }

  componentDidMount() {
    FetchGetProfile(this, varsString.mentor);
  }

  showUser = (id: number) => {
    FetchGetProfile(this, varsString.mentor, id);
  };

  showModal = ({ title, content, width }: IFShowModal) => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: title,
          content: content,
          width: width,
        },
      })
    );
  };
}

export default ProfileMentor;
