import React from 'react';

import Slider from 'react-slick';
import AuthorPost from '../../components/answer/AuthorPost/AuthorPost';
import Comment from '../../components/answer/Comment/Comment';
import FormComment from '../../components/FormComment/FormComment';
import { getTypeAndIDPage } from '../../global/functions';
import { IFComment, IFImages } from '../../global/interfaces';
import { baseURL, varsString } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';

import Attachment from '../../components/detailPageActivity/Attachment/Attachment';

import './Answer.scss';
import { ArrDeclensionCases } from '../../services/Declension';

interface Props {}

interface State {
  title: string;
  name: string;
  surname: string;
  text: string;
  date: string;
  avatar: string;
  id: number;
  likes: number;
  liked: boolean;
  comments: IFComment[];
  images: IFImages[];
  commentCount: number;
  document: string[] | null;

  showSubComment: number;
  commentsShowed: number;
  idParentComment: number;
}

class Answer extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  typePage = getTypeAndIDPage().typePage;
  idPage = getTypeAndIDPage().idPage;
  idAnswer = getTypeAndIDPage().idAnswer;
  idModule = getTypeAndIDPage().idModule;
  typePageforApi = this.typePage == 'course' ? 'courses' : this.typePage;

  constructor(props: Props) {
    super(props);
    this.state = {
      title: '',
      name: '',
      surname: '',
      text: '',
      date: '',
      avatar: '',
      id: 0,
      likes: 0,
      liked: false,
      comments: [],
      images: [
        // { original: 'challengeAnswer/challenge566_user7546_num1_1652961201.jpeg' },
        // { original: 'challengeAnswer/challenge761_user376_num1_1612171421.png' },
      ],
      commentCount: 0,
      document: null,
      // [
      //   'challengeAnswer/challenge410_user3963_num1_1652244214.PNG',
      //   'challengeAnswer/182/banner-01 (2).jpg',
      // ],

      showSubComment: -1,
      commentsShowed: 5,
      idParentComment: 0,
    };
  }

  render() {
    const {
      title,
      name,
      surname,
      text,
      date,
      avatar,
      id,
      likes,
      liked,
      comments,
      images,
      commentCount,
      document,
      showSubComment,
      idParentComment,
    } = this.state;
    const { typePage, idPage, idModule } = this;
    const settings = {
      // autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'slider-work',
    };

    // убрать после исправления api
    comments.map((item: IFComment) => {
      if (item.sub) {
        item.sub = Object.values(item.sub);
      }
    });

    let linkBack, typeBack;
    switch (typePage) {
      case 'course':
        linkBack = `/${typePage}/${idPage}/module/${idModule}`;
        typeBack = 'theme';
        break;
      case 'project':
        linkBack = `/${typePage}/${idPage}/tasks`;
        typeBack = 'tasksProject';
        break;
      default:
        linkBack = `/${typePage}/${idPage}`;
        typeBack = typePage;
    }

    return (
      <div className="page page_answer">
        <div className="page__container">
          <div className="page__content-full">
            <div className="trek_page work-answer">
              <div className="back work-answer__back">
                <a href={linkBack}>Перейти к {ArrDeclensionCases(typeBack)[2]}</a>
              </div>

              <div className="work-answer__column">
                <div>
                  <div className="header_photo work-answer__slider">
                    <Slider {...settings}>
                      {images.map((item, i) => (
                        <div key={`slide${i}`}>
                          <div
                            className="work-answer__slide"
                            style={{ backgroundImage: `url(${baseURL}/storage/${item.original})` }}
                          ></div>
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div className="col-9">
                    <div className="video_name_like mt-24 work-answer__name-like">
                      <div id="title" className="name">
                        <h3>{title}</h3>
                      </div>
                      <div
                        className={`sign-board like ${liked ? 'active' : ''}`}
                        onClick={() => {
                          this.liked();
                        }}
                      >
                        <span>{likes}</span>
                      </div>
                    </div>

                    <hr></hr>

                    {document ? (
                      <div className="mt-10 work-answer__attachment">
                        <Attachment attachment={document} title="Прикрепленные файлы" />
                      </div>
                    ) : null}

                    <div className="page_title mt-24">
                      <h4>Комментарии</h4>
                    </div>

                    <div className="add_comment mb-40 ">
                      <FormComment addComment={this.addComment} />
                    </div>

                    <div className="comments">
                      {comments.map(({ type, avatar, u_id, name, surname, comment, date, id, sub }, i) => (
                        <React.Fragment key={`comment${i}`}>
                          <div className="comment">
                            <Comment
                              comment={{
                                id: u_id,
                                avatar,
                                name,
                                surname,
                                date,
                                type: this.checkTypeUser(type),
                                index: i,
                                comment,
                                idComment: id,
                                sub: sub ? sub : null,
                              }}
                              showFormToComment={this.showFormToComment}
                              closedFormsSubComment={this.closedFormsSubComment}
                              addComment={this.addComment}
                              checkTypeUser={this.checkTypeUser}
                            />

                            {showSubComment == i ? (
                              <div className="sub-comments">
                                <div className="add_comment mb-24">
                                  <FormComment
                                    subComment={true}
                                    closedFormsSubComment={this.closedFormsSubComment}
                                    addComment={this.addComment}
                                    idParentComment={idParentComment}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {sub ? <hr></hr> : null}
                        </React.Fragment>
                      ))}
                      {commentCount > comments.length ? (
                        <div className="load_more text-center mt-24">
                          <button className="border_btn big w230" onClick={() => this.loadComments()}>
                            Показать еще
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="video_info">
                    <AuthorPost author={{ id, avatar, name, surname, date, text }} />

                    <div className="gradient_block you_wont">
                      <span>Хочешь принять участие в этом челлендже?</span>
                      <a href={`/${typePage}/${idPage}`} className="blue_btn w100">
                        Подробнее
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.getAnswer(this.idAnswer, this.typePageforApi).then((data) => {
      this.setState({
        title: data.title,
        name: data.name,
        surname: data.surname,
        text: data.text,
        date: data.date,
        avatar: data.avatar,
        id: data.id,
        likes: data.likes,
        liked: data.liked,
        comments: data.comments ? Object.values(data.comments) : [], // исправить api
        commentCount: data.commentCount,
        // document: data.document,// исправить api
        // images: data.image, // исправить api
      });
    });
  }

  closedFormsSubComment = () => {
    this.setState(
      Object.assign({}, this.state, {
        showSubComment: -1,
      })
    );
  };

  liked() {
    this.FetchAPI.setLike(this.idAnswer, this.typePageforApi).then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          likes: data,
          liked: !this.state.liked,
        })
      );
    });
  }

  loadComments() {
    this.FetchAPI.loadComments(this.typePageforApi, this.idAnswer, this.state.commentsShowed).then(
      (data: { [key: string]: IFComment }) => {
        const newComm: IFComment[] = Object.values(data);
        newComm.map((item) => {
          Object.assign(item, { type: 'student', u_id: 376 }); // ЗАГЛУШКА, некорректное api
        });

        this.setState(
          Object.assign({}, this.state, {
            commentsShowed: this.state.commentsShowed + 5,
            comments: this.state.comments.concat(newComm), // api исправить надо
          })
        );
      }
    );
  }

  checkTypeUser(type: string) {
    if (type == varsString.student) {
      return varsString.user;
    }
    return type;
  }

  addComment = (newComment: IFComment, idParent: number) => {
    const { comments, commentCount } = this.state;
    let newComms: IFComment[] = [];
    if (idParent == 0) {
      newComms =
        commentCount == comments.length ? this.state.comments.concat(newComment) : this.state.comments;
    } else {
      comments.forEach((comm) => {
        if (comm.id == idParent) {
          const newCom = Object.assign({}, comm, {
            sub: Object.assign({}, comm.sub, { sub: newComment }),
          });
          newComms.push(newCom);
        } else {
          newComms.push(comm);
        }
      });
    }

    this.setState(
      Object.assign({}, this.state, {
        comments: newComms,
      })
    );
  };

  showFormToComment = (i: number, idParentComment: number) => {
    this.setState(
      Object.assign({}, this.state, {
        showSubComment: i,
        idParentComment: idParentComment,
      })
    );
  };
}

export default Answer;
