import * as React from 'react';

// не используется, поэтому пока статично и не отлажено
class PersonalityType extends React.Component {
  render() {
    return (
      <div className="row mt-40">
        <div className="col-6">
          <div className="page_title">
            <h4>Тип личности</h4>
            <i className="explanation icon-q-a">
              <span className="tooltip">
                <span>
                  Киберэкономика состоит из системных ресурсов, которые повышают эффективность экономических
                  процессов путем оптимального управления связью и взаимодействием между подсистемами
                  субъектов и объектов экономических отношений. Глобализация на основе новейших ИКТ.
                </span>
              </span>
            </i>
          </div>

          <div className="type_line" data-type="-43">
            <span>Экстраверсия</span>
            <span>Интроверсия</span>
            <div className="line">
              <div className="blue_line"></div>
            </div>
          </div>
          <div className="type_line" data-type="75.673">
            <span>Независимость</span>
            <span>Согласие</span>
            <div className="line">
              <div className="blue_line"></div>
            </div>
          </div>
          <div className="type_line" data-type="24.571">
            <span>Обучаемость</span>
            <span>Инертность</span>
            <div className="line">
              <div className="blue_line"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalityType;
