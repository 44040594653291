import * as React from 'react';
import Slider from 'react-slick';
import { getTypeAndIDPage } from '../../global/functions';
import { IFBanner } from '../../global/interfaces';
import { baseURL } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {}

interface State {
  banners: IFBanner[];
}

class Banner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      banners: [],
    };
  }

  render() {
    const { banners } = this.state;

    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'slider-banner',
    };

    const res =
      banners.length != 0 ? (
        <div className="slider240 mb-16">
          <Slider {...settings}>
            {banners.map(({ title, description, fields, banner }, i) => (
              <div className="slide" key={`slide${i}`}>
                <div className="left">
                  <div className="text">
                    <span className="title">{title}</span>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="right">
                  <div className="list">
                    {fields.map(({ text, link }, idx) => (
                      <a href={link} key={`link${idx}`}>
                        {text}
                      </a>
                    ))}
                  </div>
                </div>
                <div
                  className="bg"
                  style={{
                    backgroundImage: `url(${
                      banner != 'null' ? `${baseURL}/storage/${banner}` : `${baseURL}/img/bg/slider_bg.png`
                    })`,
                  }}
                ></div>
              </div>
            ))}
          </Slider>
        </div>
      ) : null;

    return res;
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    const { typePage } = getTypeAndIDPage();

    FetchAPI.getBanner(typePage).then((data) => {
      const banners: IFBanner[] = [];

      data.data.forEach((item: any) => {
        const banner = {
          banner: item.banner,
          title: item.header_main,
          description: item.description_main,
          show: item.show,
        };

        const fields: any[] = [];
        item.field.forEach((dataField: any) => {
          const field = {
            text: dataField.header_url,
            link: dataField.url,
          };
          fields.push(field);
        });

        banners.push(Object.assign(banner, { fields: fields }));
      });

      this.setState({
        banners: banners,
      });
    });
  }
}

export default Banner;
