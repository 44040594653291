import * as React from 'react';

import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {}

interface State {
  key: string;
  error: boolean;
  errorText: string;
  success: boolean;
}

class SubscriptionActivation extends React.Component<Props, State> {
  errors: { [key: string]: string }[];
  textsForm: {
    success: { title: string; text: string };
    main: { title: string; text: string };
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      key: '',
      error: false,
      errorText: '',
      success: false,
    };
    this.errors = [{ error: 'Invalid key format', text: 'Ключ недействителен' }];
    this.textsForm = {
      main: {
        title: 'Активировать подписку',
        text: 'Введите лицензионный ключ для получения доступа к платному контенту.',
      },
      success: {
        title: 'Подписка успешно активирована',
        text: 'На этой странице появится активированный тобой образовательный контент. Удачного прохождения.',
      },
    };
  }

  render() {
    const { error, errorText, success } = this.state;
    const { textsForm } = this;

    return (
      <div className="order__subscription">
        <form className="subscription">
          <div className="subscription__content">
            <p className="subscription__caption">
              {success ? textsForm.success.title : textsForm.main.title}
            </p>
            <p className="subscription__text">{success ? textsForm.success.text : textsForm.main.text}</p>
            <div className="subscription__key">
              <div className="subscription__input">
                <div className="input-text">
                  <input
                    type="text"
                    className={`input-text__input input-text__input_key ${
                      error ? 'input-text__input_error' : ''
                    }`}
                    placeholder="Ключ"
                    onChange={(e) => this.setKey(e)}
                  />
                  {error ? <span className="input-text__notification">{errorText}</span> : null}
                </div>
              </div>
              <button
                type="button"
                className="button button_blue"
                onClick={() => {
                  this.activate();
                }}
              >
                Активировать
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  setKey(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState(Object.assign({}, this.state, { key: e.target.value }));
  }

  activate() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.licenseActivation(this.state.key).then((data) => {
      if (data.key) {
        this.setState(
          Object.assign({}, this.state, {
            error: true,
            errorText: data.key,
          })
        );
      } else {
        this.setState(
          Object.assign({}, this.state, {
            error: false,
            errorText: '',
            success: true,
          })
        );

        setTimeout(() => {
          this.setState(
            Object.assign({}, this.state, {
              success: false,
            })
          );
        }, 5000);
      }
    });
  }
}

export default SubscriptionActivation;
