import * as React from 'react';
import Moment from 'react-moment';

import './CardWorks.scss';

import { baseURL } from '../../global/vars';
import { IFWorks } from '../../global/interfaces';

interface Props {
  card: IFWorks;
}

class CardWorks extends React.Component<Props> {
  render() {
    const { activity, parent, id, image, name, u_name, u_surname, publish_time, video_id, module } =
      this.props.card;

    let cardImage;
    if (video_id) {
      cardImage = {
        backgroundImage: `url(https://img.youtube.com/vi/${video_id}/hqdefault.jpg`,
      };
    } else {
      const img = /\/([A-Za-z0-9_.]*)"}/.exec(image);
      const answer = /":"([A-Za-z]*)/.exec(image);
      if (img && answer) {
        cardImage = {
          backgroundImage: `url(${baseURL}/storage/${answer[1]}/${img[1]}`,
        };
      }
    }

    const linkToCard =
      activity == 'course'
        ? `/${activity}/${parent}/module/${module}/answer/${id}`
        : `/${activity}/${parent}/answer/${id}`;

    return (
      <div className="work-card">
        <div className="work-card__preload">
          <div className="work-card__img-block work-card__img-block_preload"> </div>
          <div className="work-card__content work-card__content_preload">
            <div className="work-card__content-top">
              <h4 className="work-card__caption work-card__caption_preload"></h4>
            </div>

            <div className="work-card__content-bottom">
              <div className="work-card__sign-board work-card__sign-board_preload">
                <span></span>
              </div>
              <div className="work-card__sign-board work-card__sign-board_preload">
                <span></span>
              </div>
            </div>
          </div>
        </div>

        <a href={linkToCard}>
          <div className="work-card__img-block">
            <div className="work-card__img" style={cardImage}></div>
          </div>
          <div className="work-card__content">
            <div className="work-card__content-top">
              <h4 className="work-card__caption">{name}</h4>
            </div>

            <div className="work-card__content-bottom">
              <div className="work-card__sign-board work-card__sign-board_user">
                <span>
                  {u_name} {u_surname}
                </span>
              </div>
              <div className="work-card__sign-board work-card__sign-board_date">
                <span>
                  <Moment format="D MMMM YYYY">{publish_time}</Moment>
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default CardWorks;
