import * as React from 'react';
import './Layout.scss';

import SmallScreen from '../SmallScreen/SmallScreen';
import HeaderLeftMenu from '../HeaderLeftMenu/HeaderLeftMenu';
import HeaderTopMenu from '../HeaderTopMenu/HeaderTopMenu';
import ModalGeo from '../modals/ModalGeo/ModalGeo';
import { IFGeo } from '../../global/interfaces';
import { ContextApp } from '../../services/Contexts';
import { ClosedPopup } from '../../global/functions';
import { popups } from '../../global/vars';

type Props = {
  page?: React.ReactElement;
  pageName?: string;
};

interface State {
  geoModal: boolean;
  geo: IFGeo | null;
  elClick: React.MouseEvent | null;
}
class Layout extends React.Component<Props, State> {
  popups: Element[];

  constructor(props: Props) {
    super(props);
    this.state = {
      geoModal: false,
      geo: null,
      elClick: null,
    };
    this.popups = [];
  }

  render() {
    const { pageName = '', page } = this.props;
    const { geo } = this.state;

    return (
      <ContextApp.Consumer>
        {({ updateProfile, updateGeo }) => {
          return (
            <div
              onClick={(e) => {
                this.setState(Object.assign({}, this.state, { elClick: e }));
              }}
            >
              <SmallScreen />
              <HeaderLeftMenu />
              <HeaderTopMenu
                updateGeo={updateGeo}
                updateGeoRegion={this.updateGeoRegion}
                pageName={pageName}
                getModal={this.getModal}
                geo={geo}
                updateProfile={updateProfile}
                elClick={this.state.elClick}
              />
              {page}
              {this.state.geoModal ? (
                <ModalGeo
                  closedModal={this.closedModal}
                  updateGeo={updateGeo}
                  updateGeoRegion={this.updateGeoRegion}
                />
              ) : null}
            </div>
          );
        }}
      </ContextApp.Consumer>
    );
  }

  closedPopups(e: React.MouseEvent) {
    if (this.popups.length == 0) {
      const listPopups = [
        document.querySelector('.top-menu .notifications_popup '),
        document.querySelector('.top-menu .geoLocation__form '),
        document.querySelector('.top-menu .menu '),
      ];

      listPopups.forEach((item) => {
        if (item) {
          this.popups.push(item);
        }
      });
    }

    this.popups.forEach((element) => {
      const clickPopup = this.hasContains(element, e);

      if (!clickPopup) {
        element?.classList.remove('active');
      }
    });
  }

  hasContains(element: any, event: React.MouseEvent) {
    if (event.target instanceof Node && element.contains(event.target)) {
      return true;
    } else {
      return false;
    }
  }

  getModal = (showModal: boolean) => {
    this.setState(
      Object.assign(this.state, {
        geoModal: showModal,
      })
    );
  };

  closedModal = () => {
    this.setState(
      Object.assign(this.state, {
        geoModal: false,
      })
    );
  };

  updateGeoRegion = (newGeo: IFGeo) => {
    this.setState(
      Object.assign(this.state, {
        geo: newGeo,
      })
    );
  };

  closedPopup = (component: React.Component, field: string) => {
    ClosedPopup(component, field);
  };
}

export default Layout;
