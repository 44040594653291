import React from 'react';
import { bodyAuto } from '../../../global/functions';
import { ContextModal } from '../../../services/Contexts';

import './ModalActivity.scss';

interface Props {
  title: string;
  content?: React.Component | null;
  width?: string;
}

class ModalActivity extends React.Component<Props> {
  render() {
    const { title, content, width } = this.props;

    const close = (
      <ContextModal.Consumer>
        {({ closedModal, component }) => {
          return (
            <span
              className="modal-activity__close"
              onClick={() => {
                closedModal(component);
                bodyAuto();
              }}
            >
              ×
            </span>
          );
        }}
      </ContextModal.Consumer>
    );

    return (
      <div className="modal-activity">
        <div className={`modal-activity__popup ${width ? `modal-activity__popup_${width}` : ''}`}>
          <div className="modal-activity__title">
            <h3>{title}</h3>
          </div>
          {close}

          {content}
        </div>
      </div>
    );
  }
}

export default ModalActivity;
