import * as React from 'react';
import { IFPeople, IFShowModal } from '../global/interfaces';

const profile: {
  closedModal: any;
  showModal?: any;
  updateLanguages?: any;
  component: React.Component | null;
} = {
  closedModal: () => {},
  showModal: ({}: IFShowModal) => {},
  component: null,
};
export const ContextModal = React.createContext(profile);

const app: {
  updateGeo: any;
  updateProfile: any;
  profile: IFPeople;
} = {
  updateGeo: () => {},
  updateProfile: () => {},
  profile: {
    name: '',
    surname: '',
    avatar: '',
    text: '',
    id: 0,
  },
};
export const ContextApp = React.createContext(app);
