import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/ru';

import { IFSubComment } from '../../../global/interfaces';
import { baseURL, timezone } from '../../../global/vars';

interface Props {
  comment: {
    id: number;
    avatar: string;
    name: string;
    surname: string;
    date: string;
    comment: string;
    type: string;
    index: number;
    idComment: number;
    sub: IFSubComment[] | null;
  };
  showFormToComment: any;
  closedFormsSubComment: any;
  addComment: any;
  checkTypeUser: any;
}

class Comment extends React.Component<Props> {
  render() {
    const {
      comment: { id, avatar, name, surname, date, type, index, comment, idComment, sub },
      showFormToComment,
      closedFormsSubComment,
      addComment,
      checkTypeUser,
    } = this.props;

    return (
      <React.Fragment>
        <a href={`/profile/${type}/${id}`}>
          <div
            className="ava"
            style={{
              backgroundImage: `url(${baseURL}/storage/${avatar})`,
            }}
          ></div>
        </a>
        <div className="comment_content">
          <div className="autor">
            <a href={`/profile/${type}/${id}`}>
              <b>
                {name} {surname}
              </b>
            </a>
            <span>
              <Moment format="D MMM YYYY в HH:mm (Томск)" add={{ hours: timezone }}>
                {date}
              </Moment>
            </span>
          </div>
          <div className="text">
            <p>{comment}</p>
          </div>
          <div
            className="answer"
            onClick={() => {
              showFormToComment(index, idComment);
            }}
          >
            <span>Ответить</span>
          </div>
        </div>
        {sub ? (
          <div className="sub-comments">
            {sub.map((sub, i) => (
              <React.Fragment key={`sumcomment${i}`}>
                <hr></hr>
                <div className="comment">
                  <Comment
                    comment={{
                      id: sub.u_id,
                      avatar: sub.avatar,
                      name: sub.name,
                      surname: sub.surname,
                      date: sub.date,
                      type: checkTypeUser(type),
                      index: index,
                      comment: sub.comment,
                      idComment: idComment,
                      sub: null,
                    }}
                    showFormToComment={showFormToComment}
                    closedFormsSubComment={closedFormsSubComment}
                    addComment={addComment}
                    checkTypeUser={checkTypeUser}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Comment;
