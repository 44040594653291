import * as React from 'react';
import Moment from 'react-moment';
import Pagination from 'react-js-pagination';
import { IFNotify } from '../../global/interfaces';

interface Props {
  notify: IFNotify[];
  totalPage: number;
  getNotify: any;
}

interface State {
  activePage: number;
}

class TabsContentNotify extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  render() {
    const { notify, totalPage } = this.props;

    return (
      <div id="notification_container">
        {notify.map(({ message, date }, i) => (
          <div className="notification" key={`notify${i}`}>
            <div className="info">
              <div className="text" dangerouslySetInnerHTML={{ __html: message }}></div>
              <div className="date">
                <span>
                  <Moment format="D MMMM YYYY, HH:mm" add={{ hours: 4 }}>
                    {date}
                  </Moment>{' '}
                  (+4 Мск, Томск)
                </span>
              </div>
            </div>
          </div>
        ))}

        <nav aria-label="Search results pages">
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={1}
            totalItemsCount={totalPage}
            pageRangeDisplayed={6}
            onChange={this.handlePageChange.bind(this)}
            hideDisabled={true}
            // hideFirstLastPages={true}
            // hideNavigation={true}
            innerClass="pagination justify-content-center"
            itemClass="page-item"
            linkClass="page-link"
          />
        </nav>
      </div>
    );
  }

  handlePageChange(pageNumber: number) {
    this.setState({ activePage: pageNumber });
    this.props.getNotify(pageNumber);
  }
}

export default TabsContentNotify;
