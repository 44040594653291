import React from 'react';

import './CreateGroupChat.scss';
import { IFChats, IFPeople } from '../../../global/interfaces';
import UserIco from '../../UserIco/UserIco';
import SearchUsersForChat from '../SearchUsersForChat/SearchUsersForChat';
import FetchMyTrack from '../../../services/FetchMyTrack';
import { baseURL } from '../../../global/vars';
import { getTypeAndIDPage } from '../../../global/functions';
import { ClosedModal } from '../../../services/Modal';

interface Props {
  profile: IFPeople;
  addChat?: any;
  editChat?: {
    name: string;
    image?: string | null;
    checked?: number | null;
    members: IFPeople[];
  };
  buttonName: string;
  component?: React.Component;
}

interface State {
  nameChat: string;
  imageChat: string;
  defaultImageChat: number;
  members: IFPeople[];
  search: boolean;
  listUsersSearch: IFPeople[];
}

class CreateGroupChat extends React.Component<Props, State> {
  images: string[];
  fileImage: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      nameChat: this.props.editChat ? this.props.editChat.name : '',
      imageChat: this.props.editChat ? (this.props.editChat.image ? this.props.editChat.image : '') : '',
      defaultImageChat: this.props.editChat
        ? this.props.editChat.checked
          ? this.props.editChat.checked
          : -1
        : -1,
      members: this.props.addChat
        ? [this.props.profile]
        : this.props.editChat
        ? this.props.editChat.members
        : [],
      search: false,
      listUsersSearch: [],
    };
    this.images = [
      require('./img/1.jpg'),
      require('./img/2.jpg'),
      require('./img/3.jpg'),
      require('./img/4.jpg'),
      require('./img/5.jpg'),
      require('./img/6.jpg'),
    ];
    this.fileImage = React.createRef();
  }

  render() {
    const { images } = this;
    const { search, members, imageChat, defaultImageChat } = this.state;
    const { editChat, buttonName } = this.props;

    const imageBg = editChat?.image
      ? { backgroundImage: `url('${baseURL}/storage/${editChat?.image}')` }
      : imageChat != ''
      ? { backgroundImage: `url('${imageChat}')` }
      : {};

    return (
      <div className="content-modal create-chat">
        <form>
          <div className="input find_input">
            <input
              type="text"
              name="chat_name"
              placeholder="Название чата"
              defaultValue={editChat?.name}
              onChange={(e) => {
                this.setNameChat(e);
              }}
            ></input>
          </div>
        </form>

        <p className="create-chat__title mt-40">Выберите изображение чата</p>

        <div className="select_list_chat_ava">
          <div className="list">
            <div className="new_ava">
              <div id="new_ava_output_upload" className="upload_list"></div>

              <div className="current_ava" style={imageBg}></div>

              <div className="add_new_ava_input">
                <label className="" htmlFor="new_ava_upload"></label>
                <input
                  type="file"
                  id="new_ava_upload"
                  name="chat_avatar"
                  onChange={() => {
                    this.fileChanged();
                  }}
                  ref={this.fileImage}
                ></input>
              </div>
            </div>

            {images.map((item, i) => (
              <label key={`image${i}`}>
                <input
                  type="radio"
                  name="defaultImageChat"
                  value={i}
                  defaultChecked={editChat ? editChat.checked == i + 1 : defaultImageChat == i + 1}
                  onChange={() => {
                    this.changedBg(i + 1);
                  }}
                ></input>
                <div className="ava" style={{ backgroundImage: `url('${item}')` }}>
                  <div className="select"></div>
                </div>
              </label>
            ))}
          </div>
        </div>

        <p className="create-chat__title mt-40">
          Участники (<span>{members.length}</span>)
        </p>

        <div className="mentors_list">
          <div className="add_challenge">
            <div className="line">
              {members.map((member, i) => (
                <UserIco
                  typeLink="unlink"
                  data={member}
                  hasDel={true}
                  toggleList={this.toggleMember}
                  key={`user${i}`}
                />
              ))}

              {!search ? (
                <div
                  className="user add_new user_top"
                  onClick={() => {
                    this.showSearch();
                  }}
                >
                  <div>
                    <div className="ava"></div>
                    <div className="name">
                      <span>Добавить</span>
                      <span>участника</span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {search ? (
              <SearchUsersForChat
                updateUsersSearch={this.updateUsersSearch}
                toggleList={this.toggleMember}
                members={members}
              />
            ) : null}
          </div>
        </div>

        <div className="btn_line blacklist_buttons">
          <input
            type="submit"
            value={buttonName}
            className="blue_btn"
            onClick={() => {
              this.createdChat();
            }}
          ></input>
        </div>
      </div>
    );
  }

  toggleMember = (userID: number) => {
    const { members, listUsersSearch } = this.state;
    const hasUser = members.find((field) => field.id == userID);

    if (hasUser) {
      const listMembers = members;
      listMembers.splice(members.indexOf(hasUser), 1);
      this.setState(
        Object.assign({}, this.state, {
          members: listMembers,
        })
      );
    } else {
      const people = listUsersSearch.find((field) => field.id == userID);
      if (people) {
        this.setState(
          Object.assign({}, this.state, {
            members: members.concat(people),
          })
        );
      }
    }
  };

  updateUsersSearch = (users: IFPeople[]) => {
    this.setState(
      Object.assign({}, this.state, {
        listUsersSearch: users,
      })
    );
  };

  setNameChat(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      Object.assign({}, this.state, {
        nameChat: e.target.value,
      })
    );
  }

  fileChanged() {
    const file = this.fileImage.current;
    const reader = new FileReader();

    if (file && file.files?.length) {
      reader.readAsDataURL(file.files[0]);

      reader.onload = (e) => {
        this.setState(
          Object.assign({}, this.state, {
            imageChat: e.target?.result,
            defaultImageChat: -1,
          })
        );
      };
    }
  }

  changedBg(i: number) {
    this.setState(
      Object.assign({}, this.state, {
        defaultImageChat: i,
        imageChat: null,
      })
    );
  }

  showSearch() {
    this.setState(
      Object.assign({}, this.state, {
        search: true,
      })
    );
  }

  createdChat() {
    const { nameChat, members, defaultImageChat } = this.state;
    const { addChat, editChat, component } = this.props;
    const FetchAPI = new FetchMyTrack();
    const data = new FormData();

    data.append('chat_name', nameChat);

    const fileEl = this.fileImage.current;
    if (fileEl && fileEl.files?.length) {
      const file: Blob | null = fileEl.files[0];
      data.append('chat_avatar', file);
    } else {
      if (defaultImageChat != -1) {
        data.append('default_chat_ava', defaultImageChat.toString());
      }
    }

    members.forEach((item) => {
      data.append('users_list[]', item.id.toString());
    });

    if (editChat) {
      const { idPage } = getTypeAndIDPage();
      data.append('chat_id', idPage.toString());

      FetchAPI.editChat(data).then((data) => {
        component ? ClosedModal(component) : null;
      });
    } else {
      FetchAPI.createChat(data).then((data) => {
        addChat(FIX_transformData(data)); // поменять после обновления api
      });
    }
  }
}

export default CreateGroupChat;

const FIX_transformData = (data: any) => {
  // удалить после обновления api
  const newData: IFChats = {
    avatar: {
      type: data.avatar.default ? 'default' : '',
      path: data.avatar.path,
    },
    banned: 0,
    chat_name: data.name,
    chat_type: data.type,
    id: data.id,
    is_file: null,
    last_activity: 0,
    last_message_date: null,
    last_read: null,
    message: '',
    sender_id: null,
    user_id: 'group',
    user_type: 'group',
  };

  return newData;
};
