import React from 'react';
import Select from 'react-select';

import './ContentModalListLanguages.scss';

import { IFLanguages } from '../../../global/interfaces';
import FetchMyTrack from '../../../services/FetchMyTrack';
import { ContextModal } from '../../../services/Contexts';

interface Props {
  languages: IFLanguages[];
  edit?: boolean;
}

interface State {
  languages: IFLanguages[];
  editState: boolean;
  editLang: string;
  editLangID: number;
  editLevel: string;
  editLevelID: number;
  addLang: string;
  addLangID: number;
  addLevel: string;
  addLevelID: number;
}
interface IFSelectItem {
  value: number;
  label: string;
  image?: string;
}

class ContentModalListLanguages extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  optionLang: IFSelectItem[];
  optionLevel: IFSelectItem[];

  constructor(props: Props) {
    super(props);
    this.state = {
      languages: this.props.languages,
      editState: false,
      editLang: '',
      editLangID: -1,
      editLevel: '',
      editLevelID: -1,
      addLang: '',
      addLangID: -1,
      addLevel: '',
      addLevelID: -1,
    };
    this.optionLang = [
      { value: 1, label: 'Английский', image: 'gb' },
      { value: 2, label: 'Немецкий', image: 'de' },
      { value: 3, label: 'Русский', image: 'ru' },
    ];
    this.optionLevel = [
      { value: 1, label: 'A1 Novice' },
      { value: 2, label: 'A2 Intermediate' },
      { value: 3, label: 'B1 Advanced' },
      { value: 4, label: 'B2 Advanced' },
      { value: 5, label: 'C1 Advanced' },
      { value: 6, label: 'C2 Superior' },
      { value: 7, label: 'Родной' },
    ];
  }

  render() {
    const { edit = false } = this.props;
    const { languages, editState, editLang, editLevel } = this.state;

    return (
      <ContextModal.Consumer>
        {({ updateLanguages }) => {
          return (
            <div className="content-modal content-modal_lang">
              {languages.map((item: IFLanguages, i) => (
                <div className="lang" key={`lang${i}`}>
                  <i className={`${item.image}`}></i>
                  <span>
                    {item.name}
                    <span>({item.level})</span>
                  </span>
                  {edit ? (
                    <span
                      className="lang__edit"
                      onClick={() => {
                        this.editLang(i);
                      }}
                    ></span>
                  ) : null}
                  {edit ? (
                    <span
                      className="lang__delete"
                      onClick={() => {
                        this.delete(i, updateLanguages);
                      }}
                    ></span>
                  ) : null}
                </div>
              ))}
              {editState ? (
                <form
                  className="lang-all lang-all_edit"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.updateLang(updateLanguages);
                  }}
                >
                  <Select
                    options={this.optionLang}
                    placeholder={editLang}
                    isSearchable={false}
                    className="select-my"
                    classNamePrefix="select-my"
                    onChange={(selectedOpt: any) => {
                      this.updateState({
                        editLang: selectedOpt.label,
                        editLangID: selectedOpt.value,
                      });
                    }}
                  />
                  <Select
                    options={this.optionLevel}
                    placeholder={editLevel}
                    isSearchable={false}
                    className="select-my"
                    classNamePrefix="select-my"
                    onChange={(selectedOpt: any) => {
                      this.updateState({
                        editLevel: selectedOpt.label,
                        editLevelID: selectedOpt.value,
                      });
                    }}
                  />
                  <button type="submit" className="border_btn big">
                    Сохранить
                  </button>
                </form>
              ) : null}
              {edit ? (
                <form
                  className="lang-all"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.setLang(updateLanguages);
                  }}
                >
                  <Select
                    options={this.optionLang}
                    placeholder={`Язык`}
                    isSearchable={false}
                    className="select-my"
                    classNamePrefix="select-my"
                    onChange={(selectedOpt: any) => {
                      this.updateState({
                        addLang: selectedOpt.label,
                        addLangID: selectedOpt.value,
                      });
                    }}
                  />
                  <Select
                    options={this.optionLevel}
                    placeholder={`Уровень владения`}
                    isSearchable={false}
                    className="select-my"
                    classNamePrefix="select-my"
                    onChange={(selectedOpt: any) => {
                      this.updateState({
                        addLevel: selectedOpt.label,
                        addLevelID: selectedOpt.value,
                      });
                    }}
                  />
                  <button className="blue_btn">Добавить</button>
                </form>
              ) : null}
            </div>
          );
        }}
      </ContextModal.Consumer>
    );
  }

  delete(i: number, updateLanguages: any) {
    const { languages } = this.state;

    const newLang = languages.concat([]);
    newLang.splice(i, 1);

    const deletedID = languages[i].lng_id;

    this.setState(
      Object.assign({}, this.state, {
        languages: newLang,
      })
    );
    updateLanguages(newLang);

    this.FetchAPI.removeLanguage(deletedID);
  }

  editLang(i: number) {
    const { languages } = this.props;

    this.setState(
      Object.assign({}, this.state, {
        editState: true,
        editLang: languages[i].name,
        editLangID: languages[i].lng_id,
        editLevel: languages[i].level,
        editLevelID: languages[i].lvl_id,
      })
    );
  }

  updateLang(updateLanguages: any) {
    const { languages, editLang, editLevel, editLangID, editLevelID } = this.state;
    const newLang: IFLanguages[] = languages.concat([]);

    let newField: IFLanguages;
    const editField = languages.find((field) => field.name == editLang);
    if (editField) {
      newField = {
        name: editLang,
        image: this.optionLang.find((field) => field.label == editLang)?.image || '',
        level: editLevel,
        lng_id: editLangID,
        lvl_id: editLevelID,
      };

      newLang[newLang.indexOf(editField)] = newField;

      this.setState(
        Object.assign({}, this.state, {
          editState: false,
          languages: newLang,
        })
      );
      updateLanguages(newLang);
      this.FetchAPI.updateLanguage(editLangID, editLevelID);
    }
  }

  updateState(obj: any) {
    this.setState(Object.assign({}, this.state, obj));
  }

  setLang(updateLanguages: any) {
    const { addLang, addLevel, addLangID, addLevelID } = this.state;
    this.FetchAPI.setLanguage(addLangID, addLevelID);

    const newLang = [
      ...this.state.languages,
      {
        image: this.optionLang.find((field) => field.label == addLang)?.image,
        level: addLevel,
        lng_id: addLangID,
        lvl_id: addLevelID,
        name: addLang,
      },
    ];
    updateLanguages(newLang);
    this.setState(
      Object.assign({}, this.state, {
        languages: newLang,
      })
    );
  }
}

export default ContentModalListLanguages;
