import * as React from 'react';
import FetchMyTrack from '../../../services/FetchMyTrack';
import { ContextModal } from '../../../services/Contexts';

import './ModalIdeasMatrix.scss';

interface Props {}
interface State {
  loadFile: boolean;
  nameFile: string;
  email: string;
  text: string;
  file: Blob | null;
}

class ModalIdeasMatrix extends React.Component<Props, State> {
  fileInput: React.RefObject<HTMLInputElement>;
  defualtTextFile: string;

  constructor(props: Props) {
    super(props);
    this.defualtTextFile = 'Прикрепить файл(ы) (до 20 мб)';
    this.state = {
      loadFile: false,
      nameFile: this.defualtTextFile,
      email: '',
      text: '',
      file: null,
    };
    this.fileInput = React.createRef();
  }

  render() {
    return (
      <ContextModal.Consumer>
        {({ closedModal, component }) => {
          return (
            <form
              className="modal-ideas-matrix"
              onSubmit={(e) => {
                this.sendIdea(e);
                closedModal(component);
              }}
              id="formSend"
            >
              <input
                required
                type="email"
                name="email"
                placeholder="Ваш e-mail"
                maxLength={150}
                onChange={(e) => {
                  this.setState(Object.assign({}, this.state, { email: e.target.value }));
                }}
              ></input>
              <textarea
                className="mt-16"
                name="idea"
                cols={30}
                rows={10}
                placeholder="Сообщение"
                onChange={(e) => {
                  this.setState(Object.assign({}, this.state, { text: e.target.value }));
                }}
              ></textarea>
              <div className="modal-ideas-matrix__bottom mt-24">
                <div className="modal-ideas-matrix__add-files">
                  <div>
                    <label htmlFor="cover_news1" className="modal-ideas-matrix__attach">
                      {this.state.nameFile}
                    </label>
                    <input
                      type="file"
                      id="cover_news1"
                      name="document"
                      onChange={(e) => {
                        this.loadingFile(e);
                      }}
                      ref={this.fileInput}
                    ></input>
                  </div>

                  {this.state.loadFile ? (
                    <div
                      className="modal-ideas-matrix__delete-files"
                      onClick={() => {
                        this.deletedFile();
                      }}
                    >
                      удалить
                    </div>
                  ) : null}
                </div>
                <button type="submit" className="blue_btn">
                  Отправить
                </button>
              </div>
            </form>
          );
        }}
      </ContextModal.Consumer>
    );
  }

  loadingFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.fileInput.current?.files != null) {
      this.setState({
        loadFile: true,
        nameFile: this.fileInput.current?.files[0].name,
        file: e.target.files !== null ? e.target.files[0] : null,
      });
    }
  }

  deletedFile() {
    if (this.fileInput.current?.files != null) {
      this.fileInput.current.value = '';

      this.setState({
        loadFile: false,
        nameFile: this.defualtTextFile,
      });
    }
  }

  sendIdea(e: React.FormEvent) {
    e.preventDefault();
    const { email, text } = this.state;
    const data = new FormData();
    const file = this.state.file;

    if (file !== null) {
      data.append('document', file);
    }
    data.append('email', email);
    data.append('idea', text);

    const FetchAPI = new FetchMyTrack();
    FetchAPI.sendUserIdea(data);
  }
}

export default ModalIdeasMatrix;
