export const Declension = (n: number, textForms: string[]): string => {
  // взято https://realadmin.ru/coding/sklonenie-na-javascript.html
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return textForms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return textForms[1];
  }
  if (n1 == 1) {
    return textForms[0];
  }
  return textForms[2];
};

export const ArrDeclension = (type: string): string[] => {
  let arr: string[] = [];
  switch (type) {
    case 'course':
      arr = ['курс', 'курса', 'курсов'];
      break;
    case 'project':
      arr = ['проект', 'проекта', 'проектов'];
      break;
    case 'event':
      arr = ['событие', 'события', 'событий'];
      break;
    case 'challenge':
      arr = ['челлендж', 'челленджа', 'челленджей'];
      break;
    case 'people':
      arr = ['человек', 'человека', 'человек'];
      break;
    default:
      ['null', 'null', 'null'];
  }
  return arr;
};

export const ArrDeclensionCases = (type: string): string[] => {
  let arr: string[] = [];
  switch (type) {
    case 'course':
      arr = ['курс', 'курса', 'курсу', 'курс', 'курсом', 'курсе'];
      break;
    case 'project':
      arr = ['проект', 'проекта', 'проекту', 'проект', 'проектом', 'проекте'];
      break;
    case 'event':
      arr = ['событие', 'события', 'событию', 'событие', 'событием', 'событии'];
      break;
    case 'challenge':
      arr = ['челлендж', 'челленджа', 'челленджу', 'челлендж', 'челленжем', 'челлендже'];
      break;
    case 'theme':
      arr = ['тема', 'темы', 'теме', 'тема', 'темой', 'теме'];
      break;
    case 'tasksProject':
      arr = [
        'задачи проекта',
        'задач проекта',
        'задачам проекта',
        'задачи проекта',
        'задачами проекта',
        'задачах проекта',
      ];
      break;
    case 'qualification':
      arr = ['квалификация', 'квалификации', 'квалификацие', 'квалификация', 'квалификацией', 'квалификации'];
      break;
    default:
      ['null', 'null', 'null', 'null', 'null', 'null'];
  }
  return arr;
};
