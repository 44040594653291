import * as React from 'react';

// import './svg-donut-chart-framework';

interface Props {}

interface State {}

class Donut extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="card card-donut">
        <div className="page_title">
          <h4>Контент платформы</h4>
        </div>
        <div className="content-card">
          <svg id="svg"></svg>
        </div>
      </div>
    );
  }
}

export default Donut;
