import * as React from 'react';

import './News.scss';

import { count } from '../../global/vars';

interface Props {
  typeCard?: React.ReactElement;
  content: JSX.Element[];
  countCards: number;
  getFilteredPageMore: any;
}

class News extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { content, countCards } = this.props;
    const cardsMore = countCards >= count.cardsToPage;

    return (
      <div className="news">
        {countCards != 0 ? (
          <React.Fragment>
            <div className={`news__list-grid`}>{content}</div>

            {cardsMore && (
              <div className="load_more text-center mt-24">
                <button className="border_btn big w230 activity_show_more" onClick={() => this.loadMore()}>
                  Показать еще
                </button>
              </div>
            )}
          </React.Fragment>
        ) : (
          <p className="news__nofound">
            По вашему запросу результатов не найдено, попробуйте изменить запрос.
          </p>
        )}
      </div>
    );
  }

  loadMore() {
    this.props.getFilteredPageMore();
  }
}

export default News;
