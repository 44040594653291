import * as React from 'react';
import { Navigate } from 'react-router';

import '../Input/Input.scss';

import { regions } from '../../global/vars';
import Input from '../Input/Input';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {
  passwordRecovery: any;
}

interface State {
  email: string;
  password: string;
  errorEmail: string;
  errorPass: string;
  errorServer: string;
  redirect: boolean;
}

class FormSignIn extends React.Component<Props, State> {
  remember: boolean;
  role: boolean;
  acceptTerms: boolean;
  regions: { [key: string]: string };

  constructor(props: Props) {
    super(props);
    this.remember = false;
    this.role = false;
    this.acceptTerms = false;
    this.regions = regions;
    this.state = {
      email: '',
      password: '',
      errorEmail: '',
      errorPass: '',
      errorServer: '',
      redirect: false,
    };
  }

  render() {
    const { remember } = this;
    const { email, password, errorEmail, errorPass, errorServer, redirect } = this.state;
    console.log(redirect);
    if (redirect) {
      return <Navigate to="/profile" />;
    }

    return (
      <form
        id="login_form"
        onSubmit={(e) => {
          e.preventDefault();
          this.login();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            this.login();
          }
        }}
      >
        <div className="row">
          <Input
            type="text"
            name="mail"
            placeholder="Логин"
            value={email}
            saveValue={this.saveEmail}
            error={errorEmail}
          />
          <Input
            type="password"
            name="password"
            placeholder="Пароль"
            value={password}
            saveValue={this.savePassword}
            error={errorPass}
          />

          {remember ? (
            <div className="col-12">
              <div className="input checkbox">
                <input type="checkbox" id="checkbox_task_1" />
                <label htmlFor="checkbox_task_1">Запомнить меня</label>
              </div>
            </div>
          ) : null}

          <div className="col-12 input text-center">
            <input type="submit" className="blue_btn" value="Войти" />
            <span className="input-text__notification input-text__notification_server">{errorServer}</span>
          </div>

          <div className="mt-24" onClick={() => this.props.passwordRecovery()}>
            <a href="#recovery" className="remember_pass">
              Напомнить пароль
            </a>
          </div>
        </div>
      </form>
    );
  }

  login() {
    const { email, password } = this.state;

    if (email == '' || password == '') {
      this.setState(
        Object.assign({}, this.state, {
          errorEmail: email == '' ? 'Поле пустое' : '',
          errorPass: password == '' ? 'Поле пустое' : '',
        })
      );
    } else {
      const FetchAPI = new FetchMyTrack();
      FetchAPI.login(email, password).then((data) => {
        if (data.status == 'fail') {
          if (data.errors.mail) {
            this.setState(
              Object.assign({}, this.state, { errorServer: data.errors.mail, errorEmail: '', errorPass: '' })
            );
          }
          if (data.errors.user) {
            this.setState(
              Object.assign({}, this.state, { errorServer: data.errors.user, errorEmail: '', errorPass: '' })
            );
          }
        } else {
          this.setState(Object.assign({}, this.state, { errorServer: '', errorEmail: '', errorPass: '' }));
        }

        if (data.status == 'success') {
          const token = data.objects.token;
          const userType = data.objects.userType;
          this.setState(Object.assign({}, this.state, { redirect: true }));
          localStorage.setItem('token', token);
        }

        return;
      });
    }
  }

  saveEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { email: e.target.value }));
  };

  savePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { password: e.target.value }));
  };
}

export default FormSignIn;
