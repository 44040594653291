import * as React from 'react';

import './ContentModalBuy.scss';

import Tabs from '../../Tabs/Tabs';

interface Props {}

interface State {
  active: number;
}

class ContentModalBuy extends React.Component<Props, State> {
  tabs: string[];

  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
    };
    this.tabs = [
      // 'Онлайн оплата',
      'Активация подписки',
    ];
  }

  render() {
    const onlinePayment = (
      <form className="tabs-with-content__content tabs-with-content__content_active">
        <p className="modal-purchase-options__content-text">На какой e-mail отправить чек?</p>
        <div className="modal-purchase-options__block-email">
          <div className="modal-purchase-options__input-email">
            <div className="input-text">
              <input type="text" className="input-text__input" placeholder="vasilisa@mail.ru" />
              <span className="input-text__notification">Ключ недействителен</span>
            </div>
          </div>
          <button type="button" className="button modal-purchase-options__button">
            Оплатить
          </button>
        </div>
      </form>
    );

    const subscriptionActivation = (
      <div className="tabs-with-content__content">
        <p>
          Активировать доступ к квалификации по лицензионному ключу. Получить лицензионный ключ можно в школе.
        </p>
        <a href="/orders" className="button modal-purchase-options__button">
          Перейти к активации
        </a>
      </div>
    );

    const tabsContent = [
      // onlinePayment,
      subscriptionActivation,
    ];

    return (
      <div className="modal-purchase-options__content">
        <div className="tabs-with-content">
          <Tabs
            tabs={this.tabs}
            tabsContent={tabsContent}
            setActive={this.setActive}
            active={this.state.active}
          />
        </div>
      </div>
    );
  }

  setActive = (active: number) => {
    this.setState({ active: active });
  };
}

export default ContentModalBuy;
