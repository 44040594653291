import { IFCompetencies } from './interfaces';

// profile/Сompetencies
export const listCompetencies: IFCompetencies[] = [
  {
    name: 'Научно-производственная сфера',
    id: 1,
    tracks: [
      {
        id: 15,
        name: 'Робототехника',
        nameImg: 'robotics',
        sliderActivity: [],
        competencies: [
          {
            id: 0,
            name: 'Разработка дизайн-концепции объетов промышленного производства',
            score: 5,
            items: [
              {
                id: 65,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 1,
              },
              {
                id: 66,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 2,
              },
              {
                id: 67,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 3,
              },
              {
                id: 68,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 4,
              },
              {
                id: 69,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 5,
              },
              {
                id: 70,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 6,
              },
              {
                id: 71,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 7,
              },
              {
                id: 72,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 8,
              },
              {
                id: 73,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 1,
              },
              {
                id: 74,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 2,
              },
              {
                id: 75,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 3,
              },
              {
                id: 76,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 4,
              },
              {
                id: 77,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 5,
              },
              {
                id: 78,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 6,
              },
              {
                id: 79,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 7,
              },
              {
                id: 80,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 8,
              },
            ],
          },
        ],
      },
      {
        id: 16,
        name: 'Биоинформатика',
        nameImg: 'bioinformatics',
        sliderActivity: [
          {
            course: 'Кубики историй',
            course_id: 1401,
            image: {
              thumb: 'eventCard1401_1652416732_thumb.jpeg',
              crop: 'eventCard1401_1652416732_crop.jpeg',
              original: 'eventCard1401_material_1_1652416732.png',
            },
            score: 1,
            type: 'event',
            video: null,
          },
          {
            course: 'Как растения регулируют накопление парниковых газов',
            course_id: 565,
            image: {
              original: 'challengeCard565_material_1_1578909895.jpg',
            },
            score: 2,
            type: 'challenge',
            video: null,
          },
        ],
        competencies: [
          {
            id: 0,
            name: 'Разработка дизайн-концепции объетов промышленного производства',
            score: 0,
            items: [
              {
                id: 0,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 1,
              },
              {
                id: 1,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 2,
              },
              {
                id: 2,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 3,
              },
              {
                id: 3,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 4,
              },
              {
                id: 4,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 5,
              },
              {
                id: 5,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 6,
              },
              {
                id: 6,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 7,
              },
              {
                id: 7,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 8,
              },
              {
                id: 8,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 1,
              },
              {
                id: 9,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 2,
              },
              {
                id: 10,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 3,
              },
              {
                id: 11,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 4,
              },
              {
                id: 12,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 5,
              },
              {
                id: 13,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 6,
              },
              {
                id: 14,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 7,
              },
              {
                id: 15,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 8,
              },
            ],
          },
        ],
      },
      {
        id: 17,
        name: 'Mobile Health',
        nameImg: 'mobile-health',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 18,
        name: 'Автономные транспортные системы',
        nameImg: 'autonomous-transport-systems',
        sliderActivity: [],
        competencies: [],
      },
    ],
  },
  {
    name: 'Экологическая сфера',
    id: 3,
    tracks: [
      {
        id: 11,
        name: 'AR/VR',
        nameImg: 'ar-vr',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 12,
        name: 'Геймдизайн',
        nameImg: 'game-design',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 13,
        name: 'Цифровая журналистика',
        nameImg: 'digital-journalism',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 14,
        name: 'Обработка видеоизображения',
        nameImg: 'photo-video-editing',
        sliderActivity: [],
        competencies: [],
      },
    ],
  },
  {
    name: 'Креативная экономика',
    id: 3,
    tracks: [
      {
        id: 11,
        name: 'AR/VR',
        nameImg: 'ar-vr',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 12,
        name: 'Геймдизайн',
        nameImg: 'game-design',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 13,
        name: 'Цифровая журналистика',
        nameImg: 'digital-journalism',
        sliderActivity: [],
        competencies: [
          {
            id: 1,
            name: 'Владение методами и инструментами работы с данными',
            score: 60,
            items: [],
          },
          {
            id: 2,
            name: 'Дистрибуция и продвижение журналистского контента',
            score: 100,
            items: [
              {
                id: 0,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 1,
              },
              {
                id: 1,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 2,
              },
              {
                id: 2,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 3,
              },
              {
                id: 3,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 4,
              },
              {
                id: 4,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 5,
              },
              {
                id: 5,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 6,
              },
              {
                id: 6,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 7,
              },
              {
                id: 7,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 8,
              },
              {
                id: 8,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 1,
              },
              {
                id: 9,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 2,
              },
              {
                id: 10,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 3,
              },
              {
                id: 11,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 4,
              },
              {
                id: 12,
                completed: true,
                text: 'Способен выполнять изменение размеров изображения, поворот, кадрирование с использованием простейших графических редакторов',
                score: 5,
              },
              {
                id: 13,
                completed: false,
                text: 'Способен редактировать изображение: выполнять выделение, перемещение и трансформацию фрагментов, добавлять на изображение новые элементы, в том числе текст',
                score: 6,
              },
              {
                id: 14,
                completed: true,
                text: 'Способен создавать собственные изображения, используя все доступные инструменты простейших графических редакторов',
                score: 7,
              },
              {
                id: 15,
                completed: false,
                text: 'Знает форматы графических данных. Способен подготавливать изображения для печати и оптимизировать графику для веб',
                score: 8,
              },
            ],
          },
          {
            id: 3,
            name: 'Создание и упаковка журналистского контента для медиаплатформ',
            score: 15,
            items: [],
          },
        ],
      },
      {
        id: 14,
        name: 'Обработка видеоизображения',
        nameImg: 'photo-video-editing',
        sliderActivity: [],
        competencies: [
          {
            id: 4,
            name: 'Видеомонтаж и саунд-дизайн',
            score: 55,
            items: [],
          },
          {
            id: 5,
            name: 'Графический дизайн',
            score: 3,
            items: [],
          },
          {
            id: 6,
            name: 'Создание анимации',
            score: 20,
            items: [],
          },
        ],
      },
    ],
  },
  {
    name: 'Киберэкономика',
    id: 3,
    tracks: [
      {
        id: 11,
        name: 'AR/VR',
        nameImg: 'ar-vr',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 12,
        name: 'Геймдизайн',
        nameImg: 'game-design',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 13,
        name: 'Цифровая журналистика',
        nameImg: 'digital-journalism',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 14,
        name: 'Обработка видеоизображения',
        nameImg: 'photo-video-editing',
        sliderActivity: [],
        competencies: [],
      },
    ],
  },
  {
    name: 'Новый технологический сектор',
    id: 3,
    tracks: [
      {
        id: 11,
        name: 'AR/VR',
        nameImg: 'ar-vr',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 12,
        name: 'Геймдизайн',
        nameImg: 'game-design',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 13,
        name: 'Цифровая журналистика',
        nameImg: 'digital-journalism',
        sliderActivity: [],
        competencies: [],
      },
      {
        id: 14,
        name: 'Обработка видеоизображения',
        nameImg: 'photo-video-editing',
        sliderActivity: [],
        competencies: [],
      },
    ],
  },
];

export const star = [
  {
    name: 'Киберэкономика',
    count: 11,
  },
  {
    name: 'Креативная экономика',
    count: 8,
  },
  {
    name: 'Научно-производственная сфера',
    count: 46,
  },
  {
    name: 'Новый технологический сектор',
    count: 3,
  },
  {
    name: 'Экологическая сфера',
    count: 0,
  },
];
