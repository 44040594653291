import React from 'react';
import { Link } from 'react-router-dom';
import FetchMyTrack from '../../../services/FetchMyTrack';
import { ClosedModal } from '../../../services/Modal';

import './DeleteChat.scss';

interface Props {
  component: React.Component;
  idChat: number;
}

interface State {
  error: string;
}

class DeleteChat extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  render() {
    const { component } = this.props;
    const { error } = this.state;

    return (
      <div className="content-modal blocked-user">
        <div className="text">
          <span>Вы уверены, что хотите удалить чат?</span>
        </div>

        <div className="blocked-user__buttons">
          <button
            className="blocked-user__button border_btn big w100 button_close"
            onClick={() => {
              ClosedModal(component);
            }}
          >
            Нет
          </button>
          <div
            className="blocked-user__button red_btn w100 button_close"
            onClick={(e) => {
              e.preventDefault();
              this.deleted();
            }}
          >
            Да
          </div>
        </div>
        <span className="notifications">{error}</span>
      </div>
    );
  }

  deleted() {
    const { idChat, component } = this.props;
    const FetchAPI = new FetchMyTrack();

    FetchAPI.deleteChat(idChat).then((data) => {
      if (data.status == 'success') {
        ClosedModal(component);
        window.location.href = '/messages';
      } else {
        this.setState({ error: `${data.status}: Ошибка отправки запроса` });
      }
    });
  }
}

export default DeleteChat;
