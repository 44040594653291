import * as React from 'react';

import './DetailPageTrack.scss';

import { IFAlert, IFFiltersItem, IFHobby, IFNews, IFPeople } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';
import RightProfile from '../../components/RightProfile/RightProfile';
import BannerActivity from '../../components/BannerActivity/BannerActivity';
import { getTypeAndIDPage } from '../../global/functions';
import Hobbies from '../../components/profile/Hobbies/Hobbies';
import TechnicalSkills from '../../components/profile/TechnicalSkills/TechnicalSkills';
import CardNews from '../../components/CardNews/CardNews';
import Alert from '../../components/Alert/Alert';

interface Props {
  profile: IFPeople;
}

interface State {
  settingsBtn: { [key: string]: boolean };
  type: string;
  banner: {
    thumb?: string;
    crop?: string;
    original: string;
  };
  name: string;
  countStudents: number;
  description: any;
  descriptionFull: any;
  interests: IFHobby[];
  skills: IFFiltersItem[];
  work: any;
  news: IFNews[];
  currentTrack: boolean;
  alert: IFAlert | null;
}

class DetailPageTrack extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  idPage = getTypeAndIDPage().idPage;
  typePage = getTypeAndIDPage().typePage;

  constructor(props: Props) {
    super(props);
    this.state = {
      settingsBtn: {},
      type: '',
      banner: {
        thumb: '',
        crop: '',
        original: '',
      },
      name: '',
      countStudents: 0,
      description: null,
      descriptionFull: null,
      interests: [],
      skills: [],
      work: null,
      news: [],
      currentTrack: false,
      alert: null,
    };
  }

  render() {
    const { profile } = this.props;
    const {
      settingsBtn,
      type,
      banner,
      name,
      countStudents,
      description,
      descriptionFull,
      interests,
      skills,
      work,
      news,
      currentTrack,
      alert,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page right_block detail-track">
          <div className="page__container">
            <div className="page__content-full">
              <BannerActivity
                typeActivity={type}
                name={name}
                countStudents={countStudents}
                banner={banner}
                {...settingsBtn}
                textBtn={currentTrack ? 'Отменить выбор' : 'Выбрать трек'}
                textCount="Этот трек выбрали"
                hasMeasurement={true}
                clickButton={this.selectTrack}
              />
              <div className="text mt-24" dangerouslySetInnerHTML={{ __html: description }}></div>

              <div className="page__column-2">
                <Hobbies interests={interests} interestsAll={interests} edit={false} limitItem={100} />
                <TechnicalSkills skills={skills} skillsAll={skills} edit={false} limitItem={100} />
              </div>

              <div className="rectangle" dangerouslySetInnerHTML={{ __html: work }}></div>

              <div className="text mb-40">
                <h3 className="mb-24">Новости трека</h3>
                <div className="detail-track__news">
                  {news.length ? (
                    news.map((item, i) => <CardNews card={item} key={`card${i}`} />)
                  ) : (
                    <div>Новостей пока нет.</div>
                  )}
                </div>
              </div>

              <div className="text">
                <h3>Полное описание трека</h3>
                <div className="text mt-24" dangerouslySetInnerHTML={{ __html: descriptionFull }}></div>
              </div>

              <div className="trek_card_bottom">
                <div className="img">
                  <img src="https://mytrack.ru/img/trek/bitmap.png" alt=""></img>
                </div>
                <div className="text">
                  <span>Трек</span>
                  <h2>{name}</h2>

                  {!currentTrack ? (
                    <div className="btn_block">
                      <button className="blue_btn" onClick={() => this.selectTrack()}>
                        Выбрать трек
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>

              {alert ? <Alert title={alert.title} text={alert.text} component={this} /> : null}
            </div>
          </div>
        </div>

        <RightProfile profile={profile} />
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.FetchAPI.getTrack(this.idPage).then((data) => {
      this.setState({
        settingsBtn: data.currentTrack
          ? { btnCheckTrack: true, statusActiveTrack: true }
          : { btnCheckTrack: true },
        type: this.typePage,
        banner: { original: data.image },
        name: data.name,
        countStudents: data.user_count,
        description: data.subtitle,
        descriptionFull: data.description,
        interests: data.interests,
        skills: data.tech_skills,
        work: data.work,
        news: data.track_news,
        currentTrack: data.currentTrack,
      });
    });
  }

  selectTrack = () => {
    const { currentTrack } = this.state;

    if (currentTrack) {
      this.FetchAPI.deleteCurrentTrack().then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            currentTrack: false,
            settingsBtn: { btnCheckTrack: true },
            alert: {
              title: data.status == 'success' ? 'Все хорошо!' : 'Ошибка',
              text: data.objects,
            },
          })
        );
      });
    } else {
      this.FetchAPI.changeCurrentTrack(this.idPage).then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            currentTrack: true,
            settingsBtn: { btnCheckTrack: true, statusActiveTrack: true },
            alert: {
              title: data.status == 'success' ? 'Все хорошо!' : 'Ошибка',
              text: data.objects,
            },
          })
        );
      });
    }
  };
}

export default DetailPageTrack;
