import * as React from 'react';
import TrackList from '../../components/TrackList/TrackList';

interface Props {}

class Tracks extends React.Component<Props> {
  render() {
    const {} = this.props;

    return (
      <div>
        <div className="page right_block">
          <div className="content">
            <div className="page_title">
              <h4>Ты можешь начать движение по треку одиним из способов:</h4>
            </div>

            <TrackList />
          </div>
        </div>
      </div>
    );
  }
}

export default Tracks;
