import * as React from 'react';

import './DetailPageOrganization.scss';

import FetchMyTrack from '../../services/FetchMyTrack';

import { IFMentor } from '../../global/interfaces';
import Mentors from '../../components/detailPageActivity/Mentors/Mentors';
import CardInfo from '../../components/detailPageActivity/CardInfo/CardInfo';
import MoreActivityInDetail from '../../components/MoreActivityInDetail/MoreActivityInDetail';
import { baseURL } from '../../global/vars';
import Tabs from '../../components/Tabs/Tabs';
import { getTypeAndIDPage } from '../../global/functions';

interface Props {}

interface State {
  tabActive: number;
  type: string;
  banner: {
    thumb?: string;
    crop?: string;
    original: string;
  };
  name: string;
  description: any;
  contactGeoCode: string | null;
  contactAddress: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  mentors: IFMentor[];
  challenges: any[];
  courses: any[];
  events: any[];
  projects: any[];
}

class DetailPageOrganization extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      tabActive: 0,
      type: '',
      banner: {
        thumb: '',
        crop: '',
        original: '',
      },
      name: '',
      description: null,
      contactGeoCode: null,
      contactAddress: null,
      contactName: null,
      contactEmail: null,
      contactPhone: null,
      mentors: [],
      challenges: [],
      courses: [],
      events: [],
      projects: [],
    };
  }

  render() {
    const {
      type,
      banner,
      name,
      description,
      contactGeoCode,
      contactAddress,
      contactName,
      contactEmail,
      contactPhone,
      mentors,
    } = this.state;

    const cardInfo = (
      <CardInfo
        startDate={null}
        endDate={null}
        contactGeoCode={contactGeoCode}
        contactAddress={contactAddress}
        contactName={contactName}
        contactEmail={contactEmail}
        contactPhone={contactPhone}
        type={type}
        organization={''}
      />
    );

    const checkTabs = this.checkTabs();
    const tabs = checkTabs.tabs;
    const tabsContent = checkTabs.tabsContent;

    return (
      <div className="page">
        <div className="page__container">
          <div className="page__content-full detail-activity">
            <div
              className="banner big"
              style={{
                backgroundImage: `url(${baseURL}/storage/${banner.crop ? banner.crop : banner.original})`,
              }}
            >
              <div className="text">
                <span>Организация</span>
                <h2>{name}</h2>
              </div>
            </div>

            <div className="detail-activity__content mt-24">
              <div>
                <div className="text mt-24" dangerouslySetInnerHTML={{ __html: description }}></div>
              </div>

              {cardInfo}
            </div>

            {tabs.length != 0 ? (
              <div className="detail-activity__tabs">
                <Tabs
                  tabs={tabs}
                  tabsContent={tabsContent}
                  setActive={this.setActive}
                  active={this.state.tabActive}
                />
              </div>
            ) : null}

            {mentors.length != 0 ? <Mentors title={`Наставники`} mentors={mentors} /> : null}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();

    const { idPage, typePage } = getTypeAndIDPage();

    FetchAPI.getCompany(idPage).then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          type: typePage,
          banner: data.photo_links,
          name: data.name,
          description: data.description,
          contactGeoCode: data.contact_geocode,
          contactAddress: data.contact_address,
          contactName: data.contact_person,
          contactEmail: data.contact_person_email,
          contactPhone: data.contact_person_phone,
          mentors: data.mentors ? data.mentors : [],
          challenges: data.challenges,
          courses: data.courses,
          events: data.events,
          projects: data.projects,
        })
      );
    });
  }

  setActive = (active: number) => {
    this.setState(Object.assign({}, this.state, { tabActive: active }));
  };

  checkTabs() {
    const { events, courses, projects, challenges } = this.state;
    const tabs = [],
      tabsContent = [];

    if (events.length != 0) {
      tabs.push('События');
      tabsContent.push(<MoreActivityInDetail seeMore={events} type="event" key="events" />);
    }
    if (courses.length != 0) {
      tabs.push('Курсы');
      tabsContent.push(<MoreActivityInDetail seeMore={courses} type="course" key="courses" />);
    }
    if (projects.length != 0) {
      tabs.push('Проекты');
      tabsContent.push(<MoreActivityInDetail seeMore={projects} type="project" key="projects" />);
    }
    if (challenges.length != 0) {
      tabs.push('Челленджи');
      tabsContent.push(<MoreActivityInDetail seeMore={challenges} type="challenge" key="challenges" />);
    }

    return { tabs, tabsContent };
  }
}

export default DetailPageOrganization;
