import * as React from 'react';

import './MoreActivityInDetail.scss';

import CardActivity from '../CardActivity/CardActivity';
import { IFCardActivity, IFSeeMoreEvents } from '../../global/interfaces';

interface Props {
  seeMore: IFSeeMoreEvents[];
  title?: string;
  type: string;
}

class MoreActivityInDetail extends React.Component<Props> {
  render() {
    const { seeMore, title, type } = this.props;

    let listCard: IFCardActivity[] = [];
    seeMore.forEach((item) => {
      listCard.push({
        city_name: item.place || null,
        course_count: 0,
        date: item.date,
        id: item.id,
        image: item.image,
        is_online: 1,
        name: item.name,
        organization: item.organization,
        price: item.price,
        status: item.status,
        student_count: 0,
      });
    });

    return (
      <div className="more-events">
        {title ? (
          <div className="page_title mt-80">
            <h3>{title}</h3>
          </div>
        ) : null}

        <div className="news news__grid">
          <div className={`news__list-grid news__list-grid_4item`}>
            {listCard.map((item, i) => (
              <CardActivity pageName={type} data={item} tagLine={''} key={`card${i}`} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  checkNameFile(item: string) {
    const arr = /.*\/(.*)/.exec(item);
    return arr ? arr[1] : '';
  }
}

export default MoreActivityInDetail;
