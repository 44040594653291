import * as React from 'react';
import Slider from 'react-slick';

import './RightProfile.scss';

import { IFPeople } from '../../global/interfaces';
import { baseURL } from '../../global/vars';
import Mentor from '../Mentor/Mentor';
import UserIco from '../UserIco/UserIco';

interface Props {
  profile: IFPeople;
  showUser?: any;
}

interface State {
  show: boolean;
  activeSlideFriends: number;
  activeSlideMentors: number;
}

class RightProfile extends React.Component<Props, State> {
  friends: IFPeople[];
  mentors: IFPeople[];

  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
      activeSlideFriends: 1,
      activeSlideMentors: 1,
    };
    this.friends = [
      // {
      //   name: 'Екатерина',
      //   surname: 'Любер',
      //   avatar: 'studentAvatars/default_male.png',
      //   id: 999999999,
      //   score: 122,
      // },
    ];
    this.mentors = [
      // {
      //   name: 'Анастасия',
      //   surname: 'Вольнорудова',
      //   avatar: 'mentorAvatars/1586_avatar.jpg',
      //   text: 'Основатель креативного агенства «Амика». В разработке и руководстве коммандой 10 лет',
      //   id: 22,
      // },
    ];
  }

  render() {
    const {
      profile: { name, surname, avatar, text = '' },
      showUser,
    } = this.props;
    const { friends, mentors } = this;

    const countFriendsGroup = 4;
    const friendsSlider: IFPeople[][] = [];
    for (let i = 0; i < friends.length; i += countFriendsGroup) {
      const group: IFPeople[] = friends.slice(i, i + countFriendsGroup);
      friendsSlider.push(group);
    }

    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'slider-friends',
    };

    return (
      <div id="right_block" className={`right-block-my-lk ${this.state.show ? 'roll_up show' : ''}`}>
        <div
          className="expand_block"
          onClick={() => {
            this.show();
          }}
        ></div>

        <div
          className="roll_up_btn"
          onClick={() => {
            this.closed();
          }}
        ></div>

        <a href="/settings" className="settings">
          <img src={require('../../old/img/icons/a-settings.svg').default} alt="" />
        </a>

        <div className="profile">
          <div className="photo">
            <div className="ava" style={{ backgroundImage: `url('${baseURL}/storage/${avatar}')` }}></div>
          </div>
          <div className="name">
            <span>
              {name}
              <br />
              {surname}
            </span>
          </div>
          <div className="deskr">
            <span>{text}</span>
          </div>

          <div className="online_status">
            <span>Онлайн</span>
          </div>

          <div className="right-block-my-lk__buttons">
            <div className="right-block-my-lk__button-my-order">
              <a href="/orders" className="button button_border button_big">
                Мои покупки
              </a>
            </div>
          </div>
        </div>

        {friends.length ? (
          <div className="friends">
            <div className="page_title">
              <h4>Друзья</h4>
              <a href="/user_friends" className="link_border">
                Все друзья
              </a>
            </div>

            <div className="friends_slider">
              <div>
                <Slider
                  {...settings}
                  afterChange={(current: number) => this.setState({ activeSlideFriends: current + 1 })}
                >
                  {friendsSlider.map((item, i) => (
                    <div key={`friend${i}`}>
                      <div className="users_list">
                        {item.map((people, idx) => (
                          <UserIco
                            typeLink={'user'}
                            data={people}
                            showUser={showUser}
                            key={`userIco${idx}`}
                          /> // typeLink нужно отредактировать, когда будут в api данные по друзьям
                        ))}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

            <div className="slider_counter">
              <span id="friends_slider_counter">{this.state.activeSlideFriends}</span>/{friendsSlider.length}
            </div>
          </div>
        ) : null}

        {mentors.length ? (
          <div className="mertors">
            <div className="page_title">
              <h4>Наставники</h4>
            </div>

            <div className="mertors_slider shadow-box">
              <Slider
                {...settings}
                afterChange={(current: number) => this.setState({ activeSlideMentors: current + 1 })}
              >
                {mentors.map(({ name, surname, avatar, text, id }, i) => (
                  <Mentor
                    name={name}
                    surname={surname}
                    avatar={avatar}
                    text={text}
                    id={id}
                    key={`mentor${i}`}
                  />
                ))}
              </Slider>
            </div>
            <div className="slider_counter">
              <span id="mertors_slider_counter">{this.state.activeSlideMentors}</span>/{mentors.length}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  show() {
    this.setState({ show: true });
  }

  closed() {
    this.setState({ show: false });
  }
}

export default RightProfile;
