import * as React from 'react';

import { getTypeAndIDPage } from '../../global/functions';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {}

interface State {
  name: string;
  description: string;
}

class TaskActivity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: 'Тема',
      description: '',
    };
  }

  render() {
    const { name, description } = this.state;
    const { idPage, typePage, idModule } = getTypeAndIDPage();

    return (
      <div className="page">
        <div className="page__container">
          <div className="page__content-full">
            <nav className="nav_blue">
              <div className="left">
                <a href={`/${typePage}/${idPage}/module/${idModule}`}>К теме курса</a>
              </div>
              <div className="content_8col">
                <div className="center">
                  <div className="drop_down_list">
                    <div className="selected">
                      <span>{`Задание: ${name}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </nav>

            <div id="module_article" className="content_8col">
              <div className="content">
                <div className="news_page">
                  <div className="page_title">
                    <h1>{name}</h1>
                  </div>
                  <div className="text" dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    const { idPage, idModule, idTask } = getTypeAndIDPage();

    FetchAPI.getCourseModuleTask(idPage, idModule, idTask).then((data) => {
      this.setState({
        name: data.name,
        description: data.description,
      });
    });
  }
}

export default TaskActivity;
