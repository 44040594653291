import React from 'react';
import CardActivity from '../../components/CardActivity/CardActivity';
import News from '../../components/News/News';
import { getTypeAndIDPage } from '../../global/functions';
import { IFCardActivity, IFImages } from '../../global/interfaces';
import { apiUrl } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';
import { filteredPageMore } from '../../services/Serv.ActivityPages';

import './SkillActivities.scss';

interface Props {}
interface State {
  cards: {
    city: string | null;
    date: string | null;
    id: number;
    image: IFImages[];
    name: string;
    organization: string;
    price: number;
    type: string;
    video: string | null;
  }[];
  page: number;
}

class SkillActivities extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  types: {
    name: string;
    type: string;
  }[];

  constructor(props: Props) {
    super(props);
    this.state = {
      cards: [],
      page: 0,
    };
    this.types = [
      { name: 'Курс', type: 'course' },
      { name: 'Проект', type: 'project' },
      { name: 'Событие', type: 'event' },
      { name: 'Челлендж', type: 'challenge' },
    ];
  }
  render() {
    const { cards } = this.state;

    const arrData: IFCardActivity[] = [];

    cards.forEach((item) => {
      const data: IFCardActivity = {
        city_name: item.city ? item.city : '',
        course_count: 0, // ?
        date: item.date ? item.date : '',
        id: item.id,
        image: item.image[0],
        is_online: 1, // ?
        name: item.name,
        organization: item.organization,
        price: item.price,
        status: null, // ?
        student_count: 0, // ?
        type: this.types.find((field) => field.name == item.type)?.type,
      };
      arrData.push(data);
    });

    const content = arrData.map((item, i) => (
      <CardActivity
        pageName={item.type ? item.type : ''}
        data={item}
        tagLine={cards[i].type}
        key={`card${i}`}
      />
    ));

    return (
      <div className="page page_skillActivities">
        <div className="page__container">
          <div className="page-with-left-bar">
            <div className="left-bar">
              <div className="left-bar__back">
                <a href="/profile">Вернутьcя к профилю</a>
              </div>
              <div className="left-bar__page-name">
                <span className="left-bar__title">Образовательные активности элемента компетенции</span>
              </div>
            </div>

            <div className="page__content">
              <News
                content={content}
                countCards={20 /*cards.length раскомментировать когда будут приходить по 18 карточек*/}
                getFilteredPageMore={this.getFilteredPageMore}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { idPage } = getTypeAndIDPage();

    this.FetchAPI.getSkillActivities(idPage, 0).then((data) => {
      this.setState({
        cards: data.cardFields,
      });
    });
  }

  getFilteredPageMore = () => {
    const newPage = this.state.page + 1;
    const { idPage } = getTypeAndIDPage();

    this.FetchAPI.getSkillActivities(idPage, newPage).then((data) => {
      this.setState({
        cards: this.state.cards.concat(data.cardFields),
        page: newPage,
      });
    });
  };
}

export default SkillActivities;
