import { ComponentState } from 'react';
import FetchMyTrack from '../services/FetchMyTrack';
import { Config, IFCardActivity, IFFiltersItem } from '../global/interfaces';
import { apiFields } from '../global/vars';

export const printDataPage = (url: string, geo: number, config: Config, Component: React.Component) => {
  const FetchAPI = new FetchMyTrack();
  FetchAPI.getFilterFieldsPOST(url, geo, Component);

  FetchAPI.getFilteredPage(config, url).then((data) => {
    Component.setState(
      Object.assign(Component.state, {
        cards: data,
      })
    );
  });
};

export const loadMoreActivity = (
  config: Config,
  url: string,
  cards: IFCardActivity[],
  Component: React.Component
) => {
  const FetchAPI = new FetchMyTrack();

  FetchAPI.getFilteredPage(config, url).then((data) => {
    Component.setState(
      Object.assign({}, Component.state, {
        cards: cards.concat(data),
        countNewCards: data.length,
      })
    );
  });
};

export const updateConfig = (key: string, item: string, Component: ComponentState, masAges: number[][]) => {
  let itemAges: number[] = [];

  if (key == apiFields.ages) {
    itemAges = masAges[parseInt(item)];
  }

  const newConfig = {
    page: Component.state.config.page,
    [apiFields.trackID]: key == apiFields.tracks ? item : Component.state.config.track_id,
    [apiFields.wayID]: key == apiFields.ways ? item : Component.state.config.way_id,
    fresh: Component.state.config.fresh,
    [apiFields.typeID]: key == apiFields.type ? item : Component.state.config.type_id,
    [apiFields.organizationID]:
      key == apiFields.organizations ? item : Component.state.config.organization_id,
    [apiFields.ages]: key == apiFields.ages ? itemAges : Component.state.config.ages,
    [apiFields.price]: key == apiFields.price ? item : Component.state.config.price,
    [apiFields.geo]: key == apiFields.geo ? item : Component.state.config.geo_id,
    [apiFields.cityID]: key == apiFields.cities ? item : Component.state.config.city_id,

    [apiFields.languages]: key == apiFields.languages ? item : Component.state.config.languages,
    [apiFields.interests]: key == apiFields.interests ? item : Component.state.config.interests,
    [apiFields.techSkills]: key == apiFields.techSkills ? item : Component.state.config.tech_skills,
    [apiFields.gender]: key == apiFields.gender ? item : Component.state.config.gender,
    [apiFields.needle]: key == apiFields.needle ? item : Component.state.config.needle,
  };

  if (key == apiFields.ways) {
    delete newConfig[apiFields.trackID];
  }

  const promise = new Promise((resolve) => {
    Component.setState(
      Object.assign({}, Component.state, {
        config: newConfig,
      })
    );
    resolve(true);
  });
  promise.then(() => {});
};

export const updateConfigNumbers = (id: string, item: number, Component: ComponentState) => {
  const promise = new Promise((resolve) => {
    Component.setState(
      Object.assign({}, Component.state, {
        config: {
          page: id == apiFields.page ? item : Component.state.config.page,
          fresh: id == apiFields.fresh ? item : Component.state.config.fresh,
          geo_id: id == apiFields.geo ? item : Component.props.geo,
          [apiFields.trackID]: Component.state.config.track_id,
          [apiFields.wayID]: Component.state.config.way_id,
          [apiFields.typeID]: Component.state.config.type_id,
          [apiFields.organizationID]: Component.state.config.organization_id,
          [apiFields.ages]: Component.state.config.ages,
          [apiFields.price]: Component.state.config.price,
          [apiFields.cityID]: Component.state.config.city_id,
        },
      })
    );
    resolve(true);
  });
  promise.then(() => {});
};

export const filteredPage = (Component: ComponentState, url: string) => {
  const FetchAPI = new FetchMyTrack();

  const promise = new Promise((resolve) => {
    updateConfigNumbers(apiFields.page, 0, Component);
    resolve(true);
  });

  promise.then(() => {
    FetchAPI.getFilteredPage(Component.state.config, url).then((data) => {
      Component.setState(
        Object.assign({}, Component.state, {
          cards: data,
          countNewCards: data.length,
        })
      );
    });
  });
};

export const filteredPageMore = (url: string, Component: ComponentState) => {
  const promise = new Promise((resolve) => {
    updateConfigNumbers(apiFields.page, Component.state.config.page + 1, Component);
    resolve(true);
  });

  promise.then(() => {
    loadMoreActivity(Component.state.config, url, Component.state.cards, Component);
  });
};

export const updateFiltersTracks = (item: IFFiltersItem[], Component: ComponentState) => {
  const promise = new Promise((resolve) => {
    Component.setState(
      Object.assign({}, Component.state, {
        filters: Object.assign({}, Component.state.filters, {
          [apiFields.tracks]: item,
        }),
      })
    );
    resolve(true);
  });
  promise.then(() => {});
};

export const updateAllConfig = (defaultFresh: number, Component: ComponentState) => {
  const newState = {
    fresh: defaultFresh,
    page: 0,
    geo_id: Component.state.config.geo_id,
    needle: '',
  };

  const promise = new Promise((resolve) => {
    Component.setState(
      Object.assign({}, Component.state, {
        config: newState,
      })
    );
    resolve(true);
  });

  promise.then(() => {
    console.log(Component.state.config);
  });
};
