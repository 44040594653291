import * as React from 'react';
import { Link } from 'react-router-dom';
import { IFPeople } from '../../global/interfaces';
import { baseURL } from '../../global/vars';

class Mentor extends React.Component<IFPeople> {
  render() {
    const { name, surname, avatar, text, id } = this.props;

    return (
      <Link to={`/profile/mentor/${id}`} className="mentor">
        <div className="img">
          <div style={{ backgroundImage: `url(${baseURL}/img/bg/mentor_bg.jpg)` }}></div>
        </div>
        <div className="ava" style={{ backgroundImage: `url(${baseURL}/storage/${avatar})` }}></div>
        <div className="name">
          <span>
            {name}
            <br />
            {surname}
          </span>
        </div>
        <div className="deskr">
          <span>{text}</span>
        </div>
      </Link>
    );
  }
}

export default Mentor;
