import * as React from 'react';

import './WhoCooler.scss';

import { IFWhoCooler } from '../../../global/interfaces';
import { baseURL } from '../../../global/vars';

interface Props {
  ladder: IFWhoCooler[];
}

class WhoCooler extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { ladder } = this.props;

    return (
      <div className="card who-cooler">
        <div className="page_title">
          <h4>Кто круче</h4>
        </div>
        <div className="content-card">
          <div className="table">
            <div className="table_head">
              <div>
                <span>Пользователь</span>
              </div>
              <div>
                <span>Сколько раз «рулил» ↓</span>
              </div>
              <div>
                <span>Число шагов в траектории</span>
              </div>
              <div>
                <span>Всего баллов</span>
              </div>
            </div>

            {ladder.map((item, i) => (
              <div className="table_row" key={`row${i}`}>
                <div>
                  <a href={`/profile/user/${item.id}`}>
                    <div
                      className="ava"
                      style={{ backgroundImage: `url(${baseURL}/storage/${item.avatar})` }}
                    ></div>
                  </a>
                  <a href={`/profile/user/${item.id}`}>
                    <span className="who-cooler__name">
                      {item.name} {item.surname}
                    </span>
                  </a>
                </div>
                <div>
                  <span>{item.ladder_count}</span>
                </div>
                <div>
                  <span>{item.steps}</span>
                </div>
                <div>
                  <span>{item.score}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default WhoCooler;
