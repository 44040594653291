import * as React from 'react';
import Slider from 'react-slick';

import './Gallery.scss';

import { baseURL } from '../../../global/vars';

interface Props {
  type: string;
  gallery: string[];
}

class Gallery extends React.Component<Props> {
  render() {
    const { gallery, type } = this.props;
    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 3,
      className: 'slider-gallery',
      arrows: false,
    };

    return (
      <div>
        <h3 className="mt-40">Галерея</h3>
        <Slider {...settings}>
          {gallery.map((image, i) => (
            <div key={`gallery${i}`}>
              <div
                className="slider-gallery__image"
                style={
                  type
                    ? {
                        backgroundImage: `url(${baseURL}/storage/${type}ActivityGallery/${image})`,
                      }
                    : {}
                }
              ></div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default Gallery;
