import * as React from 'react';

interface Props {
  searchPlaceholder?: string;
  search?: any;
  needle?: string;
}
interface State {
  value: string;
}
class Search extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { value: this.props.needle ? this.props.needle : '' };
  }

  render() {
    const { searchPlaceholder, search } = this.props;
    return (
      <form
        action=""
        className="find_form mb-24"
        onSubmit={(e) => {
          e.preventDefault();
          search ? search(this.state.value) : null;
        }}
      >
        <div className="input find_input">
          <input
            type="text"
            name="find"
            placeholder={`Поиск ${searchPlaceholder ? searchPlaceholder : ''}`}
            value={this.state.value}
            onChange={(e) => {
              this.handleChange(e);
            }}
          />
          <input type="submit" name="send" />
        </div>
      </form>
    );
  }

  componentDidUpdate(prevP: Props) {
    if (prevP != this.props) {
      this.setState({
        value: this.props.needle ? this.props.needle : '',
      });
    }
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: e.target.value });
  }
}

export default Search;
