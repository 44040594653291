import * as React from 'react';
import CardActivity from '../../components/CardActivity/CardActivity';
import RightProfile from '../../components/RightProfile/RightProfile';
import { IFCardActivity, IFImages, IFPeople } from '../../global/interfaces';
import { typeOnline } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {
  profile: IFPeople;
}

interface State {
  cardsRec: {
    dateCreated: string;
    id: number;
    image: IFImages;
    name: string;
    type: string;
    video: string | null;
  }[];
}

class Recommendations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cardsRec: [
        {
          dateCreated: '0000-00-00',
          id: 0,
          image: { original: 'courseActivityCard/courseCard628_1.jpg' },
          name: 'Наименование',
          type: '',
          video: null,
        },
      ],
    };
  }

  render() {
    const { cardsRec } = this.state;
    const { profile } = this.props;

    const cards: IFCardActivity[] = [];
    cardsRec.forEach((item) => {
      cards.push({
        city_name: null,
        course_count: 0,
        date: item.dateCreated,
        id: item.id,
        image: item.image,
        is_online: 1,
        name: item.name,
        organization: '',
        price: 0,
        status: null,
        student_count: 0,
      });
    });

    return (
      <React.Fragment>
        <div className="page right_block">
          <div className="content">
            <div className="page_title">
              <h4>Заходи, учавствуй и получай свои первые баллы</h4>
              <a href="#" className="print_btn">
                Распечатать индивидуальный план развития
              </a>
            </div>

            <div className="table">
              <div className="row">
                <div className="col-12 table-head">
                  <div className="row row-recommend">
                    <div className="col-4">
                      <span>Название мероприятия</span>
                    </div>
                    <div className="col-3">
                      <span>Рекомендует</span>
                    </div>
                    <div className="col-5">
                      <span>Комментарий</span>
                    </div>
                  </div>
                </div>

                <div className="col-12 table-row">
                  <div className="row row-recommend">
                    <div className="col-4">
                      <a href="#">Сессия по реализации национальных проектов на территории Томской области</a>
                    </div>
                    <div className="col-3">
                      <span>Анастасия Вольнорудова</span>
                    </div>
                    <div className="col-4">
                      <span>Рекомендую посетить это мероприятие, думаю тебе будет интересно</span>
                    </div>
                    <div className="col-1">
                      <span className="del"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page_title mt-40">
              <h4>Рекомендации на основе вашего профиля</h4>
            </div>

            {cards.length != 0 ? (
              <div className="news">
                <div className="news_list news_list_switcher news__list-grid">
                  {cards.map((item, i) => (
                    <CardActivity
                      pageName="course"
                      data={item}
                      tagLine={typeOnline[item.is_online]}
                      key={`card${i}`}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <span>
                Персональные рекомендации пока недоступны. Чтобы открыть рекомендации, заработай свои первые
                баллы в челленджах, курсах, проектах или событиях.
              </span>
            )}
          </div>
        </div>
        <RightProfile profile={profile} />
      </React.Fragment>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.getRecommend().then((data) => {
      this.setState({
        cardsRec: data.recommendations,
      });
    });
  }
}

export default Recommendations;
