import * as React from 'react';

interface Props {
  id?: number;
  title?: string;
  description?: string;
}

class DetailTrackNews extends React.Component<Props> {
  render() {
    const { id, title, description } = this.props;
    return (
      <div className="page">
        <div className="page__container">
          <div className="page__content-full">
            <nav className="nav_blue">
              <div className="left">
                <a href={`/track/${id}`}>Назад к треку</a>
              </div>
            </nav>

            <div id="module_article" className="content_8col">
              <div className="content">
                <div className="news_page">
                  <div className="page_title">
                    <h1>{title}</h1>
                  </div>
                  {description ? (
                    <div className="text" dangerouslySetInnerHTML={{ __html: description }}></div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailTrackNews;
