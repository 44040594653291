import React from 'react';
import Select from 'react-select';

import './Settings.scss';

import { IFAlert, optionSelect } from '../../global/interfaces';
import { baseURL, regions } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';
import Input from '../../components/Input/Input';
import Alert from '../../components/Alert/Alert';

interface Props {}
interface State {
  avatar: string;
  city: string;
  date: string | null;
  gender: number | null;
  geoID: number;
  email: string;
  name: string;
  school: string;
  statusMessage: string | null;
  surname: string;
  regionsSelect: optionSelect[];

  alert: IFAlert | null;
}

class Settings extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  refFormSettings: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      avatar: '',
      city: '',
      date: null,
      gender: null,
      geoID: 85,
      email: '',
      name: '',
      school: '',
      statusMessage: null,
      surname: '',
      regionsSelect: [],

      alert: null,
    };
    this.refFormSettings = React.createRef();
  }
  render() {
    const {
      avatar,
      city,
      date,
      gender,
      geoID,
      email,
      name,
      school,
      statusMessage,
      surname,
      regionsSelect,
      alert,
    } = this.state;

    return (
      <div className="page page_settings settings">
        <div className="page__container">
          <div className="page__content-min">
            <div className="page_title">
              <h3>Настройки</h3>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.updateSettings();
              }}
              ref={this.refFormSettings}
            >
              <div className="settings__ava">
                <div className="new_ava">
                  <div className="upload_list"></div>

                  <div
                    className="current_ava"
                    style={{ backgroundImage: `url(${baseURL}/storage/${avatar})` }}
                  ></div>

                  <div className="add_new_ava_input">
                    <label className="modal"></label>
                  </div>
                </div>

                <div className="input mt-24">
                  <label htmlFor="status">Статус</label>
                  <input
                    type="text"
                    name="status"
                    placeholder="Введите ваш статус"
                    defaultValue={statusMessage ? statusMessage : ''}
                  ></input>
                </div>
              </div>

              <div className="page_title mt-24">
                <h4>Общие сведения</h4>
              </div>

              <div className="page__column-2">
                <Input
                  type="text"
                  name="name"
                  placeholder="Имя"
                  labelFor="userName"
                  labelText="Имя"
                  value={name}
                />
                <Input
                  type="text"
                  name="surname"
                  placeholder="Фамилия"
                  labelFor="surname"
                  labelText="Фамилия"
                  value={surname}
                />
              </div>

              {gender != null ? (
                <div className="input">
                  <label className="mb-16">Пол</label>
                  <div className="input-radio-block">
                    <div className="input radio">
                      <input
                        type="radio"
                        id="sex_m"
                        name="gender"
                        value="0"
                        defaultChecked={gender == 0}
                      ></input>
                      <label htmlFor="sex_m">Мужской</label>
                    </div>
                    <div className="input radio">
                      <input
                        type="radio"
                        id="sex_w"
                        name="gender"
                        value="1"
                        defaultChecked={gender == 1}
                      ></input>
                      <label htmlFor="sex_w">Женский</label>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="page_title mt-24">
                <h4>Контактная информация</h4>
              </div>

              <Input
                type="text"
                name="email"
                placeholder="Введите вашу почту"
                labelFor="email"
                labelText="E-mail"
                value={email}
              />

              {regionsSelect.length ? (
                <Select
                  options={regionsSelect}
                  placeholder={`Ваш регион`}
                  isSearchable={false}
                  className="select-my"
                  classNamePrefix="select-my"
                  name="geoID"
                  defaultValue={regionsSelect[geoID]}
                />
              ) : null}

              <div className="page__column-2">
                <Input
                  type="text"
                  name="city"
                  placeholder="Введите ваш город"
                  labelFor="city"
                  labelText="Город"
                  value={city}
                />
                <Input
                  type="text"
                  name="school"
                  placeholder="Введите вашу школу"
                  labelFor="school"
                  labelText="Школа"
                  value={school}
                />
              </div>

              <div className="page_title mt-24">
                <h4>Изменение пароля</h4>
              </div>

              <Input
                type="password"
                name="pass"
                placeholder="Ваш пароль"
                labelFor="pass"
                labelText="Текущий пароль"
              />

              <div className="page__column-2">
                <Input
                  type="password"
                  name="newPass"
                  placeholder="Новый пароль"
                  labelFor="newPass"
                  labelText="Новый пароль"
                />
                <Input
                  type="password"
                  name="repNewPass"
                  placeholder="Повторите новый пароль"
                  labelFor="repNewPass"
                  labelText="Повторите новый пароль"
                />
              </div>

              <div className="btn_block left">
                <input type="submit" className="blue_btn" value="Сохранить изменения"></input>
              </div>
            </form>
          </div>
        </div>

        {alert ? <Alert title={alert.title} text={alert.text} component={this} /> : null}
      </div>
    );
  }

  componentDidMount() {
    this.FetchAPI.getSettings().then((data) => {
      const regionsSelect: optionSelect[] = [];
      Object.keys(regions).forEach((key: string) => {
        regionsSelect.push({ value: parseInt(key), label: regions[parseInt(key)] });
      });

      this.setState({
        avatar: data.avatar,
        city: data.city,
        date: data.date,
        gender: data.gender,
        geoID: data.geo_id,
        email: data.mail,
        name: data.name,
        school: data.school,
        statusMessage: data.status_message,
        surname: data.surname,
        regionsSelect: regionsSelect,
      });
    });
  }

  updateSettings() {
    const form = this.refFormSettings.current;

    if (form) {
      const name: HTMLInputElement | null = form.querySelector(`input[name='name']`);
      const surname: HTMLInputElement | null = form.querySelector(`input[name='surname']`);
      const email: HTMLInputElement | null = form.querySelector(`input[name='email']`);
      const status: HTMLInputElement | null = form.querySelector(`input[name='status']`);
      const city: HTMLInputElement | null = form.querySelector(`input[name='city']`);
      const school: HTMLInputElement | null = form.querySelector(`input[name='school']`);
      const pass: HTMLInputElement | null = form.querySelector(`input[name='pass']`);
      const passNew: HTMLInputElement | null = form.querySelector(`input[name='newPass']`);
      const passNewRep: HTMLInputElement | null = form.querySelector(`input[name='repNewPass']`);
      const region: HTMLInputElement | null = form.querySelector(`input[name='geoID']`);
      const gender: HTMLInputElement | null = form.querySelector(`input[name='gender']:checked`);

      const settings = {
        status: status ? status.value : '',
        name: name ? name.value : '',
        surname: surname ? surname.value : '',
        gender: gender ? gender.value : null,
        mail: email ? email.value : '',
        geo_id: region ? region.value : 85,
        city: city ? city.value : '',
        school: school ? school.value : '',
        pass: pass ? pass.value : '',
        new_pass: passNew ? passNew.value : '',
        rep_new_pass: passNewRep ? passNewRep.value : '',
      };

      this.FetchAPI.updateSettings(settings).then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            alert: {
              title: data.status == 'success' ? 'Все хорошо!' : 'Ошибка',
              text: 'Изменения профиля загружены успешно',
            },
          })
        );
      });
    }
  }
}

export default Settings;
