import * as React from 'react';

import './Speedometers.scss';

import { IFSpeedometers } from '../../../global/interfaces';
import SpeedItem from '../SpeedItem/SpeedItem';
import TabsMini from '../../TabsMini/TabsMini';

const speedometer1 = require('./img/speedometer-1.svg').default;
const speedometer2 = require('./img/speedometer-2.svg').default;
const speedometer3 = require('./img/speedometer-3.svg').default;

interface Props {
  activity: {
    today: IFSpeedometers;
    overall: IFSpeedometers;
  };
}
interface State {
  active: number;
}

class Speedometers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  render() {
    const {
      activity: { today, overall },
    } = this.props;
    const { active } = this.state;

    const speedometers = [
      {
        count: active == 0 ? `${overall.subscribe.count}` : `+${today.subscribe.count}`,
        speedometerImage: speedometer1,
        text: 'Записались на образовательную активность',
        speed: this.calcSpeed(active == 0 ? overall.subscribe : today.subscribe),
      },
      {
        count: active == 0 ? `${overall.answer.count}` : `+${today.answer.count}`,
        speedometerImage: speedometer2,
        text: 'Опубликовали работу',
        speed: this.calcSpeed(active == 0 ? overall.answer : today.answer),
      },
      {
        count: active == 0 ? `${overall.score.count}` : `+${today.score.count}`,
        speedometerImage: speedometer3,
        text: 'Начали строить траекторию',
        speed: this.calcSpeed(active == 0 ? overall.score : today.score),
      },
    ];

    return (
      <div className="card speedometers-card">
        <div className="page_title">
          <h4>Активность пользователей на платформе</h4>
        </div>
        <div className="content-card card-speedometers">
          <TabsMini
            tabs={['За весь период', 'Сегодня']}
            active={this.state.active}
            changedTabs={this.changedTabs}
          />

          <div className="switch_content">
            <div className="speedometers active">
              <div>
                {speedometers.map((item, i) => (
                  <SpeedItem
                    count={item.count}
                    speedometerImage={item.speedometerImage}
                    text={item.text}
                    speed={item.speed}
                    key={`speed${i}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  changedTabs = (i: number) => {
    this.setState({
      active: i,
    });
  };

  calcSpeed(field: { count: number; max: number }) {
    const percent = field.count / field.max;
    const speed = percent * 180;

    return speed;
  }
}

export default Speedometers;
