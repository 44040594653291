import * as React from 'react';

import './CompetenceDevelopment.scss';

import Point from '../../Point/Point';
import { IFCompetence, IFCompetencePoints } from '../../../global/interfaces';
import { ArrDeclension, ArrDeclensionCases, Declension } from '../../../services/Declension';

interface Props {
  type: string;
  list: {
    type: string;
    title: string;
    track: string;
    competencies: {
      name: string;
      points: IFCompetencePoints[];
    }[];
  }[];
}

interface State {}

class CompetenceDevelopment extends React.Component<Props, State> {
  render() {
    const { list, type } = this.props;

    return (
      <div className="detail-activity__competence-development mt-60">
        <div className="page_title">
          <h3>Развитие компетенций</h3>
        </div>

        <div className="text mt-24 mb-24">
          {type == 'course' ? (
            <p>
              Пройдя {Declension(1, ArrDeclension(type))}, ты заработаешь баллы по следующим компетенциям:
            </p>
          ) : (
            <p>Участвуй в {ArrDeclensionCases(type)[5]} и зарабатывай баллы по компетенциям:</p>
          )}
        </div>

        {list.map(({ competencies, track }, i) => (
          <div className="detail-activity__competence-track" key={`list${i}`}>
            <h4>{`Трек "${track}"`}</h4>
            {competencies.map(({ name, points }, idx) => (
              <React.Fragment key={`comp${idx}`}>
                <p>{`Компетенция "${name}"`}</p>

                <div className="detail-activity__competences-list">
                  {points.map((point, index) => (
                    <Point card={point} checkMark={false} key={`point${index}`} />
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default CompetenceDevelopment;
