import FetchMyTrack from './FetchMyTrack';

interface searchConfig {
  value: string;
  url: string;
  Component: React.Component;
  geo: number;
  fresh: number;
}
export default class Filter {
  FetchAPI = new FetchMyTrack();

  search = ({ value, url, Component, geo, fresh }: searchConfig) => {
    this.FetchAPI.getFilteredPage(
      {
        fresh: fresh,
        like: value,
        geo_id: geo,
      },
      url
    ).then((data) => {
      Component.setState(
        Object.assign(Component.state, {
          cards: data,
        })
      );
    });
  };
}
