import * as React from 'react';

import './CourseProgramm.scss';
import { IFCourseProgramm } from '../../../global/interfaces';
import { ArrDeclension, Declension } from '../../../services/Declension';
import { getTypeAndIDPage } from '../../../global/functions';
import { Link } from 'react-router-dom';

interface Props {
  courseProgramm: IFCourseProgramm[];
}

class CourseProgramm extends React.Component<Props> {
  render() {
    const { courseProgramm } = this.props;
    const { idPage, typePage } = getTypeAndIDPage();
    return (
      <div className="course-programm row mt-60">
        <div className="col-8">
          <div className="page_title">
            <h3>Программа {Declension(2, ArrDeclension(typePage))}</h3>
          </div>

          <div className="course-programm__table table">
            <div className="row">
              <div className="col-12 table-head">
                <div className="row">
                  <div>
                    <span>Тема</span>
                  </div>
                  <div>
                    <span>Задания</span>
                  </div>
                </div>
              </div>
              {courseProgramm.map((item, i) => (
                <div className="col-12 table-row" key={`row${i}`}>
                  <div className="row">
                    <div>
                      <span>
                        <Link to={`/${typePage}/${idPage}/module/${item.id}`} state={{ numberTheme: i }}>
                          {item.name}
                        </Link>
                      </span>
                    </div>
                    <div>
                      <span>{item.taskCount}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CourseProgramm;
