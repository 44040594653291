import * as React from 'react';
import { IFChatMenu } from '../../../global/interfaces';

interface Props {
  menu: IFChatMenu[];
}

interface State {
  showMenu: boolean;
}

class MenuChat extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }
  render() {
    const { menu } = this.props;
    const { showMenu } = this.state;

    return (
      <React.Fragment>
        {menu.length ? (
          <div
            className="title_menu"
            onClick={() => {
              this.showMenu();
            }}
          ></div>
        ) : null}

        {showMenu ? (
          <div className="title_menu_content">
            {menu.map(({ href, clickFunction, arg, icon, name }, i) => (
              <a
                href={href}
                className="open_modal"
                onClick={() => clickFunction(arg ? arg : null)}
                key={`link${i}`}
              >
                <img src={icon} alt=""></img>
                <span>{name}</span>
              </a>
            ))}
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  showMenu() {
    this.setState(
      Object.assign({}, this.state, {
        showMenu: !this.state.showMenu,
      })
    );
  }
}

export default MenuChat;
