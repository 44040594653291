import React from 'react';

import './Table.scss';

import { IFTasks } from '../../global/interfaces';

interface Props {
  tasks: IFTasks[];
  heads: string[];
  type: string;
}

class Table extends React.Component<Props> {
  render() {
    const { tasks, heads, type } = this.props;

    return (
      <div className="table">
        <div className="row activities_tasks">
          <div className="col-12 table-head">
            <div className="row">
              {heads.map((item, i) => (
                <span key={`head${i}`}>{item}</span>
              ))}
            </div>
          </div>

          {tasks.map(({ project_name, project_id, module_id, task_id, task_name, status }, i) => (
            <div className="col-12 table-row" key={`row${i}`}>
              <div className="row">
                <span>{project_name}</span>
                <a
                  href={`/${type}/${project_id}${
                    type == 'course' ? `/module/${module_id}/task/${task_id}` : `/tasks`
                  }`}
                >
                  {task_name}
                </a>
                <div>
                  <span className="status red">{status}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Table;
