import * as React from 'react';
import { IFFiltersItem } from '../../../global/interfaces';
import { ContextModal } from '../../../services/Contexts';
import ContentModalListTechSkills from '../ContentModalListTechSkills/ContentModalListTechSkills';
import Edit from '../Edit/Edit';

interface Props {
  skills: IFFiltersItem[];
  skillsAll: IFFiltersItem[];
  updateStateProfile?: any;
  edit: boolean;
  limitItem?: number;
}
class TechnicalSkills extends React.Component<Props> {
  render() {
    const { skills, skillsAll, updateStateProfile, edit, limitItem = 7 } = this.props;

    return (
      <div className="col-12 mt-40">
        <div className="page_title">
          <h4>Технические навыки</h4>
          {edit ? (
            <Edit
              title="Технические навыки"
              ComponentModal={
                <ContentModalListTechSkills
                  skills={this.props.skills}
                  allListSkills={skillsAll}
                  updateStateProfile={updateStateProfile}
                />
              }
              width="min"
            />
          ) : null}
        </div>
        <div className="interests_list">
          {skills.map((item, i) => (i < limitItem ? <span key={`skill${item.id}`}>{item.name}</span> : null))}
        </div>

        {skills.length == 0 ? <p className="fs14w400">Пока не добавлен ни один навык.</p> : null}

        {skills.length > limitItem ? (
          <ContextModal.Consumer>
            {({ showModal }) => {
              return (
                <a
                  href="#all"
                  className="link_border"
                  onClick={() => {
                    this.showSkills(showModal);
                  }}
                >
                  Смотреть все
                </a>
              );
            }}
          </ContextModal.Consumer>
        ) : null}
      </div>
    );
  }

  showSkills(showModal: any) {
    showModal({
      title: 'Технические навыки',
      content: <ContentModalListTechSkills skills={this.props.skills} />,
      width: 'min',
    });
  }
}

export default TechnicalSkills;
