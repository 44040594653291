import * as React from 'react';

class SmallScreen extends React.Component {
  render() {
    return (
      <div id="small_screen">
        <div className="content">
          <div className="big">У вас маленький экран</div>
        </div>
      </div>
    );
  }
}

export default SmallScreen;
