import * as React from 'react';

import { clickTabs } from '../../global/functions';

interface Props {
  tabs: {
    text: string;
    fresh: number;
  }[];
  updateFilterTabs: any;
}

interface State {
  active: number;
}

class FilterTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  render() {
    const { tabs } = this.props;
    const { active } = this.state;

    return (
      <div className="sort_switcher mb-24">
        {tabs.map((item, i) => (
          <span
            className={`${active == i ? 'active' : ''}`}
            key={`filterTabs${i}`}
            onClick={() => {
              clickTabs(this, i);
              this.update(item.fresh);
            }}
          >
            {item.text}
          </span>
        ))}
      </div>
    );
  }

  update(i: number) {
    this.props.updateFilterTabs(i);
  }
}

export default FilterTabs;
