import * as React from 'react';

import './Certificate.scss';

interface Props {
  name: string;
  buy: any;
}

class Certificate extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { name, buy } = this.props;

    return (
      <div className="certificate">
        <div className="certificate__text">
          <h3 className="mt-24">Сертификат</h3>
          <p className="mt-24">
            При успешном прохождении квалификации «<b>{name}</b>» ты получишь электронный сертификат.
          </p>
          <div className="mt-24 mb-24">
            <a href="#purchase-options_modal" className="open_modal blue_btn" onClick={() => buy()}>
              Варианты покупки
            </a>
          </div>
        </div>
        <div className="certificate__image">
          <img src={require('./img/cert.jpeg')} alt="Сертификат" />
        </div>
      </div>
    );
  }
}

export default Certificate;
