// export const baseURL = 'https://mytrack.ru';
export const baseURL = '//dev123.tintel.ru';

export const regions: { [key: number]: string } = {
  0: 'Республика Адыгея',
  1: 'Республика Алтай',
  2: 'Алтайский край',
  3: 'Амурская область',
  4: 'Архангельская область',
  5: 'Астраханская область',
  6: 'Республика Башкортостан',
  7: 'Белгородская область',
  8: 'Брянская область',
  9: 'Республика Бурятия',
  10: 'Владимирская область',
  11: 'Волгоградская область',
  12: 'Вологодская область',
  13: 'Воронежская область',
  14: 'Республика Дагестан',
  15: 'Еврейская автономная область',
  16: 'Забайкальский край',
  17: 'Ивановская область',
  18: 'Республика Ингушетия',
  19: 'Иркутская область',
  20: 'Кабардино-Балкарская республика',
  21: 'Калининградская область',
  22: 'Республика Калмыкия',
  23: 'Калужская область',
  24: 'Камчатский край',
  25: 'Карачаево-Черкесская республика',
  26: 'Республика Карелия',
  27: 'Кемеровская область',
  28: 'Кировская область',
  29: 'Республика Коми',
  30: 'Костромская область',
  31: 'Краснодарский край',
  32: 'Красноярский край',
  33: 'Республика Крым',
  34: 'Курганская область',
  35: 'Курская область',
  36: 'Ленинградская область',
  37: 'Липецкая область',
  38: 'Магаданская область',
  39: 'Республика Марий Эл',
  40: 'Республика Мордовия',
  41: 'Москва',
  42: 'Московская область',
  43: 'Мурманская область',
  44: 'Ненецкий автономный округ',
  45: 'Нижегородская область',
  46: 'Новгородская область',
  47: 'Новосибирская область',
  48: 'Омская область',
  49: 'Оренбургская область',
  50: 'Орловская область',
  51: 'Пензенская область',
  52: 'Пермский край',
  53: 'Приморский край',
  54: 'Псковская область',
  55: 'Ростовская область',
  56: 'Рязанская область',
  57: 'Самарская область',
  58: 'Санкт-Петербург',
  59: 'Саратовская область',
  60: 'Сахалинская область',
  61: 'Свердловская область',
  62: 'Севастополь',
  63: 'Республика Северная Осетия',
  64: 'Смоленская область',
  65: 'Ставропольский край',
  66: 'Тамбовская область',
  67: 'Республика Татарстан',
  68: 'Тверская область',
  69: 'Томская область',
  70: 'Тульская область',
  71: 'Республика Тыва',
  72: 'Тюменская область',
  73: 'Удмуртская республика',
  74: 'Ульяновская область',
  75: 'Хабаровский край',
  76: 'Республика Хакасия',
  77: 'Ханты-Мансийский автономный округ',
  78: 'Челябинская область',
  79: 'Чеченская республика',
  80: 'Чувашская республика',
  81: 'Чукотский автономный округ',
  82: 'Республика Саха',
  83: 'Ямало-Ненецкий автономный округ',
  84: 'Ярославская область',
  85: 'Вся Россия',
};

export enum apiFields {
  student = 'student',
  guest = 'guest',
  ages = 'ages',
  cities = 'cities',
  price = 'price',
  health = 'health',
  page = 'page',
  trackID = 'track_id',
  tracks = 'tracks',
  wayID = 'way_id',
  ways = 'ways',
  fresh = 'fresh',
  type = 'type',
  typeID = 'type_id',
  organizationID = 'organization_id',
  organizations = 'organizations',
  geo = 'geo_id',
  cityID = 'city_id',
  languages = 'languages',
  interests = 'interests',
  techSkills = 'tech_skills',
  gender = 'gender',
  needle = 'needle',
}

export enum apiUrl {
  navigator = 'organization',
  courses = 'courses',
  project = 'project',
  challenge = 'challenge',
  event = 'event',
  people = 'people',
}

export enum urlPages {
  navigator = 'navigator',
}
export enum varsString {
  student = 'student',
  mentor = 'mentor',
  user = 'user',
}

export const activityNames = [
  { name: 'all', text: 'Все' },
  { name: 'course', text: 'Курс' },
  { name: 'project', text: 'Проект' },
  { name: 'challenge', text: 'Челлендж' },
  { name: 'event', text: 'Событие' },
  { name: 'qualifications', text: 'Квалификация' },
];

export const statuses: { [key: number]: { text: string; class: string } } = {
  0: { text: 'Открыт набор', class: '' },
  1: { text: 'Набор закрыт', class: 'gray' },
  2: { text: 'Завершено', class: 'gray' },
  3: { text: 'Участник', class: 'green' },
  4: { text: 'Прием работ', class: 'green' },
  5: { text: 'Заявка на рассмотрении', class: 'orange' },
  6: { text: 'Выполнено', class: 'turquoise' },
  7: { text: 'Победитель', class: 'turquoise' },
};

export const typeOnline: { [key: number]: string } = {
  0: 'Оффлайн',
  1: 'Онлайн',
};

export enum main {
  url = 'challenges',
  login = 'login',
  register = 'register',
}
export enum count {
  cardsToPage = 18,
  peopleToPage = 90,
  worksToPage = 36,
}

declare global {
  interface Window {
    typeLogin: string;
  }
}

export const defaultConfig = {
  people: {
    page: 0,
    fresh: 0,
  },
  all: {
    page: 0,
    fresh: 1,
  },
};

export const competenciesMaps = {
  text: [
    'На старте',
    'Начало движения',
    'Набор скорости',
    'Крутой поворот',
    'Объезд препятствий',
    'Финишная прямая',
  ],
  position: [1, 2, 3, 4, 5, 6],
  points: [
    [0, 0],
    [1, 20],
    [21, 51],
    [52, 100],
    [101, 150],
    [151, 99999999999],
  ],
};

export const timezone = 7;

export enum popups {
  notifications = 'notifications',
}
