import * as React from 'react';
import { getTypeAndIDPage } from '../../global/functions';
import FetchMyTrack from '../../services/FetchMyTrack';
import { ContextApp } from '../../services/Contexts';

interface Props {
  subComment?: boolean;
  closedFormsSubComment?: any;
  idParentComment?: number;
  addComment: any;
}

interface State {
  focus: boolean;
  characterCount: number;
  text: string;
}

class FormComment extends React.Component<Props, State> {
  refFormComment: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);
    this.refFormComment = React.createRef();
    this.state = {
      focus: false,
      characterCount: 500,
      text: '',
    };
  }

  render() {
    const { subComment, closedFormsSubComment } = this.props;
    const { focus, characterCount } = this.state;
    const { refFormComment } = this;

    return (
      <ContextApp.Consumer>
        {({ profile }) => {
          return (
            <div className="shadow-box h-auto form-comment">
              <form
                className="form-comment__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.sendComment();
                }}
                ref={refFormComment}
              >
                <div
                  className="ava"
                  style={{
                    backgroundImage: `url('https://mytrack.ru/storage/studentAvatars/28_avatar.jpg')`,
                  }}
                ></div>
                <div className="form">
                  <div className="form-comment__text-block">
                    <textarea
                      name="comment"
                      placeholder="Оставить комментарий"
                      maxLength={500}
                      onFocus={() => this.setState(Object.assign({}, this.state, { focus: true }))}
                      // onBlur={() => this.setState(Object.assign({}, this.state, { focus: false }))}
                      onChange={(e) => this.characterCount(e)}
                      required
                    ></textarea>
                    {focus ? (
                      <label className="form-comment__text-label">Осталось символов: {characterCount}</label>
                    ) : null}
                  </div>

                  {focus || subComment ? (
                    <div className="btn_block">
                      {subComment ? (
                        <span className="border_btn big" onClick={() => closedFormsSubComment()}>
                          Отмена
                        </span>
                      ) : null}
                      <input type="submit" className="blue_btn" value="Отправить"></input>
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          );
        }}
      </ContextApp.Consumer>
    );
  }

  characterCount(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState(
      Object.assign({}, this.state, {
        characterCount: 500 - e.target.value.length,
        text: e.target.value,
      })
    );
  }

  sendComment() {
    const FetchAPI = new FetchMyTrack();
    const { idAnswer, typePage } = getTypeAndIDPage();
    const { subComment, idParentComment, closedFormsSubComment } = this.props;

    const idParent = subComment && idParentComment ? idParentComment : 0;
    const typePageforApi = typePage == 'course' ? 'courses' : typePage;

    FetchAPI.sendComment(typePageforApi, idAnswer, idParent, this.state.text).then(async (data) => {
      this.refFormComment.current?.reset();

      await this.props.addComment(
        {
          avatar: data.avatar,
          comment: data.comment,
          date: data.date,
          id: data.id,
          name: data.name,
          parent_id: data.parent_id,
          surname: data.surname,
          type: data.type,
          u_id: data.U_id,
        },
        idParent
      );

      if (subComment) {
        closedFormsSubComment();
      }
    });
  }
}

export default FormComment;
