import * as React from 'react';

import { IFCompetencePoints, IFCompetenciesTracks } from '../../../global/interfaces';
import { ContextModal } from '../../../services/Contexts';
import FetchMyTrack from '../../../services/FetchMyTrack';
import Point from '../../Point/Point';

import ModalIdeasMatrix from '../ModalIdeasMatrix/ModalIdeasMatrix';

import './ModalCompetencies.scss';

interface Props {
  track: IFCompetenciesTracks;
  idxTab: number;
}

interface State {
  activeTab: number;
}

class ModalCompetencies extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: this.props.idxTab,
    };
  }

  render() {
    const { track } = this.props;

    const points: IFCompetencePoints[] = track.competencies[this.state.activeTab].items;

    return (
      <div className="modal-competencies">
        <div className="switch modal_tabs">
          {track.competencies.map((item, i) => (
            <div
              className={this.state.activeTab == i ? 'active' : ''}
              key={`modalTab${i}`}
              onClick={() => {
                this.setTab(i);
              }}
            >
              <span>{item.name}</span>
            </div>
          ))}
        </div>

        <div className="switch_content content">
          <div className="active">
            <div className="competences_list">
              {points?.map((item, i) => (
                <Point card={item} key={`score${i}`} />
              ))}
            </div>
          </div>
        </div>

        <ContextModal.Consumer>
          {({ showModal }) => {
            return (
              <div className="btn_line">
                <button
                  className="border_btn big open_modal"
                  onClick={() => {
                    this.showModalIdeas(showModal);
                  }}
                >
                  Предложить изменения
                </button>
              </div>
            );
          }}
        </ContextModal.Consumer>
      </div>
    );
  }

  setTab(idx: number) {
    this.setState({
      activeTab: idx,
    });
  }

  showModalIdeas(showModal: any) {
    showModal({
      title: `Предложить изменения к матрице компетенций трека`,
      content: <ModalIdeasMatrix />,
      width: 'min',
    });
  }
}

export default ModalCompetencies;
