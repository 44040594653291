import * as React from 'react';
import { Link } from 'react-router-dom';

import './Point.scss';

interface Props {
  card: any;
  checkMark?: boolean;
}

class Point extends React.Component<Props> {
  render() {
    const { card, checkMark = true } = this.props;

    return (
      <Link
        to={{ pathname: `/skillActivities/${card.id}` }}
        className={`competence_points_card ${card.completed ? '' : 'not_active'} ${this.getColor(
          card.score
        )}`}
      >
        <div className="text">
          <span>{card.text}</span>
        </div>
        <div className="points">
          {checkMark ? <div className="select_icon"></div> : null}
          <span>{card.score}</span>
          <span>балл</span>
        </div>
      </Link>
    );
  }

  getColor(idx: number) {
    let color = '';
    if ([1, 11, 12, 13].includes(idx)) {
      color = 'blue';
    }
    if ([2].includes(idx)) {
      color = 'orange';
    }
    if ([3].includes(idx)) {
      color = 'red';
    }
    if ([4, 5, 6].includes(idx)) {
      color = 'yellow';
    }
    if ([7, 8, 9, 10].includes(idx)) {
      color = 'green';
    }
    if ([14, 15, 16].includes(idx)) {
      color = 'dark_blue';
    }

    return color;
  }
}

export default Point;
