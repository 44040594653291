import * as React from 'react';

interface Props {}
// Статичный блок, т.к. не используется в проекте (взят из верстки)
class UserAnalyticFull extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="card full">
          <div className="page_title">
            <h4>Аналитика по пользователям</h4>
          </div>
          <div className="text">
            <p>Добавьте пользователей, чтобы следить за их достижениями.</p>
          </div>
          <div className="content-card">
            <div className="table table-big">
              <div className="table_head">
                <div>
                  <span>Пользователь</span>
                </div>
                <div>
                  <span>Баллов</span>
                </div>
                <div>
                  <span>Треков</span>
                </div>
                <div>
                  <span>Всего работ</span>
                </div>
                <div>
                  <span>Участник активностей</span>
                </div>
                <div>
                  <span></span>
                </div>
                <div>
                  <span></span>
                </div>
                <div>
                  <span></span>
                </div>
              </div>

              <div className="table_row">
                <div>
                  <div className="ava" style={{ backgroundImage: `url('./img/photo/photo_1.jpg')` }}></div>
                  <span>Никита Панов</span>
                </div>
                <div>
                  <span>360</span>
                </div>
                <div>
                  <span>3</span>
                </div>
                <div>
                  <span>95</span>
                </div>
                <div>
                  <span>100</span>
                </div>
                <div>
                  <a href="#">Траектория</a>
                </div>
                <div>
                  <a href="#" className="competencies_btn">
                    <div className="ico">
                      <div></div>
                      <div></div>
                    </div>
                    <span>Компетенции</span>
                  </a>
                </div>
                <div>
                  <span className="del"></span>
                </div>

                <div className="full_info">
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="100"></div>
                    </div>
                  </div>
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="28"></div>
                    </div>
                  </div>
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="9"></div>
                    </div>
                  </div>
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="43"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table_row">
                <div>
                  <div className="ava" style={{ backgroundImage: `url('./img/photo/photo_1.jpg')` }}></div>
                  <span>Никита Панов</span>
                </div>
                <div>
                  <span>360</span>
                </div>
                <div>
                  <span>3</span>
                </div>
                <div>
                  <span>95</span>
                </div>
                <div>
                  <span>100</span>
                </div>
                <div>
                  <a href="#">Траектория</a>
                </div>
                <div>
                  <a href="#" className="competencies_btn">
                    <div className="ico">
                      <div></div>
                      <div></div>
                    </div>
                    <span>Компетенции</span>
                  </a>
                </div>
                <div>
                  <span className="del"></span>
                </div>

                <div className="full_info">
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="100"></div>
                    </div>
                  </div>
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="28"></div>
                    </div>
                  </div>
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="9"></div>
                    </div>
                  </div>
                  <div>
                    <div className="ico" data-tippy-content="Промышленный дизайн" data-tippy-theme="light">
                      <img src="./img/icons/cad.svg"></img>
                    </div>
                    <div className="name">
                      <span>Разработка дизайн-концепции объектов промышленного производства (28/100)</span>
                    </div>
                    <div className="progress">
                      <div data-progress="43"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table_bottom">
                <a href="#create_chat" className="open_modal">
                  <div className="user add_new user_top add_new_users_table">
                    <div>
                      <div className="ava"></div>
                      <div className="name">
                        <span>Добавить пользователей</span>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="#" className="download_excel_btn">
                  <div className="ico">
                    <div></div>
                    <div></div>
                  </div>
                  <span>Скачать Excel</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- "Новый чат" модалка --> */}
        <div id="create_chat" className="modal_div">
          <div className="min">
            <div className="modal_title">
              <h3>Добавить пользователей</h3>
            </div>
            <span className="modal_close">×</span>

            <form action="">
              <div className="content">
                <div className="mentors_list">
                  <div className="page_title mt-0">
                    <h6>
                      Выбранные пользователи (<span id="members_count">3</span>)
                    </h6>
                  </div>

                  <input type="hidden" name="users-list" className="users-list" value=""></input>

                  <div className="top_list add_challenge">
                    <div id="users_list" className="line">
                      <div className="user user_top" data-user_id="1">
                        <div>
                          <div className="ava" style={{ backgroundImage: `url('/img/photo/mentor1.jpg')` }}>
                            <div className="del" onClick={() => {}}></div>
                          </div>
                          <div className="name">
                            <span>Катерина</span>
                            <span>Любер</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="search_users show">
                      <div className="find_form">
                        <div className="input find_input">
                          <input className="input_find" type="text" name="find" placeholder="Поиск"></input>
                          <input type="submit" name="send"></input>
                        </div>
                      </div>

                      <div className="prev">
                        <div className="text">
                          <img src="./img/icons/a-search-80-px.svg" alt=""></img>
                          <span>
                            Чтобы добавить нового учатника в чат, напишите его имя в поле поиска выше
                          </span>
                        </div>
                      </div>

                      <div className="search_users_result">
                        <div id="mentors_list_modal" className="line">
                          <div className="user user_top" data-user_id="1">
                            <div>
                              <div
                                className="ava"
                                style={{ backgroundImage: `url('/img/photo/mentor1.jpg')` }}
                              >
                                <div className="select"></div>
                              </div>
                              <div className="name">
                                <span>Катерина</span>
                                <span>Любер</span>
                              </div>
                            </div>
                          </div>

                          <div className="user user_top" data-user_id="2">
                            <div>
                              <div
                                className="ava"
                                style={{ backgroundImage: `url('/img/photo/mentor2.jpg')` }}
                              >
                                <div className="select"></div>
                              </div>
                              <div className="name">
                                <span>Катерина</span>
                                <span>Любер</span>
                              </div>
                            </div>
                          </div>

                          <div className="user user_top" data-user_id="3">
                            <div>
                              <div
                                className="ava"
                                style={{ backgroundImage: `url('/img/photo/mentor1.jpg')` }}
                              >
                                <div className="select"></div>
                              </div>
                              <div className="name">
                                <span>Катерина</span>
                                <span>Любер</span>
                              </div>
                            </div>
                          </div>

                          <div className="user user_top" data-user_id="4">
                            <div>
                              <div
                                className="ava"
                                style={{ backgroundImage: `url('/img/photo/mentor1.jpg')` }}
                              >
                                <div className="select"></div>
                              </div>
                              <div className="name">
                                <span>Катерина</span>
                                <span>Любер</span>
                              </div>
                            </div>
                          </div>

                          <div className="user user_top" data-user_id="5">
                            <div>
                              <div
                                className="ava"
                                style={{ backgroundImage: `url('/img/photo/mentor2.jpg')` }}
                              >
                                <div className="select"></div>
                              </div>
                              <div className="name">
                                <span>Катерина</span>
                                <span>Любер</span>
                              </div>
                            </div>
                          </div>

                          <div className="user user_top" data-user_id="6">
                            <div>
                              <div
                                className="ava"
                                style={{ backgroundImage: `url('/img/photo/mentor1.jpg')` }}
                              >
                                <div className="select"></div>
                              </div>
                              <div className="name">
                                <span>Катерина</span>
                                <span>Любер</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <input type="hidden" className="users-list"></input>
                    </div>
                  </div>
                </div>

                <div className="btn_line blacklist_buttons">
                  <input type="button" value="Сохранить" className="blue_btn"></input>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserAnalyticFull;
