import * as React from 'react';
import Moment from 'react-moment';

import { IFDetailChatMessageByDays, IFPeople } from '../../../global/interfaces';
import { baseURL, varsString } from '../../../global/vars';
import { Link } from 'react-router-dom';

interface Props {
  profile: IFPeople;
  messages: IFDetailChatMessageByDays[];
}

interface State {}

class MessagesList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { profile, messages } = this.props;

    return (
      <div className="msg_list">
        {messages.map((day, i) => (
          <div className="day" key={`day${i}`}>
            <div className="date">{day.date}</div>

            {day.messages.map(({ message, date, sender_id, avatar, type, read_date }, idx) => (
              <div className={`msgs ${sender_id != profile.id ? 're' : ''}`} key={`msg${idx}`}>
                <div className="list">
                  <div className={`msg ${read_date ? 'reed' : ''}`}>
                    <p>{message}</p>
                    <div className="time">
                      <span>
                        <Moment format="HH:mm" add={{ hours: 7 }}>
                          {date}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
                {sender_id != profile.id ? (
                  <Link
                    to={`/profile/${type == varsString.student ? varsString.user : type}/${sender_id}`}
                    className="ava"
                    style={{ backgroundImage: `url(${baseURL}/storage/${avatar})` }}
                  ></Link>
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default MessagesList;
