import * as React from 'react';

import FilterForm from '../../components/FilterForm/FilterForm';
import Search from '../../components/Search/Search';
import Tabs from '../../components/Tabs/Tabs';

import { Config, IFFiltersItem, IFFitlers, IFPeople } from '../../global/interfaces';
import { apiFields, apiUrl, defaultConfig } from '../../global/vars';
import { updateAllConfig } from '../../services/Serv.ActivityPages';
import FetchMyTrack from '../../services/FetchMyTrack';
import TabsContentPeople from '../../components/TabsContentPeople/TabsContentPeople';

interface Props {}

interface State {
  data: {
    students: IFPeople[];
    mentors: IFPeople[];
    filters: IFFitlers;
  };
  config: Config;
  active: number;
}

class People extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      data: {
        students: [],
        mentors: [],
        filters: {},
      },
      config: {
        page: 0,
        languages: '',
        interests: '',
        tech_skills: '',
        track_id: '',
        way_id: '',
        gender: '',
        fresh: 0,
        needle: '',
      },
      active: 0,
    };
  }

  render() {
    const {
      data: { students, mentors },
      active,
    } = this.state;
    const title = 'Люди';

    return (
      <div className="page">
        <div className="page__container">
          <div className="page-with-left-bar">
            <div className="left-bar">
              <div className="left-bar__page-name">
                <span>{title}</span>
              </div>

              <FilterForm
                filters={this.state.data.filters}
                updateConfig={this.updateConfig}
                fetchGetFilteredPage={this.fetchGetFilteredPage}
                updateAllConfig={this.updateAllConfig}
                updateFiltersTracks={this.updateFiltersTracks}
              />
            </div>

            <div className="page__content">
              <Search search={this.search} needle={this.state.config.needle} />
              <Tabs
                tabs={['Пользователи', 'Наставники']}
                tabsContent={[
                  <TabsContentPeople
                    people={students}
                    active={this.state.active}
                    fetchGetFilteredPageMore={this.fetchGetFilteredPageMore}
                    updateConfig={this.updateConfig}
                    fetchGetFilteredPage={this.fetchGetFilteredPage}
                    typeLink="user"
                    key="students"
                  />,
                  <TabsContentPeople
                    people={mentors}
                    active={this.state.active}
                    fetchGetFilteredPageMore={this.fetchGetFilteredPageMore}
                    updateConfig={this.updateConfig}
                    fetchGetFilteredPage={this.fetchGetFilteredPage}
                    typeLink="mentor"
                    key="mentors"
                  />,
                ]}
                updateFilterTabs={this.updateFilterTabs}
                setActive={this.setActive}
                active={this.state.active}
                filterTabs={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.FetchAPI.getPage().then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          data: {
            students: data.students,
            mentors: data.mentors,
            filters: data.filters_config,
          },
        })
      );
    });
  }

  setActive = (active: number) => {
    this.setState({ active: active });
  };

  updateAllConfig = () => {
    updateAllConfig(defaultConfig.people.fresh, this);
  };

  updateConfig = (key: string, item: string) => {
    const promise = new Promise((resolve) => {
      this.setState(
        Object.assign({}, this.state, {
          config: {
            [apiFields.page]: this.state.config.page,
            [apiFields.languages]: key == apiFields.languages ? item : this.state.config.languages,
            [apiFields.interests]: key == apiFields.interests ? item : this.state.config.interests,
            [apiFields.techSkills]: key == apiFields.techSkills ? item : this.state.config.tech_skills,
            [apiFields.trackID]: key == apiFields.trackID ? item : this.state.config.track_id,
            [apiFields.wayID]: key == apiFields.wayID ? item : this.state.config.way_id,
            [apiFields.gender]: key == apiFields.gender ? item : this.state.config.gender,
            [apiFields.fresh]: this.state.config.fresh,
            [apiFields.needle]: key == apiFields.needle ? item : this.state.config.needle,
          },
        })
      );
      resolve(true);
    });

    promise.then(() => {
      this.fetchGetFilteredPage();
    });
  };
  updateConfigNumbers = (key: string, item: number) => {
    const promise = new Promise((resolve) => {
      this.setState(
        Object.assign({}, this.state, {
          config: {
            [apiFields.page]: key == apiFields.page ? item : this.state.config.page,
            [apiFields.languages]: this.state.config.languages,
            [apiFields.interests]: this.state.config.interests,
            [apiFields.techSkills]: this.state.config.tech_skills,
            [apiFields.trackID]: this.state.config.track_id,
            [apiFields.wayID]: this.state.config.way_id,
            [apiFields.gender]: this.state.config.gender,
            [apiFields.fresh]: key == apiFields.fresh ? item : this.state.config.fresh,
            [apiFields.needle]: this.state.config.needle,
          },
        })
      );
      resolve(true);
    });

    promise.then(() => {
      console.log(this.state.config);
    });
  };

  updateFilterTabs = (i: number) => {
    const promise = new Promise((resolve) => {
      this.updateConfigNumbers(apiFields.fresh, i);
      resolve(true);
    });

    promise.then(() => {
      this.fetchGetFilteredPage();
    });
  };

  updateFiltersTracks = (item: IFFiltersItem[]) => {
    new Promise((resolve) => {
      this.setState(
        Object.assign({}, this.state, {
          data: {
            students: this.state.data.students,
            mentors: this.state.data.mentors,
            filters: Object.assign(this.state.data.filters, {
              track_id: item,
            }),
          },
        })
      );
      resolve(true);
    }).then(() => {
      console.log(this.state.config, this.state.data.filters);
    });
  };

  fetchGetFilteredPage = () => {
    const promise = new Promise((resolve) => {
      this.updateConfigNumbers(apiFields.page, 0);
      resolve(true);
    });

    promise.then(() => {
      this.FetchAPI.getFilteredPage(this.state.config, apiUrl.people).then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            data: {
              students: data.students,
              mentors: data.mentors,
              filters: this.state.data.filters,
            },
          })
        );
      });
    });
  };

  fetchGetFilteredPageMore = (index: number) => {
    const promise = new Promise((resolve) => {
      this.updateConfigNumbers(apiFields.page, this.state.config.page + 1);
      resolve(true);
    });

    promise.then(() => {
      this.FetchAPI.getFilteredPage(this.state.config, apiUrl.people).then((data) => {
        const students =
          index == 0 ? this.state.data.students.concat(data.students) : this.state.data.students;
        const mentors = index == 1 ? this.state.data.mentors.concat(data.mentors) : this.state.data.mentors;

        this.setState(
          Object.assign({}, this.state, {
            data: {
              students: students,
              mentors: mentors,
              filters: this.state.data.filters,
            },
          })
        );
      });
    });
  };

  search = (value: string) => {
    const promise = new Promise((resolve) => {
      this.updateConfig('needle', value);
      resolve(true);
    });

    promise.then(() => {
      this.fetchGetFilteredPage();
    });
  };
}

export default People;
