import * as React from 'react';
import Select from 'react-select';

import '../Input/Input.scss';
import './FormRegister.scss';

import { regions, varsString } from '../../global/vars';
import Input from '../Input/Input';
import { IFAlert, optionSelect } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';
import Alert from '../Alert/Alert';
import { ItEmail, OnlyLetters } from '../../services/Serv.Login';

interface Props {}

interface State {
  name: string;
  errorName: string;
  surname: string;
  errorSurname: string;
  email: string;
  errorEmail: string;
  pass: string;
  errorPass: string;
  pass2: string;
  errorPass2: string;
  errorServer: string;
  serverNotify: boolean;
  gender: optionSelect;
  errorGender: string;
  region: optionSelect;
  errorRegion: string;
  checked: boolean;
  errorCheck: string;
  successReg: boolean;
  alert: IFAlert | null;
}

class FormRegister extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  refFormRegister: React.RefObject<HTMLFormElement>;
  role: boolean;
  acceptTerms: boolean;
  regions: { [key: string]: string };

  constructor(props: Props) {
    super(props);
    this.refFormRegister = React.createRef();
    this.role = false;
    this.acceptTerms = false;
    this.regions = regions;
    this.state = {
      name: '',
      errorName: '',
      surname: '',
      errorSurname: '',
      email: '',
      errorEmail: '',
      pass: '',
      errorPass: '',
      pass2: '',
      errorPass2: '',
      errorServer: '',
      serverNotify: false,
      gender: { value: -1, label: '' },
      region: { value: -1, label: '' },
      checked: false,
      errorCheck: '',
      errorGender: '',
      errorRegion: '',
      successReg: false,
      alert: null,
    };
  }

  render() {
    const { refFormRegister, role, acceptTerms, regions } = this;
    const {
      errorName,
      errorSurname,
      errorEmail,
      errorPass,
      errorPass2,
      errorServer,
      serverNotify,
      errorGender,
      errorRegion,
      errorCheck,
      checked,
      successReg,
      alert,
    } = this.state;

    const regionsSelect: optionSelect[] = [];
    Object.keys(regions).forEach((key) => {
      regionsSelect.push({ value: parseInt(key), label: regions[key] });
    });

    if (successReg) {
      return (
        <div className="reg-success mt-12">
          На вашу почту отправлено письмо с инструкцией по активации аккаунта.
          <button
            className="blue_btn w100 mt-24"
            onClick={() => {
              this.refresh();
            }}
          >
            Отправить письмо повторно
          </button>
          {alert ? (
            <Alert title={alert.title} text={alert.text} component={this} error={alert.error} />
          ) : null}
        </div>
      );
    }

    return (
      <form
        id="register_student"
        ref={refFormRegister}
        onSubmit={(e) => {
          e.preventDefault();
          this.register();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            this.register();
          }
        }}
      >
        <div className="row">
          {role ? (
            <div>
              <div className="col-6">
                <div className="input radio">
                  <input type="radio" id="role_s" name="role" />
                  <label htmlFor="role_s">Ученик</label>
                </div>
              </div>
              <div className="col-6">
                <div className="input radio">
                  <input type="radio" id="role_m" name="role" />
                  <label htmlFor="role_m">Наставник</label>
                </div>
              </div>
            </div>
          ) : null}

          <div id="for_s" className="col-12 p-0">
            <Input
              type="text"
              name="name"
              placeholder="Имя"
              form="register_student"
              saveValue={this.saveName}
              error={errorName}
            />
            <Input
              type="text"
              name="surname"
              placeholder="Фамилия"
              form="register_student"
              saveValue={this.saveSurname}
              error={errorSurname}
            />

            <div className="col-12">
              <div className="input">
                <Select
                  options={[
                    { value: 0, label: 'Мужской' },
                    { value: 1, label: 'Женский' },
                  ]}
                  placeholder={`Ваш пол`}
                  isSearchable={false}
                  className="select-my 1"
                  classNamePrefix="select-my"
                  name="gender"
                  onChange={(e) => {
                    this.setState(Object.assign({}, this.state, { gender: e }));
                  }}
                />
                <span className="input-text__notification">{errorGender}</span>
              </div>
            </div>

            <div className="col-12">
              <div className="input">
                <Select
                  options={regionsSelect}
                  placeholder={`Ваш регион`}
                  isSearchable={false}
                  className="select-my 1"
                  classNamePrefix="select-my"
                  name="geo_id"
                  onChange={(e) => {
                    this.setState(Object.assign({}, this.state, { region: e }));
                  }}
                />
                <span className="input-text__notification">{errorRegion}</span>
              </div>
            </div>

            <Input
              type="text"
              name="mail"
              placeholder="E-mail"
              form="register_student"
              saveValue={this.saveEmail}
              error={errorEmail}
            />
            <Input
              type="password"
              name="password"
              placeholder="Пароль"
              form="register_student"
              saveValue={this.savePass}
              error={errorPass}
            />
            <Input
              type="password"
              name="password1"
              placeholder="Повторите пароль"
              form="register_student"
              saveValue={this.savePass2}
              error={errorPass2}
            />

            <div className="col-12">
              <div className="input checkbox reg-agreement">
                <input
                  type="checkbox"
                  id="checkbox_task_2"
                  name="check"
                  defaultChecked={checked}
                  onChange={() => {
                    this.setState(Object.assign({}, this.state, { checked: !checked }));
                  }}
                />
                <label htmlFor="checkbox_task_2">
                  Соглашаюсь на{' '}
                  <a href="/legal" target="_blank">
                    обработку персональных данных
                  </a>{' '}
                  в соответствии с Политикой конфиденциальности и принимаю условия{' '}
                  <a href="#">Лицензионного соглашения</a>
                </label>
                <span className="input-text__notification input-text__notification_check">{errorCheck}</span>
              </div>
            </div>

            {acceptTerms ? (
              <div className="col-12">
                <div className="input checkbox">
                  <input type="checkbox" id="checkbox_task_2" />
                  <label htmlFor="checkbox_task_2">Принимаю условия соглашения и политики</label>
                </div>
              </div>
            ) : null}

            <div className="col-12 input text-center">
              <input
                type="submit"
                className="blue_btn"
                value="Зарегистрироваться"
                id="send_register"
                form="register_student"
              />
              <span
                className={`input-text__notification input-text__notification_server ${
                  serverNotify ? 'input-text__notification_server-success' : ''
                }`}
              >
                {errorServer}
              </span>
            </div>
          </div>
        </div>
        {alert ? <Alert title={alert.title} text={alert.text} component={this} error={alert.error} /> : null}
      </form>
    );
  }

  register() {
    const { name, surname, email, pass, pass2, gender, region, checked } = this.state;
    let passNotify: string = '',
      pass2Notify: string = '',
      nameNotify: string = '',
      surnameNotify: string = '',
      emailNotify: string = '',
      checkNotify: string = '',
      genderNotify: string = '',
      regionNotify: string = '';
    let hasErrors: number = 0;

    this.setState(
      Object.assign({}, this.state, {
        errorName: '',
        errorSurname: '',
        errorEmail: '',
        errorPass: '',
        errorPass2: '',
        errorCheck: '',
        errorGender: '',
        errorRegion: '',
      })
    );

    if (pass !== pass2) {
      pass2Notify = 'Пароли не совпадают';
      hasErrors = 1;
    }
    if (pass.length < 8) {
      passNotify = 'Пароль должен быть минимум 8 символов';
      hasErrors = 1;
    }

    if (!OnlyLetters(name)) {
      nameNotify = 'Имя некорректно, используйте только киррилицу';
      hasErrors = 1;
    }
    if (!OnlyLetters(surname)) {
      surnameNotify = 'Фамилия некорректна, используйте только киррилицу';
      hasErrors = 1;
    }

    if (!ItEmail(email)) {
      emailNotify = 'E-mail введен некорректно';
      hasErrors = 1;
    }

    if (
      name == '' ||
      surname == '' ||
      email == '' ||
      pass == '' ||
      pass2 == '' ||
      !checked ||
      gender.value == -1 ||
      region.value == -1
    ) {
      nameNotify = name == '' ? 'Поле пустое' : nameNotify;
      surnameNotify = surname == '' ? 'Поле пустое' : surnameNotify;
      emailNotify = email == '' ? 'Поле пустое' : emailNotify;
      passNotify = pass == '' ? 'Поле пустое' : passNotify;
      pass2Notify = pass2 == '' ? 'Поле пустое' : pass2Notify;
      checkNotify = !checked ? 'Согласие является обязательным' : checkNotify;
      genderNotify = gender.value == -1 ? 'Выберите пол' : genderNotify;
      regionNotify = region.value == -1 ? 'Выберите регион' : regionNotify;
      hasErrors = 1;
    }

    if (hasErrors) {
      this.setState(
        Object.assign({}, this.state, {
          errorName: nameNotify,
          errorSurname: surnameNotify,
          errorEmail: emailNotify,
          errorPass: passNotify,
          errorPass2: pass2Notify,
          errorCheck: checkNotify,
          errorGender: genderNotify,
          errorRegion: regionNotify,
        })
      );
    } else {
      this.FetchAPI.register(
        varsString.student,
        name,
        surname,
        gender.value,
        region.value,
        email,
        pass,
        pass2,
        'on'
      ).then((data) => {
        const clean = { errorName: '', errorSurname: '', errorEmail: '', errorPass: '', errorPass2: '' };

        if (data.status == 'fail') {
          if (data.errors.mail) {
            this.setState(Object.assign({}, this.state, clean, { errorServer: data.errors.mail }));
          }
          if (data.errors.user) {
            this.setState(Object.assign({}, this.state, clean, { errorServer: data.errors.user }));
          }
          if (data.errors.Request) {
            this.setState(Object.assign({}, this.state, clean, { errorServer: data.errors.Request }));
          }
          if (data.errors.auth) {
            this.setState(Object.assign({}, this.state, clean, { errorServer: data.errors.auth }));
          }
        } else {
          this.setState(Object.assign({}, this.state, clean, { errorServer: '' }));
        }

        if (data.status == 'success') {
          const msg = data.objects;
          this.setState(
            Object.assign({}, this.state, { errorServer: msg, serverNotify: true, successReg: true })
          );
        }
      });
    }
  }

  refresh() {
    this.FetchAPI.refreshRegister(this.state.email).then((data) => {
      if (data.status == 'success') {
        this.setState(Object.assign({}, this.state, { alert: { title: 'Все хорошо!', text: data.objects } }));
      } else {
        this.setState(
          Object.assign({}, this.state, { alert: { title: 'Ошибка', text: data.errors, error: true } })
        );
      }
    });
  }

  saveName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { name: e.target.value }));
  };

  saveSurname = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { surname: e.target.value }));
  };
  saveEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { email: e.target.value }));
  };
  savePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { pass: e.target.value }));
  };
  savePass2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { pass2: e.target.value }));
  };
}

export default FormRegister;
