import * as React from 'react';

import { IFCompetenciesTracks } from '../../../global/interfaces';

import ModalCompetencies from '../ModalCompetencies/ModalCompetencies';
import { ContextModal } from '../../../services/Contexts';

interface Props {
  track: IFCompetenciesTracks;
  listTracks: IFCompetenciesTracks[];
}

class HistoryDiagram extends React.Component<Props> {
  render() {
    const {
      track: { name, competencies },
      listTracks,
    } = this.props;

    const donut = (
      <div className="treks_donut">
        <svg
          width="180px"
          height="180px"
          viewBox="1 1 44 44"
          className="donut"
          aria-labelledby="beers-title beers-desc"
          role="img"
        >
          <circle
            className={`donut-segment ${name == listTracks[0].name ? 'active' : ''}`}
            cx="22"
            cy="22"
            r="40%"
            fill="transparent"
            stroke="#ff4b3a"
            strokeWidth="8px"
            strokeDasharray="12.5 87.5"
            strokeDashoffset="62.5"
          ></circle>

          <circle
            className={`donut-segment ${name == listTracks[1].name ? 'active' : ''}`}
            cx="22"
            cy="22"
            r="40%"
            fill="transparent"
            stroke="#ff800b"
            strokeWidth="8px"
            strokeDasharray="12.5 87.5"
            strokeDashoffset="75"
          ></circle>

          <circle
            className={`donut-segment ${name == listTracks[2].name ? 'active' : ''}`}
            cx="22"
            cy="22"
            r="40%"
            fill="transparent"
            stroke="#ffc500"
            strokeWidth="8px"
            strokeDasharray="12.5 87.5"
            strokeDashoffset="87.5"
          ></circle>

          <circle
            className={`donut-segment ${name == listTracks[3].name ? 'active' : ''}`}
            cx="22"
            cy="22"
            r="40%"
            fill="transparent"
            stroke="#00cd6a"
            strokeWidth="8px"
            strokeDasharray="12.5 87.5"
            strokeDashoffset="100"
          ></circle>
        </svg>
      </div>
    );

    return (
      <ContextModal.Consumer>
        {({ showModal }) => {
          return (
            <div className="treks_name_list history-map__content">
              <div className="history-map__tracks">
                <div className="history-map__tracks-list">
                  {listTracks.map((item, i) => (
                    <span className={name == item.name ? 'active' : ''} key={`track${i}`}>
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>

              {donut}

              <div className="competencies_block">
                <h6>Компетенции</h6>
                <div className="competencies_list">
                  {competencies.map((item, i) => (
                    <div
                      className="competence"
                      key={`compet${i}`}
                      onClick={() => {
                        this.showPoints(i, showModal);
                      }}
                    >
                      <span>
                        {item.name} ({item.score}/100)
                      </span>
                      <div className="line">
                        <div style={{ width: `${item.score}%` }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        }}
      </ContextModal.Consumer>
    );
  }

  showPoints(i: number, showModal: any) {
    showModal({
      title: `Компетенции трека «${this.props.track.name}»`,
      content: <ModalCompetencies track={this.props.track} idxTab={i} />,
      width: 'big grey',
    });
  }
}

export default HistoryDiagram;
