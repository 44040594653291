import * as React from 'react';
import './HeaderTopMenu.scss';

import { baseURL, apiFields, main, popups } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';
import GeoLocation from '../GeoLocation/GeoLocation';
import { IFGeo } from '../../global/interfaces';
import { Link } from 'react-router-dom';

const icoBest1 = require('./steering_wheel.svg').default;
const icoBest2 = require('./wheel.svg').default;
const icoPerson = require('../../old/img/icons/a-person-outline.svg').default;
const icoSettings = require('../../old/img/icons/a-settings.svg').default;
const icoLogout = require('../../old/img/icons/a-logout.svg').default;

interface Props {
  updateGeo: any;
  updateGeoRegion: any;
  pageName: string;
  getModal: any;
  geo: IFGeo | null;
  updateProfile: any;
  elClick: React.MouseEvent | null;
}
interface State {
  avatar?: string;
  geo: IFGeo | null;
  geo_id: number;
  message_alarm?: boolean;
  name?: string;
  newbie?: number;
  notify_alarm?: boolean;
  on_tracks: number;
  press?: string;
  status_text?: string;
  surname?: string;
  user_id?: number;
  user_type: string;
  who_rules: {
    works: number;
    name: string;
    surname: string;
    id: number;
    score: number;
  } | null;

  showNotification: boolean;
  notifications: {
    text: string;
    time: string;
  }[];
  showUserMenu: boolean;
  loading: boolean;
  showMenu: boolean;
  authorized: boolean;
}

class HeaderTopMenu extends React.Component<Props, State> {
  notify: React.RefObject<HTMLDivElement>;
  toggleNotify: React.RefObject<HTMLDivElement>;
  FetchAPI = new FetchMyTrack();
  menu: {
    text: string;
    link: string;
    active: boolean;
    show: boolean;
  }[];

  constructor(props: Props) {
    super(props);
    this.notify = React.createRef();
    this.toggleNotify = React.createRef();
    this.state = {
      // avatar: '',
      geo: this.props.geo
        ? {
            code: this.props.geo.code,
            region: this.props.geo.region,
            city: this.props.geo.city,
          }
        : null,
      geo_id: 85,
      message_alarm: false,
      name: '',
      // newbie: 0,
      notify_alarm: false,
      on_tracks: 0,
      // press: '',
      // status_text: '',
      // surname: '',
      // user_id: 0,
      user_type: 'guest',
      who_rules: null,

      showNotification: false,
      notifications: [],
      showUserMenu: false,
      loading: true,
      showMenu: false,
      authorized: false,
    };

    this.menu = [
      {
        text: 'Треки',
        link: '/tracks',
        active: window.location.pathname == '/tracks',
        show: true,
      },
      {
        text: 'Активности',
        link: '/activity',
        active: window.location.pathname == '/activity',
        show: false,
      },
      {
        text: 'Рекомендации',
        link: '/recommendations',
        active: window.location.pathname == '/recommendations',
        show: false,
      },
      {
        text: 'Профайл',
        link: '/profile',
        active: window.location.pathname == '/profile',
        show: false,
      },
    ];
  }

  render() {
    const { avatar, name, who_rules, on_tracks, geo, notify_alarm, message_alarm, showMenu, authorized } =
      this.state;
    const { menu } = this;

    const whoRules = who_rules ? (
      <a href={`/profile/user/${who_rules.id}`} className="top-menu__the-best-name">
        {who_rules.name} {who_rules.surname}
      </a>
    ) : (
      <span>
        <a className="top-menu__who-rules-null">–</a>
      </span>
    );

    const indicator = <div className="indicator"></div>;
    const topMenuIcons = (
      <div className="icons">
        <a href="/messages" className="link_btn messages">
          {message_alarm ? indicator : null}
          <i className="icon icon-a-mail"></i>
        </a>
        <div
          ref={this.toggleNotify}
          className="open_list_btn header-notifications"
          onClick={() => this.showNotification()}
        >
          {notify_alarm ? indicator : null}
          <i className="icon icon-a-notifications"></i>
        </div>
      </div>
    );

    const topMenuUser = (
      <div
        className="user"
        onClick={() => {
          this.showUserMenu();
        }}
      >
        <span>{name}</span>
        {avatar ? (
          <div className="ava" style={{ backgroundImage: `url(${baseURL}/storage/${avatar})` }}></div>
        ) : (
          <div className="ava"></div>
        )}
      </div>
    );
    const topMenuProfileMenu = (
      <div className={`menu ${this.state.showUserMenu ? 'active' : ''}`}>
        <a href="/profile">
          <img src={icoPerson} alt="" />
          Профайл
        </a>
        <a href="/settings">
          <img src={icoSettings} alt="" />
          Настройки
        </a>
        <div className="line"></div>
        <a
          href="/login"
          onClick={() => {
            this.logOut();
          }}
        >
          <img src={icoLogout} alt="" />
          Выход
        </a>
      </div>
    );

    const topMenuLogin = (
      <div className="top-menu__user-login">
        <div className="input">
          <Link
            to={{ pathname: '/login' }}
            state={{ active: main.register }}
            className={`border_btn top-menu__button`}
          >
            Регистрация
          </Link>
        </div>

        <div className="input">
          <Link
            to={{ pathname: '/login' }}
            state={{ active: main.login }}
            className={`border_btn top-menu__button`}
          >
            Войти
          </Link>
        </div>
      </div>
    );

    const popupNotifications = (
      <div className={`notifications_popup popup_cloud active`} ref={this.notify}>
        {notify_alarm ? (
          this.state.notifications.map((item, index) => (
            <div className="event_alert" key={`notify${index}`}>
              <div className="text">
                <a href="#">{item.text}</a>
              </div>
              <div className="time">
                <span>{item.time}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="event_alert event_alert_center">
            <div className="text">Новых уведомлений нет</div>
          </div>
        )}

        <div className="show_all">
          <a href="/notifications">Смотреть все</a>
        </div>
      </div>
    );

    if (this.state.loading) {
      return <div></div>;
    }

    return (
      <div>
        <div id="top_menu" className="top-menu">
          <div className="user_pages">
            {menu.map(({ link, text, active, show }, i) =>
              show ? (
                <a href={`${link}`} className={`${active ? 'selected' : ''}`} key={`link${i}`}>
                  {text}
                </a>
              ) : showMenu ? (
                <a href={`${link}`} className={`${active ? 'selected' : ''}`} key={`link${i}`}>
                  {text}
                </a>
              ) : null
            )}
          </div>

          <div className="the_best">
            <div>
              <div className="ico">
                <img src={icoBest1} alt="" />
              </div>
              <div className="text">
                <span>Сегодня рулит:</span>
                {whoRules}
              </div>
            </div>

            <div>
              <div className="ico">
                <img src={icoBest2} alt="" />
              </div>
              <div className="text">
                <span>Всего на треках:</span>
                <span>{on_tracks} человек</span>
              </div>
            </div>
          </div>

          {geo ? <GeoLocation geo={geo} getModal={this.props.getModal} /> : null}

          {name ? topMenuIcons : null}

          {authorized ? topMenuUser : topMenuLogin}
          {authorized ? topMenuProfileMenu : null}

          {this.state.showNotification ? popupNotifications : null}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.FetchAPI.getTopProfile({})
      .then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            who_rules: data.who_rules,
            on_tracks: data.on_tracks,
            geo: data.geo,
            geo_id: data.geo_id,
            user_type: data.user_type,
            notify_alarm: data.notify_alarm || this.state.notify_alarm,
            message_alarm: data.message_alarm || this.state.message_alarm,
            name: data.name,
            surname: data.surname,
            avatar: data.avatar,
            status_text: data.status_text,
            showMenu: data.user_type == apiFields.student,
            authorized: data.user_type != apiFields.guest,
          })
        );

        this.props.updateProfile({
          name: data.name,
          surname: data.surname,
          avatar: data.avatar,
          text: data.status_text,
          id: data.user_id,
        });

        if (data.geo_id) {
          this.props.updateGeo(data.geo_id);
        }
        if (data.geo) {
          this.props.updateGeoRegion(data.geo);
        }
      })
      .then(() => {
        this.setState(
          Object.assign({}, this.state, {
            loading: false,
          })
        );
      });

    if (this.state.authorized) {
      this.FetchAPI.getMenu().then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            notifications: data.notifications,
          })
        );
      });
    }
  }

  componentDidUpdate(prevP: Props) {
    if (prevP !== this.props) {
      if (this.props.geo) {
        this.setState(
          Object.assign({}, this.state, {
            geo: {
              code: this.props.geo.code,
              region: this.props.geo.region,
              city: this.props.geo.city,
            },
          })
        );
      }

      // if (this.props.elClick) {
      //   const clickPopup = this.hasContains(this.notify.current, this.props.elClick);
      //   console.log(this.toggleNotify);
      //   const clickToggleNotify = this.hasContains(this.toggleNotify.current, this.props.elClick);

      //   if (!clickPopup && !clickToggleNotify) {
      //     console.log('closed');
      //     this.closedNotification();
      //   }
      // }
    }
  }

  hasContains(element: any, event: React.MouseEvent) {
    console.log(element, event);
    if (element == event.target || (event.target instanceof Node && element.contains(event.target))) {
      return true;
    } else {
      return false;
    }
  }

  showNotification() {
    this.setState(Object.assign({}, this.state, { showNotification: !this.state.showNotification }));
    console.log('open');
  }

  closedNotification() {
    this.setState(Object.assign(this.state, { showNotification: false }));
  }

  showUserMenu() {
    this.setState(Object.assign(this.state, { showUserMenu: !this.state.showUserMenu }));
  }

  logOut() {
    localStorage.setItem('token', '');
  }
}

export default HeaderTopMenu;
