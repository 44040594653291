import * as React from 'react';

import './TrackList.scss';

import { clickTabs } from '../../global/functions';
import { baseURL } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';

interface Props {}

interface State {
  active: number;
  qualify: {
    company_name: string;
    id: number;
    image: string;
    name: string;
    order: number;
    price: number;
  }[];
  fields: {
    nameGroup: string;
    tooltip: string;
    items: { id: number; name: string; img: string }[];
  }[];
}

class TrackList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
      qualify: [],
      fields: [
        // нужно получать по API (корректные данные в API)
        {
          nameGroup: 'Научно-производственная сфера',
          tooltip: 'Преобразование традиционных отраслей, появление новых материалов и технологий.',
          items: [
            { id: 1, name: 'Промышленный дизайн', img: 'trackPicture/track_1.jpg' },
            {
              id: 3,
              name: 'Технологии освоения космоса',
              img: 'trackPicture/технологии освоения космоса.jpg',
            },
          ],
        },
        {
          nameGroup: 'Киберэкономика',
          tooltip:
            'Киберэкономика состоит из системных ресурсов, которые повышают эффективность экономических процессов путем оптимального управления связью и взаимодействием между подсистемами субъектов и объектов экономических отношений. Глобализация на основе новейших ИКТ.',
          items: [
            { id: 2, name: 'Программная инженерия', img: 'trackPicture/программная инженерия.jpg' },
            {
              id: 6,
              name: 'Data Mining',
              img: 'trackPicture/data mining.jpg',
            },
          ],
        },
      ],
    };
  }

  render() {
    const tracks = [
      {
        title: 'Пройти квалификацию',
        image: `${baseURL}/img/icons/trek/qualification_new.svg`,
        imageActive: `${baseURL}/img/icons/trek/qualification_new-active.svg`,
        text: `Квалификация — это отличный способ попробовать себя в разных треках. Наставники MyTrack уже
        выстроили для тебя короткие траектории из самых классных активностей: это крутые челленджи и
        курсы, проекты, вебинары, обучающие мастер-классы по различным направлениям. Участвуй в
        квалификациях, чтобы понять, что интересно именно тебе!`,
      },
      {
        title: 'По сфере деятельности',
        image: `${baseURL}/img/icons/trek/field.svg`,
        imageActive: `${baseURL}/img/icons/trek/field_active.svg`,
        text: '',
      },
      {
        title: 'По интересному тебе контенту',
        image: `${baseURL}/img/icons/trek/content.svg`,
        imageActive: `${baseURL}/img/icons/trek/content_active.svg`,
        text: '',
      },
    ];

    const listQualify = this.state.qualify.map((item, i) => (
      <a href={`/qualifications/${item.id}`} className="qualify_card" key={`quality${i}`}>
        <div className="preload">
          <div className="text">
            <div className="organization_name">
              <span></span>
            </div>
            <div className="name">
              <span></span>
            </div>
          </div>
          <div className="bg"></div>
        </div>

        <div className="status yellow">
          <span>{`${item.price} ₽`}</span>
        </div>

        <div className="text">
          <div className="organization_name">
            <span>{item.company_name}</span>
          </div>
          <div className="name">
            <span>#{i + 1}</span>
            <span>{item.name}</span>
          </div>
        </div>
        <div className="bg">
          <div
            style={{
              backgroundImage: `url('${baseURL}/storage/${item.image}')`,
            }}
          ></div>
        </div>
      </a>
    ));

    const listField = (
      <div className="trek trek-field active">
        {this.state.fields.map((item, i) => (
          <div className="category" key={`category${i}`}>
            <div className="page_title">
              <h3>{item.nameGroup}</h3>
              <i className="explanation icon-q-a">
                <span className="tooltip">
                  <span>{item.tooltip}</span>
                </span>
              </i>
            </div>
            <div className="treks_group">
              {item.items.map((card, i) => (
                <div className="trek_card" key={`card${i}`}>
                  <a href={`/track/${card.id}`}>
                    <div className="name">
                      <span>{card.name}</span>
                    </div>
                    <div
                      className="bg"
                      style={{
                        backgroundImage: `url('${baseURL}/storage/${card.img}')`,
                      }}
                    ></div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );

    const listSelect = (
      <div className="select_content_list">
        <div className="select_content education">
          <span>Выбери интересные челленджи</span>
          <a href="/challenges" className="blue_btn">
            Челленджи
          </a>
        </div>

        <div className="select_content project">
          <span>Выбери интересные проекты</span>
          <a href="/projects" className="blue_btn">
            Проекты
          </a>
        </div>

        <div className="select_content events">
          <span>Выбери события, которые хочешь посетить</span>
          <a href="/events" className="blue_btn">
            События
          </a>
        </div>
      </div>
    );

    return (
      <div className="treks">
        <div id="trek_list">
          {tracks.map((item, i) => (
            <div
              className={`trek ${this.state.active == i ? 'active' : ''}`}
              onClick={() => clickTabs(this, i)}
              key={`track${i}`}
            >
              <div className="img">
                <img src={item.imageActive} alt="" className="hover" />
                <img src={item.image} alt="" />
              </div>
              <div className="text">
                <span>{item.title}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="trek_content">
          {tracks.map((item, i) => (
            <div className={`trek ${this.state.active == i ? 'active' : ''}`} key={`track${i}`}>
              <div className="category">
                <div className="page_title">
                  <span>{item.text}</span>
                </div>

                {this.state.active == 0 ? (
                  <div className="qualify_list">{listQualify}</div>
                ) : this.state.active == 1 ? (
                  <div className="qualify_list">{listField}</div>
                ) : this.state.active == 2 ? (
                  <div className="select_content_list">{listSelect}</div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();
    FetchAPI.getTracksQualifications().then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          qualify: data,
        })
      );
    });
  }
}

export default TrackList;
