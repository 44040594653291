import * as React from 'react';
import { IFWorks } from '../../global/interfaces';
import { count } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';
import CardWorks from '../CardWorks/CardWorks';

interface Props {
  type: string;
}

interface State {
  works: IFWorks[];
  page: number;
  countCards: number;
}

class TabsContentWorks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      works: [],
      page: 0,
      countCards: count.worksToPage,
    };
  }

  render() {
    const { works } = this.state;
    const cardsMore = works.length < count.worksToPage ? false : true;

    return (
      <div className="tabs__content active">
        <div className="news">
          <div className="news__list-grid news__list-grid_4item">
            {this.state.works.map((item, i) => (
              <CardWorks card={item} key={`card${i}`} />
            ))}
          </div>
        </div>
        <div className="load_more text-center mt-24">
          {cardsMore ? (
            <button
              data-type="project"
              className="border_btn big w230 works_show_more"
              onClick={async () => {
                await this.incrementPage();
                this.loadMore();
              }}
            >
              Показать еще
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchCards();
  }

  async componentDidUpdate(prevP: Props) {
    if (prevP != this.props) {
      await this.setState(
        Object.assign({}, this.state, {
          page: 0,
        })
      );
      this.fetchCards();
    }
  }

  fetchCards() {
    const { type } = this.props;
    const page = this.state.page;

    const FetchAPI = new FetchMyTrack();
    FetchAPI.getFilteredPageWorks(type, page).then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          works: data.works,
          countCards: data.works.length,
        })
      );
    });
  }

  incrementPage() {
    this.setState(
      Object.assign({}, this.state, {
        page: this.state.page + 1,
      })
    );
  }

  loadMore() {
    const { type } = this.props;
    const page = this.state.page;

    const FetchAPI = new FetchMyTrack();
    FetchAPI.getFilteredPageWorks(type, page).then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          works: this.state.works.concat(data.works),
          countCards: data.works.length,
        })
      );
    });
  }
}

export default TabsContentWorks;
