import * as React from 'react';

// не используется, поэтому пока статично и не отлажено
class Workload extends React.Component {
  render() {
    return (
      <div className="col-6">
        <div className="page_title">
          <h4>Загруженность</h4>
        </div>

        <div id="speedometer">
          <div className="diogram">
            <div className="speedometr" data-hourly-load="11:10">
              <div className="line">
                <img src="./img/speedometr_line.svg" alt=""></img>
              </div>
              <img src="./img/speedometr.svg" alt=""></img>
            </div>
            <div className="hourly_load"></div>
          </div>

          <div className="text_diogram">
            <p>
              Текст о том сколько дополнительных часов может заниматься школьник. И сколько этих часов
              уже набралось.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Workload;
