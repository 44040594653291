import * as React from 'react';

import FilterForm from '../../components/FilterForm/FilterForm';
import Search from '../../components/Search/Search';
import News from '../../components/News/News';

import { IFFitlers } from '../../global/interfaces';
import Banner from '../Banner/Banner';
import FilterTabs from '../FilterTabs/FilterTabs';
import { getTypeAndIDPage } from '../../global/functions';
import { urlPages } from '../../global/vars';

interface Props {
  title: string;
  searchHas?: boolean;
  content: JSX.Element[];
  countCards: number;
  filters: IFFitlers;
  updateConfig: any;
  fetchGetFilteredPage: any;
  updateAllConfig: any;
  updateFiltersTracks: any;
  search?: any;
  getFilteredPageMore: any;
  hasFilterTabs?: boolean;
  updateFilterTabs?: any;
}

class ActivityPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      title = 'Заголовок',
      searchHas = true,
      filters,
      content,
      countCards,
      getFilteredPageMore,
      hasFilterTabs = false,
      updateFilterTabs,
      ...itemProps
    } = this.props;
    return (
      <div className="page">
        <div className="page__container">
          <div className="page-with-left-bar">
            <div className="left-bar">
              <div className="left-bar__page-name">
                <span>{title}</span>
              </div>

              <FilterForm filters={filters} {...itemProps} />
            </div>

            <div className="page__content">
              {searchHas && <Search {...itemProps} />}

              {hasFilterTabs && (
                <FilterTabs
                  tabs={[
                    { text: 'По новизне', fresh: 1 },
                    { text: 'По популярности', fresh: 0 },
                  ]}
                  updateFilterTabs={updateFilterTabs}
                />
              )}

              {getTypeAndIDPage().typePage != urlPages.navigator ? <Banner /> : null}

              <News content={content} countCards={countCards} getFilteredPageMore={getFilteredPageMore} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ActivityPage;
