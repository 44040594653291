import * as React from 'react';

import './GeoLocation.scss';

interface Props {
  geo: {
    code: string;
    region: string;
    city: string;
  };
  getModal: any;
}

interface State {
  showGeoPopup: boolean;
  showModal: boolean;
}

class GeoLocation extends React.Component<Props, State> {
  refPopup: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      showGeoPopup: false,
      showModal: false,
    };
    this.refPopup = React.createRef();
  }

  render() {
    const { geo } = this.props;
    return (
      <div className="geoLocation">
        <p className="geoLocation__place" onClick={() => this.toggleGeoPopup()}>
          {geo.region}
        </p>
        <div className={`geoLocation__form ${this.state.showGeoPopup ? 'active' : ''}`} ref={this.refPopup}>
          <p className="geoLocation__form-text">
            Ваш регион «<span className="geoLocation__region">{geo.region}</span>»?
          </p>
          <button
            type="button"
            className="button button_blue geoLocation__button geoLocation__button_yes"
            onClick={() => this.closedPopup()}
          >
            Да
          </button>
          <a
            href="#geoLocation_modal"
            className="border_btn geoLocation__button geoLocation__button_no open_modal"
            onClick={() => this.showModal()}
          >
            Нет
          </a>
        </div>
      </div>
    );
  }

  toggleGeoPopup() {
    this.setState(
      Object.assign(this.state, {
        showGeoPopup: !this.state.showGeoPopup,
      })
    );
  }

  closedPopup() {
    this.refPopup.current?.classList.remove('active');
    this.setState(
      Object.assign(this.state, {
        showGeoPopup: false,
      })
    );
  }

  showModal() {
    this.setState(
      Object.assign({}, this.state, {
        showGeoPopup: false,
      })
    );

    this.props.getModal(true, this);
  }
}

export default GeoLocation;
