import React from 'react';

interface Props {
  tabs: string[];
  active: number;
  changedTabs: any;
}

interface State {
  active: number;
}

class TabsMini extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  render() {
    const { tabs, active } = this.props;

    return (
      <div className="switch">
        {tabs.map((item, i) => (
          <span
            className={`${active == i ? 'active' : ''}`}
            key={`tabs${i}`}
            onClick={() => this.props.changedTabs(i)}
          >
            {item}
          </span>
        ))}
      </div>
    );
  }
}

export default TabsMini;
