import * as React from 'react';
import { regions } from '../../../global/vars';
import FetchMyTrack from '../../../services/FetchMyTrack';

import './ModalGeo.scss';

interface Props {
  closedModal?: any;
  updateGeo: any;
  updateGeoRegion: any;
}

class ModalGeo extends React.Component<Props> {
  FetchAPI = new FetchMyTrack();
  state = {
    listRegions: [
      'Вся Россия',
      'Томская область',
      'Новосибирская область',
      'Кемеровская область',
      'Красноярский край',
      'Омская область',
      'Тюменская область',
      'Алтайский край',
    ],
  };

  render() {
    const countColumn1 = Math.ceil(this.state.listRegions.length / 2);
    const column1: string[] = [];
    const column2: string[] = [];
    this.state.listRegions.forEach((item, i) => {
      i < countColumn1 ? column1.push(item) : column2.push(item);
    });

    return (
      <div id="geoLocation_modal" className="modal_div">
        <div className="min">
          <div className="modal_title">
            <h3>Выберите ваш регион</h3>
          </div>
          <span
            className="modal_close"
            onClick={() => {
              this.props.closedModal();
            }}
          >
            ×
          </span>

          <div className="content">
            <form action="" className="mt-0">
              <p>Выбор региона нужен для показа контента из вашего региона </p>
              <div className="input mt-0">
                <input
                  type="text"
                  placeholder="Начните вводить название региона"
                  onChange={(e) => {
                    this.searchRegion(e);
                  }}
                />
              </div>

              <div className="geo-regions">
                <div className="geo-regions__column geo-regions__column_1">
                  {column1.map((item, index) => (
                    <p
                      key={`regionItem${index}`}
                      className="geo-regions__item"
                      onClick={(e) => {
                        this.changedGeo(e);
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
                <div className="geo-regions__column geo-regions__column_2">
                  {column2.map((item, index) => (
                    <p
                      key={`regionItem${index}`}
                      className="geo-regions__item"
                      onClick={(e) => {
                        this.changedGeo(e);
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  changedGeo(e: any) {
    this.props.updateGeo(e.target.innerText); // изменяется значение geo_id
    this.props.updateGeoRegion({
      code: 'unknow',
      region: e.target.innerText,
      city: 'unknow',
    }); // изменяется текст в header
    this.props.closedModal();
  }

  searchRegion(e: React.ChangeEvent<HTMLInputElement>) {
    const text = e.target.value;
    const list = Object.values(regions).filter((item) => item.toLowerCase().includes(text.toLowerCase()));

    this.setState(
      Object.assign({}, this.state, {
        listRegions: list,
      })
    );
  }
}

export default ModalGeo;
