import * as React from 'react';

import './Mentors.scss';

import { IFMentor } from '../../../global/interfaces';
import Mentor from '../../Mentor/Mentor';

interface Props {
  mentors: IFMentor[];
  title?: string;
}

class Mentors extends React.Component<Props> {
  render() {
    const { title, mentors } = this.props;
    return (
      <div className="mentors mt-80">
        {title ? (
          <div className="page_title">
            <h3>{title}</h3>
          </div>
        ) : null}

        <div className="mertors_list">
          {mentors.map(({ name, surname, avatar, status, id }, i) => (
            <Mentor
              name={name}
              surname={surname}
              avatar={avatar}
              text={status ? status : ''}
              id={id}
              key={`mentor${i}`}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Mentors;
