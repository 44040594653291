import * as React from 'react';

import CardNavigator from '../../components/CardNavigator/CardNavigator';
import ActivityPage from '../../components/ActivityPage/ActivityPage';

import { IFFitlers, IFCardNavigator, Config, IFFiltersItem } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';
import { apiUrl, count, defaultConfig } from '../../global/vars';
import Filter from '../../services/Filter';
import {
  filteredPage,
  filteredPageMore,
  updateAllConfig,
  updateConfig,
  updateFiltersTracks,
} from '../../services/Serv.ActivityPages';
interface Props {
  geo: number;
}

interface State {
  filters: IFFitlers;
  cards: IFCardNavigator[];
  countNewCards: number;
  config: Config;
}

class Navigator extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  pageName = 'navigator';
  fresh = 1;

  constructor(props: Props) {
    super(props);
    this.state = {
      filters: {},
      cards: [],
      countNewCards: count.cardsToPage,
      config: {
        page: 0,
        fresh: this.fresh,
        geo_id: this.props.geo,
      },
    };
  }

  render() {
    const { ...itemProps } = this.props;
    const { filters, cards } = this.state;
    const content = cards.map((item, i) => (
      <CardNavigator pageName={this.pageName} data={item} key={`card${i}`} />
    ));

    return (
      <ActivityPage
        title="Навигатор"
        content={content}
        countCards={this.state.countNewCards}
        filters={filters}
        search={this.search}
        fetchGetFilteredPage={this.fetchGetFilteredPage}
        updateFiltersTracks={this.updateFiltersTracks}
        updateConfig={this.updateConfig}
        updateAllConfig={this.updateAllConfig}
        getFilteredPageMore={this.getFilteredPageMore}
        {...itemProps}
      />
    );
  }

  componentDidMount() {
    this.FetchAPI.getFilterFields(apiUrl.navigator).then((data) => {
      this.setState(
        Object.assign(this.state, {
          filters: data,
        })
      );
      return;
    });

    this.FetchAPI.getFilteredPage({ fresh: this.fresh, geo_id: this.props.geo }, apiUrl.navigator).then(
      (data) => {
        this.setState(
          Object.assign(this.state, {
            cards: data,
          })
        );
        return;
      }
    );
  }

  search = (value: string) => {
    const filter = new Filter();
    filter.search({ value, url: apiUrl.navigator, Component: this, geo: this.props.geo, fresh: this.fresh });
  };

  fetchGetFilteredPage = () => {
    filteredPage(this, apiUrl.navigator);
  };

  updateFiltersTracks = (item: IFFiltersItem[]) => {
    updateFiltersTracks(item, this);
  };

  updateAllConfig = () => {
    updateAllConfig(defaultConfig.all.fresh, this);
  };

  updateConfig = async (key: string, item: string, masAges: number[][]) => {
    await updateConfig(key, item, this, masAges);
    this.fetchGetFilteredPage();
  };

  getFilteredPageMore = () => {
    filteredPageMore(apiUrl.navigator, this);
  };
}

export default Navigator;
