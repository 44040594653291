import * as React from 'react';

import './HeaderLeftMenu.scss';

interface Props {}
interface State {
  active: number;
}

interface Link {
  text: string;
  link: string;
  className: string;
  active: boolean;
}
class HeaderLeftMenu extends React.Component<Props, State> {
  links: Link[];

  constructor(props: Props) {
    super(props);
    this.links = [
      {
        text: 'Навигатор',
        link: '/navigator',
        className: 'icon-navigator',
        active: window.location.pathname == '/navigator',
      },
      {
        text: 'Курсы',
        link: '/course',
        className: 'icon-education',
        active: window.location.pathname == '/course',
      },
      {
        text: 'Проекты',
        link: '/project',
        className: 'icon-project',
        active: window.location.pathname == '/project',
      },
      {
        text: 'Челленджи',
        link: '/challenge',
        className: 'icon-challenge',
        active: window.location.pathname == '/challenge',
      },
      {
        text: 'События',
        link: '/event',
        className: 'icon-events',
        active: window.location.pathname == '/event',
      },
      {
        text: 'Люди',
        link: '/people',
        className: 'icon-people',
        active: window.location.pathname == '/people',
      },
      {
        text: 'Витрина работ',
        link: '/works',
        className: 'icon-showcase',
        active: window.location.pathname == '/works',
      },
      {
        text: 'Аналитика',
        link: '/analytics',
        className: 'icon-analytics',
        active: window.location.pathname == '/analytics',
      },
    ];
  }

  render() {
    const { links } = this;

    const year = new Date().getFullYear();

    return (
      <div id="left_menu">
        <a href="/challenges">
          <div className="site_name"></div>
        </a>

        <div className="menu_list">
          {links.map((item, index) => (
            <a
              href={item.link}
              className={`menu_item ${item.active ? 'active' : ''}`}
              key={`menuItem${index}`}
            >
              <span className="icon">
                <span className={item.className}></span>
              </span>
              <span className="item_name">
                <span>{item.text}</span>
              </span>
            </a>
          ))}
        </div>

        <div className="footer">
          <div className="terms-use-link">
            <a href="/legal">Условия использования</a>
          </div>
          <div className="copy">
            <span>© Все права защищены {year}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderLeftMenu;
