import React from 'react';
import Moment from 'react-moment';
import { baseURL, timezone } from '../../../global/vars';

interface Props {
  author: {
    id: number;
    avatar: string;
    name: string;
    surname: string;
    date: string;
    text: string;
  };
}

class AuthorPost extends React.Component<Props> {
  render() {
    const {
      author: { id, avatar, name, surname, date, text },
    } = this.props;
    return (
      <div>
        <div className="user_info">
          <a href={`/profile/user/${id}`}>
            <div
              className="ava"
              style={{
                backgroundImage: `url(${baseURL}/storage/${avatar})`,
              }}
            ></div>
          </a>
          <div className="text_block">
            <div className="name">
              <a href={`/profile/user/${id}`}>
                <b>
                  {name} {surname}
                </b>
              </a>
            </div>
            <div className="title_menu"></div>
            <div className="title_menu_content">
              <a className="open_modal" href="#accrual_of_competencies">
                Начислить компетенции
              </a>
              <a id="blockban" href="ban" className="red">
                Блокировать
              </a>
            </div>
            <div className="date">
              <span>
                <Moment format="D MMM YYYY в HH:mm (Томск)" add={{ hours: timezone }}>
                  {date}
                </Moment>
              </span>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="text">{text}</div>
      </div>
    );
  }
}

export default AuthorPost;
