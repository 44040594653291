import React from 'react';
import { IFCardActivity, IFFiltersItem } from './interfaces';
import { typeOnline } from './vars';

export const clickTabs = (component: React.Component, i: number) => {
  component.setState({
    active: i,
  });
};

export const bodyHidden = () => {
  document.querySelector('body')?.classList.add('body-hidden');
};
export const bodyAuto = () => {
  document.querySelector('body')?.classList.remove('body-hidden');
};

export const checkTagLine = (type: string, item: IFCardActivity) => {
  let tagLine = '';
  switch (type) {
    case 'course':
      tagLine = typeOnline[item.is_online];
      break;
    case 'project':
      tagLine = 'Проект';
      break;
    case 'challenge':
      tagLine = '';
      break;
    case 'event':
      tagLine = item.type || '';
      break;
    default:
      '';
  }
  return tagLine;
};

export const getTypeAndIDPage = () => {
  const urlPage = location.pathname;
  const fieldID = /\/([0-9]+)/.exec(urlPage);
  const idPage = fieldID ? Number(fieldID[1]) : 0;
  const fieldType = /\/([A-Za-z]+)/.exec(urlPage);
  const typePage = fieldType ? fieldType[1] : '';
  const fieldModule = /\/[A-Za-z]+\/[0-9]+\/module\/([0-9]+)/.exec(urlPage);
  const idModule = fieldModule ? Number(fieldModule[1]) : 0;

  const fieldTask = /\/[A-Za-z]+\/[0-9]+\/module\/[0-9]+\/[A-Za-z]+\/([0-9]+)/.exec(urlPage);
  const idTask = fieldTask ? Number(fieldTask[1]) : 0;

  const fieldAnswer = /\/[A-Za-z]+\/[0-9]+\/answer\/([0-9]+)/.exec(urlPage);
  const idAnswer = fieldAnswer ? Number(fieldAnswer[1]) : 0;

  return { idPage, typePage, idModule, idTask, idAnswer };
};

export const generateCurrentDate = () => {
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth();
  const year = now.getFullYear();
  const h = now.getHours();
  const m = now.getMinutes();
  const s = now.getSeconds();

  const dayStr = day < 10 ? `0${day}` : day;
  const monthStr = month + 1 < 10 ? `0${month + 1}` : month + 1;

  const date = `${year}-${monthStr}-${dayStr} ${h}:${m}:${s}`;
  return date;
};

export const ClosedAlert = (Component: React.Component) => {
  Component.setState(
    Object.assign({}, Component.state, {
      alert: false,
    })
  );
};

export const ClosedPopup = (Component: React.Component, field: string) => {
  Component.setState(
    Object.assign(Component.state, {
      [field]: false,
    })
  );
};

// ----------------то что ниже в будущем не должно использоваться------------------------------------------

export const agesTransform = (options: number[][]) => {
  let newOptions: IFFiltersItem[] = [];
  options.forEach((item: number[], i: number) => {
    newOptions.push(item[0] != 0 ? { id: i, name: `${item[0]}-${item[1]}` } : { id: i, name: `Для всех` });
  });
  return newOptions;
};

export const priceTransform = (options: { [key: string]: number }) => {
  let newOptions: IFFiltersItem[] = [];
  Object.keys(options).forEach((key: string) => {
    newOptions.push({ id: options[key], name: key });
  });
  return newOptions;
};

export const healthTransform = (options: string[]) => {
  let newOptions: IFFiltersItem[] = [];
  options.forEach((item: string, i) => {
    newOptions.push({ id: i, name: item });
  });
  return newOptions;
};
