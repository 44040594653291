import * as React from 'react';

import '../SelectMy/SelectMy.scss';
import './FilterForm.scss';

import { IFFitlers, IFFiltersItem, ListSelectItemNew, optionSelect } from '../../global/interfaces';
import FetchMyTrack from '../../services/FetchMyTrack';
import { apiFields } from '../../global/vars';
import { agesTransform, priceTransform, healthTransform } from '../../global/functions';
import FilterFormSelect from './FilterFormSelect';

interface Props {
  filters: IFFitlers;
  updateConfig: any;
  fetchGetFilteredPage: any;
  updateAllConfig: any;
  updateFiltersTracks: any;
}

interface State {
  defaultFilters: {
    [key: string]: string;
  };
  filterValue: {
    [key: string]: string;
  };
  clearedFilters: {
    [key: string]: boolean;
  };
  filterload: boolean;
}

const clearedFiltersDefault = {
  gender: true,
  way_id: true,
  track_id: true,
  languages: true,
  interests: true,
  tech_skills: true,
  tracks: true,
  ways: true,
  ages: true,
  organizations: true,
  price: true,
  type: true,
  cities: true,
  health: true,
};

class FilterForm extends React.Component<Props, State> {
  FetchMyTrack = new FetchMyTrack();
  masAges: number[][];

  constructor(props: Props) {
    super(props);
    this.state = {
      defaultFilters: {},
      filterValue: {},
      clearedFilters: Object.assign({}, clearedFiltersDefault),
      filterload: true,
    };
    this.masAges = [
      [6, 10],
      [11, 14],
      [15, 18],
      [0, 201],
    ];
  }

  render() {
    const { filters, fetchGetFilteredPage } = this.props;
    const listSelect: ListSelectItemNew[] = [];

    const { ages } = apiFields;

    Object.keys(filters).forEach((key, index) => {
      const item = Object.values(filters)[index];
      let options = item.items;

      // эти проверки нужно убрать после корректировки api
      if (key == ages) {
        this.masAges = options;
        options = agesTransform(options);
      }

      const optionsFinal: optionSelect[] = [];
      options.forEach((item: IFFiltersItem) => {
        optionsFinal.push({
          value: item.id,
          label: item.name,
        });
      });

      listSelect.push({ key: key, keyStr: item.name, options: optionsFinal });
    });

    return (
      <form
        action=""
        className="filter-form"
        onSubmit={(e) => {
          e.preventDefault();
          fetchGetFilteredPage();
        }}
      >
        <div className="filter-form__title">
          <span>Фильтр</span>
        </div>

        {listSelect.map((item: ListSelectItemNew, index) => (
          <div className="input" key={`select${index}`}>
            <FilterFormSelect
              item={item}
              placeholder={this.state.filterValue[item.key]}
              selectedOption={this.selectedOption}
              cleared={this.state.clearedFilters[item.key]}
            />
          </div>
        ))}

        {/* <div className="input">
          <button className="blue_btn w100">Применить</button>
        </div> */}

        <div className="filter-form__clear">
          <span
            onClick={() => {
              this.clearFilter();
            }}
          >
            Сбросить фильтр
          </span>
        </div>
      </form>
    );
  }

  componentDidUpdate(prevP: Props) {
    if (prevP != this.props && Object.keys(this.getDefault()).length != 0 && this.state.filterload) {
      const def = this.getDefault();

      this.setState(
        Object.assign({}, this.state, {
          defaultFilters: def,
          filterValue: def,
          filterload: false,
        })
      );
    }
  }

  getDefault() {
    const { filters } = this.props;
    let def: { [key: string]: string } = {};
    Object.keys(filters).forEach((key, i) => {
      const item = Object.values(filters)[i];
      def = Object.assign({}, def, { [key]: item.name });
    });

    return def;
  }

  selectedOption = (selectedOption: optionSelect | null, objFilter: ListSelectItemNew) => {
    if (selectedOption) {
      this.props.updateConfig(objFilter.key, selectedOption.value.toString(), this.masAges);
      const filterValueNew = Object.assign({}, this.state.filterValue, {
        [objFilter.key]: selectedOption.label,
      });
      const clearedFiltersNew = Object.assign({}, this.state.clearedFilters, {
        [objFilter.key]: false,
      });
      this.setState({
        filterValue: filterValueNew,
        clearedFilters: clearedFiltersNew,
      });

      if (objFilter.key === apiFields.ways) {
        const wayId = selectedOption.value;
        this.fetchGetTracks(wayId);
      }
    }
  };

  clearFilter() {
    new Promise((resolve) => {
      this.setState({
        filterValue: Object.assign({}, this.state.defaultFilters),
        clearedFilters: Object.assign({}, clearedFiltersDefault),
      });
      this.props.updateAllConfig();
      resolve(true);
    }).then(() => {
      this.props.fetchGetFilteredPage();
    });
  }

  fetchGetTracks(wayId: number) {
    this.FetchMyTrack.getTracks(wayId).then((data) => {
      this.props.updateFiltersTracks(data);
      this.setState({
        filterValue: Object.assign({}, this.state.filterValue, {
          [apiFields.tracks]: this.state.defaultFilters.tracks,
        }),
        clearedFilters: Object.assign({}, this.state.clearedFilters, {
          [apiFields.tracks]: true,
        }),
      });
    });
  }
}

export default FilterForm;
