import * as React from 'react';
import Moment from 'react-moment';

import './Messages.scss';

import { ContextModal } from '../../services/Contexts';
import Search from '../../components/Search/Search';
import { IFChats, IFModal, IFPeople } from '../../global/interfaces';
import { baseURL } from '../../global/vars';
import FetchMyTrack from '../../services/FetchMyTrack';
import ModalActivity from '../../components/activity/ModalActivity/ModalActivity';
import ChatBanned from '../../components/messages/ChatBanned/ChatBanned';
import CreateGroupChat from '../../components/messages/CreateGroupChat/CreateGroupChat';
import MenuChat from '../../components/messages/MenuChat/MenuChat';
import { ClosedModal } from '../../services/Modal';

interface Props {
  profile: IFPeople;
}

interface State {
  chats: IFChats[];
  banned: IFPeople[];

  profile: IFPeople;
  showMenu: boolean;

  modalActive: boolean;
  modal: IFModal;
}

class Messages extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();
  profileID: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      chats: [],
      banned: [],

      profile: {
        name: '',
        surname: '',
        avatar: '',
        text: '',
        id: 0,
      },
      showMenu: false,

      modalActive: false,
      modal: {
        title: 'Заголовок',
        content: null,
        width: '',
      },
    };
    this.profileID = 0;
  }

  render() {
    const { chats, modalActive, modal } = this.state;
    return (
      <React.Fragment>
        <div className="page">
          <div className="msgs_page">
            <div className="list_of_messages">
              <div className="header">
                <div className="name">Сообщения</div>
                <MenuChat
                  menu={[
                    {
                      name: 'Создать групповой чат',
                      href: '#createChat',
                      icon: require('./img/a-create-chat.svg').default,
                      clickFunction: this.showCreateChat,
                    },
                    {
                      name: 'Заблокированные',
                      href: '#bannedChats',
                      icon: require('./img/a-block.svg').default,
                      clickFunction: this.showBanned,
                    },
                  ]}
                />
              </div>

              <Search search={this.search} />

              {chats.length ? (
                <ul className="dialogs_list">
                  {chats.map((item, i) => (
                    <a
                      href={`/message/${item.id}`}
                      className={`dialog ${item.last_read ? '' : 'new'}`}
                      key={`chat${i}`}
                    >
                      <div
                        className="ava"
                        style={
                          !item.banned
                            ? { backgroundImage: `url('${baseURL}/storage/${item.avatar.path}')` }
                            : {}
                        }
                      >
                        {item.user_type === 'mentor' ? <div className="mentor"></div> : null}
                        {item.last_activity ? <div className="online"></div> : null}
                      </div>
                      <div className="info">
                        <div className="text">
                          <span className="name">{item.chat_name}</span>
                          <div className="msg_text">
                            <span>{item.message}</span>
                            {item.last_message_date ? (
                              <span className="time">
                                {`• `}
                                <Moment fromNow add={{ hours: 7 }}>
                                  {item.last_message_date}
                                </Moment>
                              </span>
                            ) : (
                              <span>Сообщений нет</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </ul>
              ) : (
                <div className="clead_history">
                  <div className="text">
                    <img src={require('./img/chat2.svg').default} alt=""></img>
                    <span>У вас еще нет диалогов</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {modalActive ? (
          <ContextModal.Provider
            value={{
              component: this,
              closedModal: ClosedModal,
            }}
          >
            <ModalActivity title={modal.title} content={modal.content} width={modal.width} />
          </ContextModal.Provider>
        ) : null}
      </React.Fragment>
    );
  }

  componentDidUpdate(prevP: Props) {
    const { profile } = this.props;

    if (prevP !== this.props && this.profileID != profile.id) {
      this.profileID = profile.id;

      this.FetchAPI.getChatsPage(profile.id).then((data) => {
        this.setState(
          Object.assign({}, this.state, {
            chats: data.chats,
            banned: data.blocked_users,
          })
        );
      });
    }
  }

  search = (value: string) => {
    const { profile } = this.props;

    this.FetchAPI.getChatsByName(profile.id, value).then((data) => {
      this.setState(
        Object.assign({}, this.state, {
          chats: data.chats,
        })
      );
    });
  };

  showMenu() {
    this.setState(
      Object.assign({}, this.state, {
        showMenu: !this.state.showMenu,
      })
    );
  }

  showBanned = () => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Заблокированные',
          content: <ChatBanned banned={this.state.banned} updateBannedList={this.updateBannedList} />,
          width: 'min',
        },
      })
    );
  };

  updateBannedList = (newBanned: IFPeople[]) => {
    this.setState(
      Object.assign({}, this.state, {
        banned: newBanned,
        modal: {
          title: 'Заблокированные',
          content: <ChatBanned banned={newBanned} updateBannedList={this.updateBannedList} />,
          width: 'min',
        },
      })
    );
  };

  showCreateChat = () => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Создание группового чата',
          content: (
            <CreateGroupChat
              profile={this.props.profile}
              addChat={this.addChat}
              buttonName="Создать групповой чат"
            />
          ),
          width: 'min',
        },
      })
    );
  };

  addChat = (newChat: IFChats) => {
    this.setState(
      Object.assign({}, this.state, {
        chats: [newChat, ...this.state.chats],
      })
    );
    ClosedModal(this);
  };
}

export default Messages;
