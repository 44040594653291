import * as React from 'react';
import Slider from 'react-slick';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import '../../../global/tippy.scss';

import './Rewards.scss';
import '../../../global/SliderSlick.scss';

interface Props {
  medals: {
    description: string;
    have: number;
    id: number;
    name: string;
  }[];
}

class Rewards extends React.Component<Props> {
  icons: any[];
  limitItem: number;

  constructor(props: Props) {
    super(props);
    this.icons = [
      require(`./img/1.svg`).default,
      require(`./img/2.svg`).default,
      require(`./img/3.svg`).default,
      require(`./img/4.svg`).default,
      require(`./img/5.svg`).default,
      require(`./img/6.svg`).default,
      require(`./img/7.svg`).default,
      require(`./img/8.svg`).default,
      require(`./img/9.svg`).default,
      require(`./img/4.svg`).default,
      require(`./img/5.svg`).default,
      require(`./img/6.svg`).default,
      require(`./img/7.svg`).default,
      require(`./img/8.svg`).default,
      require(`./img/9.svg`).default,
    ];
    this.limitItem = 9;
  }

  render() {
    const { medals } = this.props;
    const { icons, limitItem } = this;

    const settings = {
      // autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: limitItem,
      slidesToScroll: limitItem,
      className: 'slider-rewards',
      arrows: medals.length > limitItem,
    };

    return (
      <div className="row mt-40">
        <div className="col-12">
          <div className="page_title publications_title">
            <h4>Вознаграждения</h4>
          </div>

          <div className="awards_slider">
            <Slider {...settings}>
              {medals.map((item, i) => (
                <Tooltip
                  title={`<b>${item.name}</b><span>${item.description}</span>`}
                  position="top"
                  trigger="mouseenter"
                  key={`medal${i}`}
                  className="test"
                  theme="light"
                  arrow={true}
                >
                  <div className={`award ${item.have > 0 ? 'received' : ''}`}>
                    <img src={icons[i]} alt="" />
                  </div>
                </Tooltip>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Rewards;
