import * as React from 'react';

import './Qualifications.scss';

import { ContextModal } from '../../services/Contexts';

import FetchMyTrack from '../../services/FetchMyTrack';
import CompetenceDevelopment from '../../components/detailPageActivity/CompetenceDevelopment/CompetenceDevelopment';
import BannerActivity from '../../components/BannerActivity/BannerActivity';

import { IFCompetencePoints, IFModal } from '../../global/interfaces';
import CardInfo from '../../components/detailPageActivity/CardInfo/CardInfo';
import { getTypeAndIDPage } from '../../global/functions';
import { ArrDeclension } from '../../services/Declension';
import ModalActivity from '../../components/activity/ModalActivity/ModalActivity';
import ContentModalBuy from '../../components/qualifications/ContentModalBuy/ContentModalBuy';
import ContentModalYoutube from '../../components/qualifications/ContentModalYoutube/ContentModalYoutube';
import Certificate from '../../components/qualifications/Certificate/Certificate';
import { ClosedModal } from '../../services/Modal';

interface Props {
  name?: string;
  text?: string;
}

interface State {
  settingsBtn: { [key: string]: boolean };
  type: string;
  banner: {
    thumb?: string;
    crop?: string;
    original: string;
  };
  name: string;
  countStudents: number;
  price: number | null;
  description: any;
  activities: {
    id: number | string;
    order: number;
    type: string;
    image?: string;
    name?: string;
    ustatus?: string;
  }[];
  map: {
    type: string;
    title: string;
    track: string;
    competencies: {
      name: string;
      points: IFCompetencePoints[];
    }[];
  }[];
  startDate: string | null;
  endDate: string | null;
  organization: string;
  contactGeoCode: string | null;
  contactAddress: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;

  modal: IFModal;
  modalActive: boolean;
}

class Qualifications extends React.Component<Props, State> {
  FetchAPI = new FetchMyTrack();

  constructor(props: Props) {
    super(props);
    this.state = {
      settingsBtn: {},
      type: '',
      banner: {
        thumb: '',
        crop: '',
        original: '',
      },
      name: '',
      countStudents: 0,
      price: null,
      description: null,
      activities: [],
      map: [
        // {
        //   type: 'course',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 145,
        //           text: 'Способен определять основные сельскохозяйственные культуры по морфологическим признакам',
        //           score: 1,
        //           completed: true,
        //         },
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: 'project',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 145,
        //           text: 'Способен определять основные сельскохозяйственные культуры по морфологическим признакам',
        //           score: 1,
        //           completed: true,
        //         },
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //         {
        //           id: 145,
        //           text: 'Способен определять основные сельскохозяйственные культуры по морфологическим признакам',
        //           score: 1,
        //           completed: true,
        //         },
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: 'price',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //     {
        //       name: 'Культивирование агрокультур и животноводство 2',
        //       points: [
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: 'course',
        //   title: 'Трек',
        //   track: 'Программная инженерия',
        //   competencies: [
        //     {
        //       name: 'Культивирование агрокультур и животноводство',
        //       points: [
        //         {
        //           id: 147,
        //           text: 'Проводит возделывание, уборку и первичную обработку урожая, его закладку на хранение',
        //           score: 3,
        //           completed: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      startDate: null,
      endDate: null,
      organization: '',
      contactGeoCode: null,
      contactAddress: null,
      contactName: null,
      contactEmail: null,
      contactPhone: null,

      modal: {
        title: 'Заголовок',
        content: null,
        width: '',
      },
      modalActive: false,
    };
  }

  render() {
    const {
      settingsBtn,
      type,
      banner,
      name,
      countStudents,
      price,
      description,
      activities,
      map,
      startDate,
      endDate,
      organization,
      contactGeoCode,
      contactAddress,
      contactName,
      contactEmail,
      contactPhone,
      modal,
      modalActive,
    } = this.state;

    const cardInfo = (
      <CardInfo
        startDate={startDate}
        endDate={endDate}
        contactGeoCode={contactGeoCode}
        contactAddress={contactAddress}
        contactName={contactName}
        contactEmail={contactEmail}
        contactPhone={contactPhone}
        type={type}
        organization={organization}
        contactTitle={'Контактное лицо'}
      />
    );

    return (
      <React.Fragment>
        <div className="page">
          <div className="page__container">
            <div className="page__content-full detail-qualification">
              <BannerActivity
                {...this.props}
                typeActivity={type}
                name={name}
                countStudents={countStudents}
                banner={banner}
                {...settingsBtn}
                price={price}
                textCount="Уже прошли:"
                hasMeasurement={true}
                clickButton={this.buy}
              />

              <div className="detail-qualification__content mt-24">
                <div>
                  <div className="text mt-24" dangerouslySetInnerHTML={{ __html: description }}></div>

                  {map.length != 0 ? (
                    <div className="detail-qualification__cards-about mt-40">
                      {map.map(({ type, title, track }, i) => (
                        <div className="detail-qualification__card mb-24" key={`track${i}`}>
                          <div className="icon">
                            <img src={this.checkIcon(type)} alt="" />
                          </div>
                          <div className="text">
                            <span>{title}</span>
                            <span>{track}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>

                {cardInfo}
              </div>

              <div
                className="detail-qualification__activity"
                style={
                  activities.length > 7 ? { backgroundRepeat: 'repeat-y' } : { backgroundRepeat: 'no-repeat' }
                }
              >
                {activities.map((item, i) => (
                  <a
                    href={`${this.getLink(item)}`}
                    className="detail-qualification__activity-item"
                    key={`qualification${i}`}
                    onClick={() => {
                      item.type == 'youtube' ? this.youtube(item.id.toString()) : null;
                    }}
                  >
                    <div
                      className="detail-qualification__activity-bg"
                      style={
                        item.type == 'youtube'
                          ? {
                              backgroundImage: `url(${require('./img/play.jpg')})`,
                            }
                          : {
                              backgroundImage: `url('/storage/${item.image}')`,
                            }
                      }
                    >
                      <div className="detail-qualification__activity-content">
                        <span
                          className={`detail-qualification__activity-status ${
                            item.ustatus == 'member' ? 'green' : ''
                          }`}
                        >
                          {item.ustatus == 'member' ? 'Участник' : ''}
                        </span>
                        <span className="detail-qualification__activity-tag-line">
                          {ArrDeclension(item.type)[0]}
                        </span>
                        {/* не используется */}
                        {/* <span className="detail-qualification__activity-text"></span> */}
                      </div>
                    </div>
                  </a>
                ))}
              </div>

              {map.length != 0 ? <CompetenceDevelopment list={map} type={type} /> : null}

              <Certificate name={name} buy={this.buy} />
            </div>
          </div>
        </div>
        {modalActive ? (
          <ContextModal.Provider
            value={{
              component: this,
              closedModal: ClosedModal,
              showModal: this.showModal,
            }}
          >
            <ModalActivity title={modal.title} content={modal.content} width={modal.width} />
          </ContextModal.Provider>
        ) : null}
      </React.Fragment>
    );
  }

  componentDidMount() {
    const FetchAPI = new FetchMyTrack();

    const { idPage, typePage } = getTypeAndIDPage();

    FetchAPI.getQualification(idPage).then((data) => {
      this.setState({
        settingsBtn: Object.assign({ btnBuy: true }),
        type: typePage,

        banner: { original: data.qualification.image },
        name: data.qualification.name,
        countStudents: data.qualification.passed,
        price: data.qualification.price,
        description: data.qualification.description,
        contactName: data.qualification.person,
        contactEmail: data.qualification.email,
        contactPhone: data.qualification.phone,

        activities: Object.values(data.activities),

        // map: data.information // нужно исправить api
      });
    });
  }

  checkIcon(type: string) {
    let icon = '';
    switch (type) {
      case 'napr':
        icon = 'napr';
        break;
      case 'course':
      case 'project':
        icon = 'trec';
        break;
      case 'vozrast':
        icon = 'vozrast';
        break;
      case 'price':
        icon = 'price';
        break;
      case 'ovz':
        icon = 'ovz';
        break;
      default:
        icon = '';
    }

    let src = '';
    if (icon != '') {
      src = require(`../../old/img/icons/${icon}.svg`);
    }
    return src;
  }

  getLink(activity: { [key: string]: any }) {
    const { ustatus, type, id } = activity;
    if (ustatus == 'member') {
      return `/${type}/${id}`;
    }
    // if (ustatus == 'complete') {//?
    //   return ``;
    // }

    return `#nothing`;
  }

  buy = () => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'Варианты покупки',
          content: <ContentModalBuy />,
          width: 'very-min',
        },
      })
    );
  };

  youtube(id: string) {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
        modal: {
          title: 'О квалификации',
          content: <ContentModalYoutube link={id} />,
          width: '',
        },
      })
    );
  }

  showModal = () => {
    this.setState(
      Object.assign({}, this.state, {
        modalActive: true,
      })
    );
  };
}

export default Qualifications;
