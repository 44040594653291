import * as React from 'react';
import Select from 'react-select';
import { ListSelectItemNew } from '../../global/interfaces';

import '../SelectMy/SelectMy.scss';
import './FilterForm.scss';

interface Props {
  item: ListSelectItemNew;
  placeholder: string;
  selectedOption: any;
  cleared: boolean;
}

class FilterFormSelect extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { item, placeholder, selectedOption, cleared } = this.props;

    return (
      <React.Fragment>
        {cleared ? (
          <Select
            options={item.options}
            placeholder={`${placeholder}`}
            isSearchable={false}
            className="select-my 1"
            classNamePrefix="select-my"
            onChange={(selectedOpt) => {
              selectedOption(selectedOpt, item);
            }}
            value={null}
          />
        ) : (
          <Select
            options={item.options}
            placeholder={`${placeholder}`}
            isSearchable={false}
            className="select-my 2"
            classNamePrefix="select-my"
            onChange={(selectedOpt) => {
              selectedOption(selectedOpt, item);
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default FilterFormSelect;
