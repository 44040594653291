import * as React from 'react';

import './Attachment.scss';

import { baseURL } from '../../../global/vars';

interface Props {
  attachment: string[];
  title: string;
}

class Attachment extends React.Component<Props> {
  render() {
    const { attachment, title } = this.props;

    return (
      <div className="attachment">
        <h5 className="attachment__title">{title}</h5>

        {attachment.map((item, i) => (
          <a
            href={`${baseURL}/storage/${item}`}
            className="link link_file attachment__file"
            download
            rel="noreferrer"
            target="_blank"
            key={`file${i}`}
          >
            {this.checkNameFile(item)}
          </a>
        ))}
      </div>
    );
  }

  checkNameFile(item: string) {
    const arr = /.*\/(.*)/.exec(item);
    return arr ? arr[1] : '';
  }
}

export default Attachment;
